import { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { ResourcesService } from "../../services/ResourcesService";

export const VerticallyCenteredModal: FC<{
    id: string,
    title: string,
    show: boolean,
    onCancel: Function,
    onOk: Function,
    children: JSX.Element,
    size?: "sm" | "lg" | "xl" | undefined,
    lang: 'BG' | 'EN',
}> = ({ id, title, show, onCancel, onOk, children, size, lang }) =>
        <Modal
            size={size}
            onHide={() => onCancel()}
            show={show}
            aria-labelledby={`contained-modal-${id}`}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id={`contained-modal-${id}`}>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button className='float-end ms-2' variant="outline-primary" size='sm' onClick={() => onCancel()}>
                    <i className='bi bi-x-lg'></i> {ResourcesService.translate('Cancel', lang)}
                </Button>
                <Button className='float-end' variant="outline-success" size='sm' onClick={() => {
                    onOk();
                    onCancel();
                }}>
                    <i className='bi bi-check'></i> {ResourcesService.translate('Ok', lang)}
                </Button>
            </Modal.Footer>
        </Modal>