import { FC } from "react";
import { Col, Form, Row } from "react-bootstrap";

type FormGroupNumberProps = {
    controlId: string,
    value: number,
    setValue: Function,
    disabled: boolean,
    label: string,
};

export const FormGroupNumber: FC<FormGroupNumberProps> = ({ controlId, value, setValue, disabled, label }) =>
    <Form.Group as={Row} className="mb-3" controlId={controlId}>
        <Form.Label column sm="2">{label}</Form.Label>
        <Col sm="10">            
            <Form.Control type="number" defaultValue={value} onChange={(e) => setValue(e.target.value)} disabled={disabled} />
        </Col>
    </Form.Group>