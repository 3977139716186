import http from "../../shared/services/HttpCommon";
import { SectionData } from "../../shared/models/Entities";
import { Filter } from "../../shared/models/Filter";
import { PagedList } from "../../shared/models/PagedList";
import { AuthHeaderService } from "../../shared/services/AuthHeaderService";
import { CreateSectionData } from "./models/CreateSectionData";

export async function getSections(token: string, filter: Filter): Promise<PagedList<SectionData>> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const excludeIdQuery = filter.excludeIds && filter.excludeIds.length ? `&excludeIds=${filter.excludeIds.join('&excludeIds=')}` : '';
    const response = await http.get<SectionData[]>(`/sections?query=${filter.query}&skip=${filter.skip}&take=${filter.take}${excludeIdQuery}`, headersConfig);
    return {
        data: response.data
    };
}

export async function getSection(token: string, id: string): Promise<{ data: SectionData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const response = await http.get<SectionData>(`/sections/${id}`, headersConfig);
    return {
        data : response.data
    }
}

export async function createSection(token: string, createData: CreateSectionData): Promise<{ data: SectionData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const result = await http.post<SectionData>(`/sections`, createData, headersConfig);
    return {
        data: result.data
    };
}

export async function editSection(token: string, editData: SectionData): Promise<{ data: SectionData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    await http.put<string>(`/sections/${editData.id}`, editData, headersConfig);
    return {
        data: editData
    };
}

export async function deleteSection(token: string, id: string): Promise<{ data: string }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    await http.delete<string>(`/sections/${id}`, headersConfig);
    return {
        data: id
    };
}