import jwt_decode from "jwt-decode";

import http from "../../shared/services/HttpCommon";

export async function postLogin(phone: string, code: string) {
    const data = { phone, code };
    const response = await http.post<string>('/users/authenticate', data);
    const token = response.data;
    const decodedData: { user_role: string } = jwt_decode(token);
    return {
        data: {
            token,
            role: decodedData.user_role
        }
    };
}

export async function register(phone: string) {
    const data = { phone };
    const response = await http.post<boolean>('/users/register', data);
    return {
        data: response.data
    };
}
