import { FC } from 'react';

import { changeMode, getSectionAsync } from '../sectionSlice';

import { GenericList } from '../../../shared/components/GenericList/GenericList';
import { ModeEnum, StatusEnum } from '../../../shared/models/Enums';
import { SectionData } from '../../../shared/models/Entities';
import { ResourcesService } from '../../../shared/services/ResourcesService';
import { selectUserLanguage } from '../../login/loginSlice';
import { useAppSelector } from '../../../app/hooks';

type SectionsListProps = {
    sections: SectionData[],
    sectionStatus: StatusEnum,
    dispatch: Function
    loadSectionEdit: Function,
    query: string
    setQuery: Function,
    onNext: Function,
    onPrevious: Function,
};

export const SectionsList: FC<SectionsListProps> = ({
    sections, sectionStatus, dispatch, loadSectionEdit, query, setQuery, onNext, onPrevious
}) => {
    const lang = useAppSelector(selectUserLanguage);
    const tableHeaders = ['#', ResourcesService.translate('Title', lang), ResourcesService.translate('Questions count', lang)];

    return (
        <GenericList
            lang={lang}
            h2={ResourcesService.translate('Sections', lang)}
            headContent={
                <tr>
                    {
                        tableHeaders.map(h => <th key={h}>{h}</th>)
                    }
                </tr>
            }
            bodyContent={
                sections.map((s, i) =>
                    <tr key={i} onClick={() => dispatch(getSectionAsync(s.id))}>
                        <td>{s.id.substring(0, 8)}...</td>
                        <td>{ResourcesService.translateInput(s.titleBg, s.titleEn, lang)}</td>
                        <td>{s.questionsCount}</td>
                    </tr>
                )
            }
            listStatus={sectionStatus}
            numberOfRows={5}
            numberOfColumns={tableHeaders.length}
            onCreateClick={() => dispatch(changeMode(ModeEnum.Create))}
            searchValue={query}
            onSearch={(value: string) => dispatch(setQuery(value))}
            onNext={() => dispatch(onNext())}
            onPrevious={() => dispatch(onPrevious())}
        />
    );
}