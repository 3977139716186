import { FC } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import { useAppSelector } from '../../../app/hooks';

import { QuestionTypeEnum, StatusEnum } from '../../../shared/models/Enums';
import { ResourcesService } from '../../../shared/services/ResourcesService';
import { selectUserLanguage } from '../../login/loginSlice';
import { LiveAnswer } from '../models/LiveSurvey';
import { SurveyLiveLayoutGeneric } from './SurveyLiveLayoutGeneric';

type SurveyLiveLayoutProps = {
    isCompleted: boolean,
    isLive: boolean,
    status: StatusEnum,
    titleBg: string,
    titleEn: string,
    now: number,
    questionTitleBG: string,
    questionTitleEN: string,
    questionSubtitleBg: string,
    questionSubtitleEn: string,
    questionType: QuestionTypeEnum,
    questionLimit : number,
    questionAnswers: LiveAnswer[],
    questionAnswersSelected: string[],
    questionAnswerersRanking: { answer: { position?: number, id: string } }[],
    onSelect: Function,
    inputAnswer: string,
    setInputAnswer: Function,
    onSave: Function,
    onStart: Function,
    alertText?: string
};

export const SurveyLiveLayout: FC<SurveyLiveLayoutProps> = ({
    isCompleted, status, titleBg, titleEn, now, questionTitleBG, questionTitleEN, questionSubtitleBg, questionSubtitleEn, questionType, questionLimit, questionAnswers, questionAnswersSelected, questionAnswerersRanking, onSelect,
    inputAnswer, setInputAnswer, onSave, onStart, alertText, isLive,
}) => {
    const lang = useAppSelector(selectUserLanguage);

    return <>
        {!isLive &&
            <p>{ResourcesService.translate('The survey you are trying to access has expired.')}</p>
        }
        {isLive &&
            <Row>
                <Col md={{ span: 10, offset: 1 }} style={{ position: "relative" }}>
                    {isCompleted &&
                        <div className='mt-5 mb-1 rounded p-5 bw-questionContainer'>
                            <a href='https://www.beyondwork.bg/' className='bw-closeSurvey'>
                                <i className="bi bi-x-lg"></i>
                            </a>
                            <div className='bw-progressContainer'>
                                <span className='progress-value' style={{ left: "calc(100% - 0.7em)" }}>{100}%</span>
                                <ProgressBar now={100} />
                            </div>
                            <h3 className='text-center m-5'>{ResourcesService.translate('Thank you for taking the time to help improve the working environment in your company together.', lang)}</h3>
                            <h3 className='text-center m-5'>{ResourcesService.translate('Your responses were saved successfully. You can close this page.', lang)}</h3>
                        </div>}
                    {!isCompleted &&
                        <SurveyLiveLayoutGeneric
                            status={status}
                            titleBg={titleBg}
                            titleEn={titleEn}
                            now={now}
                            questionTitle={ResourcesService.translateInput(questionTitleBG, questionTitleEN, lang)}
                            questionSubtitle={ResourcesService.translateInput(questionSubtitleBg, questionSubtitleEn, lang)}
                            questionType={questionType}
                            questionLimit={questionLimit}
                            questionAnswers={questionAnswers}
                            questionAnswersSelected={questionAnswersSelected}
                            questionAnswerersRanking={questionAnswerersRanking}
                            onSelect={onSelect}
                            inputAnswer={inputAnswer}
                            setInputAnswer={setInputAnswer}
                            onSave={onSave}
                            alertText={alertText} />}
                </Col>
            </Row>
        }
    </>;
}