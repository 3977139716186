import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { AnswerData } from '../../shared/models/Entities';
import { ModeEnum, OperationEnum, StatusEnum } from '../../shared/models/Enums';
import { Filter } from '../../shared/models/Filter';
import { Toast } from '../../shared/models/Toast';
import { createAnswer, deleteAnswer, editAnswer, getAnswers } from './answerAPI';
export interface AnswerState {
    answersList: {
        data: AnswerData[],
        status: StatusEnum,
        query: string,
        skip: number,
        take: number,
    },
    answerEdit: {
        data: AnswerData,
        status: StatusEnum,
        operation: OperationEnum,
    },
    answerCreate: {
        status: StatusEnum
    },
    mode: ModeEnum,
    toast: Toast
};

const initialState: AnswerState = {
    answersList: {
        data: [],
        status: StatusEnum.Idle,
        query: '',
        skip: 0,
        take: 5,
    },
    answerEdit: {
        data: {
            id: '',
            titleBg: '',
            titleEn: '',
        },
        status: StatusEnum.Idle,
        operation: OperationEnum.None,
    },
    answerCreate: {
        status: StatusEnum.Idle,
    },
    mode: ModeEnum.List,
    toast: {
        show: false,
        header: '',
        body: '',
    }
};

export const getAnswersAsync = createAsyncThunk(
    'answer/getAnswers',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getAnswers(state.login.token, filter);
        return response.data;
    }
);

export const createAnswerAsync = createAsyncThunk(
    'answer/createAnswer',
    async (answer: AnswerData, { getState }) => {
        const state: any = getState();
        const response = await createAnswer(state.login.token, answer.titleBg, answer.titleEn);
        return response.data;
    }
);

export const editAnswerAsync = createAsyncThunk(
    'answer/editAnswer',
    async (answer: AnswerData, { getState }) => {
        const state: any = getState();
        const response = await editAnswer(state.login.token, answer.id, answer.titleBg, answer.titleEn);
        return response.data;
    }
);

export const deleteAnswerAsync = createAsyncThunk(
    'answer/deleteAnswer',
    async (answerId: string, { getState }) => {
        const state: any = getState();
        const response = await deleteAnswer(state.login.token, answerId);
        return response.data;
    }
);

export const answerSlice = createSlice({
    name: 'answer',
    initialState,
    reducers: {
        // Global
        changeMode: (state, action: PayloadAction<ModeEnum>) => {
            state.mode = action.payload;
        },
        changeShowToast: (state, action: PayloadAction<boolean>) => {
            state.toast.show = action.payload;

            if (action.payload === false) {
                state.toast.header = '';
                state.toast.body = '';
            }
        },
        // Edit
        loadAnswerEdit: (state, action: PayloadAction<AnswerData>) => {
            state.answerEdit.data = action.payload;
            state.answerEdit.status = StatusEnum.Idle;
            state.mode = ModeEnum.Edit;
        },
        // List
        changeQuery: (state, action: PayloadAction<string>) => {
            state.answersList.query = action.payload;
        },
        changeTake: (state, action: PayloadAction<number>) => {
            state.answersList.take = action.payload;
        },
        loadNextPage: (state) => {
            if (state.answersList.data.length > 0) {
                state.answersList.skip = state.answersList.skip + state.answersList.take;
            }
        },
        loadPreviousPage: (state) => {
            if (state.answersList.skip > 0) {
                state.answersList.skip = Math.max(0, state.answersList.skip - state.answersList.take);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            // List
            .addCase(getAnswersAsync.pending, (state) => {
                state.answersList.status = StatusEnum.Loading;
            })
            .addCase(getAnswersAsync.fulfilled, (state, action) => {
                state.answersList.status = StatusEnum.Idle;
                state.answersList.data = action.payload;
            })
            .addCase(getAnswersAsync.rejected, (state) => {
                state.answersList.status = StatusEnum.Failed;
            })
            // Create
            .addCase(createAnswerAsync.pending, (state) => {
                state.answerCreate.status = StatusEnum.Loading;
            })
            .addCase(createAnswerAsync.fulfilled, (state, action) => {
                state.answerCreate.status = StatusEnum.Idle;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Answer`;
                state.toast.body = `Created with title "${action.payload.titleEn}"`;
            })
            .addCase(createAnswerAsync.rejected, (state) => {
                state.answerCreate.status = StatusEnum.Failed;
            })
            // Delete
            .addCase(deleteAnswerAsync.pending, (state) => {
                state.answerEdit.status = StatusEnum.Loading;
                state.answerEdit.operation = OperationEnum.Deleting;
            })
            .addCase(deleteAnswerAsync.fulfilled, (state, action) => {
                state.answerEdit.status = StatusEnum.Idle;
                state.answerEdit.operation = OperationEnum.None;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Answer`;
                state.toast.body = `Deleted with id "${action.payload}"`;
            })
            .addCase(deleteAnswerAsync.rejected, (state) => {
                state.answerEdit.status = StatusEnum.Failed;
                state.answerEdit.operation = OperationEnum.None;
            })
            // Edit
            .addCase(editAnswerAsync.pending, (state) => {
                state.answerEdit.status = StatusEnum.Loading;
                state.answerEdit.operation = OperationEnum.Saving;
            })
            .addCase(editAnswerAsync.fulfilled, (state, action) => {
                state.answerEdit.status = StatusEnum.Idle;
                state.answerEdit.operation = OperationEnum.None;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Answer`;
                state.toast.body = `Edited with title "${action.payload.titleEn}"`;
            })
            .addCase(editAnswerAsync.rejected, (state) => {
                state.answerEdit.status = StatusEnum.Failed;
                state.answerEdit.operation = OperationEnum.None;
            });
    },
});

export const {
    changeMode, changeShowToast, loadAnswerEdit, changeQuery, changeTake, loadNextPage, loadPreviousPage
} = answerSlice.actions;

export const selectAnswerMode = (state: RootState) => state.answer.mode;
export const selectAnswerToastShow = (state: RootState) => state.answer.toast.show;
export const selectAnswerToastHeader = (state: RootState) => state.answer.toast.header;
export const selectAnswerToastBody = (state: RootState) => state.answer.toast.body;

export const selectAnswersList = (state: RootState) => state.answer.answersList.data;
export const selectAnswersListQuery = (state: RootState) => state.answer.answersList.query;
export const selectAnswersListStatus = (state: RootState) => state.answer.answersList.status;
export const selectAnswersListSkip = (state: RootState) => state.answer.answersList.skip;
export const selectAnswersListTake = (state: RootState) => state.answer.answersList.take;

export const selectAnswerEdit = (state: RootState) => state.answer.answerEdit.data;
export const selectAnswerEditStatus = (state: RootState) => state.answer.answerEdit.status;
export const selectAnswerEditOperation = (state: RootState) => state.answer.answerEdit.operation;

export const selectAnswerCreateStatus = (state: RootState) => state.answer.answerCreate.status;

export default answerSlice.reducer;