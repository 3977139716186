import http from "../../shared/services/HttpCommon";
import { Filter } from "../../shared/models/Filter";
import { PagedList } from "../../shared/models/PagedList";
import { AuthHeaderService } from "../../shared/services/AuthHeaderService";
import { CreateQuestionData } from "./models/CreateQuestionData";
import { EditQuestionData } from "./models/EditQuestionData";

export async function getQuestions(token: string, filter: Filter): Promise<PagedList<EditQuestionData>> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const excludeIdQuery = filter.excludeIds && filter.excludeIds.length ? `&excludeIds=${filter.excludeIds.join('&excludeIds=')}` : '';
    const response = await http.get<EditQuestionData[]>(`/questions?query=${filter.query}&skip=${filter.skip}&take=${filter.take}${excludeIdQuery}`, headersConfig);
    return {
        data: response.data
    };
}

export async function getQuestion(token: string, id: string): Promise<{ data: EditQuestionData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    var response = await http.get<EditQuestionData>(`/questions/${id}`, headersConfig);
    return {
        data: response.data
    };
}

export async function createQuestion(token: string, createQuestionData: CreateQuestionData): Promise<{ data: EditQuestionData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const result = await http.post<EditQuestionData>(`/questions`, createQuestionData, headersConfig);
    return {
        data: result.data
    };
}

export async function editQuestion(token: string, editQuestionData: EditQuestionData): Promise<{ data: EditQuestionData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    await http.put<string>(`/questions/${editQuestionData.id}`, editQuestionData, headersConfig);
    return {
        data: editQuestionData
    };
}

export async function deleteQuestion(token: string, id: string): Promise<{ data: string }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    await http.delete<string>(`/questions/${id}`, headersConfig);
    return {
        data: id
    };
}