import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { RecommendationData } from '../../shared/models/Entities';
import { ModeEnum, OperationEnum, RecommendationFactorEnum, StatusEnum } from '../../shared/models/Enums';
import { Filter } from '../../shared/models/Filter';
import { getQuestions } from '../question/questionAPI';
import { CreateRecommendationData } from './models/CreateRecommendationData';
import { RecommendationState } from './models/RecommendationState';
import { createRecommendation, deleteRecommendation, editRecommendation, getRecommendation, getRecommendations } from './recommendationAPI';


const initialState: RecommendationState = {
    recommendationsList: {
        data: [],
        status: StatusEnum.Idle,
        query: '',
        skip: 0,
        take: 5,
    },
    recommendationEdit: {
        data: {
            id: '',
            questionsCount : 0,
            titleBg: '',
            titleEn: '',
            strongPointText : '',
            strongPointTextEn : '',
            weakPointText : '',
            weakPointTextEn : '',
            weakPointSelfText : '',
            weakPointSelfTextEn : '',
            type : RecommendationFactorEnum.Pay,
            questions: [],
        },
        status: StatusEnum.Idle,
        operation: OperationEnum.None,
        questionsList: {
            data: [],
            status: StatusEnum.Idle,
            query: '',
            skip: 0,
            take: 5,
        },
    },
    recommendationCreate: {
        status: StatusEnum.Idle,
        questionsList: {
            data: [],
            status: StatusEnum.Idle,
            query: '',
            skip: 0,
            take: 5,
        },
    },
    mode: ModeEnum.List,
    toast: {
        show: false,
        header: '',
        body: '',
    }
};

export const getRecommendationsAsync = createAsyncThunk(
    'recommendation/getRecommendations',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getRecommendations(state.login.token, filter);
        return response.data;
    }
);

export const getRecommendationAsync = createAsyncThunk(
    'recommendation/getRecommendation',
    async (id : string, { getState }) => {
        const state: any = getState();
        const response = await getRecommendation(state.login.token, id);
        return response.data;
    }
);

export const createRecommendationAsync = createAsyncThunk(
    'recommendation/createRecommendation',
    async (createData: CreateRecommendationData, { getState }) => {
        const state: any = getState();
        const response = await createRecommendation(state.login.token, createData);
        return response.data;
    }
);

export const editRecommendationAsync = createAsyncThunk(
    'recommendation/editRecommendation',
    async (editData: RecommendationData, { getState }) => {
        const state: any = getState();
        const response = await editRecommendation(state.login.token, editData);
        return response.data;
    }
);

export const deleteRecommendationAsync = createAsyncThunk(
    'recommendation/deleteRecommendation',
    async (recommendationId: string, { getState }) => {
        const state: any = getState();
        const response = await deleteRecommendation(state.login.token, recommendationId);
        return response.data;
    }
);

export const getQuestionsAsync = createAsyncThunk(
    'recommendation/getQuestions',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getQuestions(state.login.token, filter);
        return response.data;
    }
);

export const getCreateQuestionsAsync = createAsyncThunk(
    'recommendation/getCreateQuestions',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getQuestions(state.login.token, filter);
        return response.data;
    }
);

export const recommendationSlice = createSlice({
    name: 'recommendation',
    initialState,
    reducers: {
        // Global
        changeMode: (state, action: PayloadAction<ModeEnum>) => {
            state.mode = action.payload;
        },
        changeShowToast: (state, action: PayloadAction<boolean>) => {
            state.toast.show = action.payload;

            if (action.payload === false) {
                state.toast.header = '';
                state.toast.body = '';
            }
        },
        // Edit
        loadRecommendationEdit: (state, action: PayloadAction<RecommendationData>) => {
            state.recommendationEdit.data = action.payload;
            state.recommendationEdit.status = StatusEnum.Idle;
            state.mode = ModeEnum.Edit;
        },
        editQuestionsListChangeQuery: (state, action: PayloadAction<string>) => {
            state.recommendationEdit.questionsList.query = action.payload;
        },
        editQuestionsListLoadNextPage: (state) => {
            if (state.recommendationEdit.questionsList.data.length > 0) {
                state.recommendationEdit.questionsList.skip = state.recommendationEdit.questionsList.skip + state.recommendationEdit.questionsList.take;
            }
        },
        editQuestionsListLoadPreviousPage: (state) => {
            if (state.recommendationEdit.questionsList.skip > 0) {
                state.recommendationEdit.questionsList.skip = Math.max(0, state.recommendationEdit.questionsList.skip - state.recommendationEdit.questionsList.take);
            }
        },
        // List
        changeQuery: (state, action: PayloadAction<string>) => {
            state.recommendationsList.query = action.payload;
        },
        loadNextPage: (state) => {
            if (state.recommendationsList.data.length > 0) {
                state.recommendationsList.skip = state.recommendationsList.skip + state.recommendationsList.take;
            }
        },
        loadPreviousPage: (state) => {
            if (state.recommendationsList.skip > 0) {
                state.recommendationsList.skip = Math.max(0, state.recommendationsList.skip - state.recommendationsList.take);
            }
        },
        // Create
        createQuestionsListChangeQuery: (state, action: PayloadAction<string>) => {
            state.recommendationCreate.questionsList.query = action.payload;
        },
        createQuestionsListLoadNextPage: (state) => {
            if (state.recommendationCreate.questionsList.data.length > 0) {
                state.recommendationCreate.questionsList.skip = state.recommendationCreate.questionsList.skip + state.recommendationCreate.questionsList.take;
            }
        },
        createQuestionsListLoadPreviousPage: (state) => {
            if (state.recommendationCreate.questionsList.skip > 0) {
                state.recommendationCreate.questionsList.skip = Math.max(0, state.recommendationCreate.questionsList.skip - state.recommendationCreate.questionsList.take);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            // List
            .addCase(getRecommendationsAsync.pending, (state) => {
                state.recommendationsList.status = StatusEnum.Loading;
            })
            .addCase(getRecommendationsAsync.fulfilled, (state, action) => {
                state.recommendationsList.status = StatusEnum.Idle;
                state.recommendationsList.data = action.payload;
            })
            .addCase(getRecommendationsAsync.rejected, (state) => {
                state.recommendationsList.status = StatusEnum.Failed;
            })

            //Get
            .addCase(getRecommendationAsync.pending, (state) => {
                state.recommendationEdit.status = StatusEnum.Loading;
            })
            .addCase(getRecommendationAsync.fulfilled, (state, action) => {
                state.recommendationEdit.status = StatusEnum.Idle;
                state.recommendationEdit.data = action.payload;
                state.mode = ModeEnum.Edit;
            })
            .addCase(getRecommendationAsync.rejected, (state) => {
                state.recommendationEdit.status = StatusEnum.Failed;
            })

            // Create
            .addCase(createRecommendationAsync.pending, (state) => {
                state.recommendationCreate.status = StatusEnum.Loading;
            })
            .addCase(createRecommendationAsync.fulfilled, (state, action) => {
                state.recommendationCreate.status = StatusEnum.Idle;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Recommendation`;
                state.toast.body = `Created with title "${action.payload.titleEn}"`;
            })
            .addCase(createRecommendationAsync.rejected, (state) => {
                state.recommendationCreate.status = StatusEnum.Failed;
            })
            .addCase(getCreateQuestionsAsync.pending, (state) => {
                state.recommendationCreate.questionsList.status = StatusEnum.Loading;
            })
            .addCase(getCreateQuestionsAsync.fulfilled, (state, action) => {
                state.recommendationCreate.questionsList.status = StatusEnum.Idle;
                state.recommendationCreate.questionsList.data = action.payload;
            })
            .addCase(getCreateQuestionsAsync.rejected, (state) => {
                state.recommendationCreate.questionsList.status = StatusEnum.Failed;
            })
            // Delete
            .addCase(deleteRecommendationAsync.pending, (state) => {
                state.recommendationEdit.status = StatusEnum.Loading;
                state.recommendationEdit.operation = OperationEnum.Deleting;
            })
            .addCase(deleteRecommendationAsync.fulfilled, (state, action) => {
                state.recommendationEdit.status = StatusEnum.Idle;
                state.recommendationEdit.operation = OperationEnum.None;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Recommendation`;
                state.toast.body = `Deleted with id "${action.payload}"`;
            })
            .addCase(deleteRecommendationAsync.rejected, (state) => {
                state.recommendationEdit.status = StatusEnum.Failed;
                state.recommendationEdit.operation = OperationEnum.None;
            })
            // Edit
            .addCase(editRecommendationAsync.pending, (state) => {
                state.recommendationEdit.status = StatusEnum.Loading;
                state.recommendationEdit.operation = OperationEnum.Saving;
            })
            .addCase(editRecommendationAsync.fulfilled, (state, action) => {
                state.recommendationEdit.status = StatusEnum.Idle;
                state.recommendationEdit.operation = OperationEnum.None;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Recommendation`;
                state.toast.body = `Edited with title "${action.payload.titleEn}"`;
            })
            .addCase(editRecommendationAsync.rejected, (state) => {
                state.recommendationEdit.status = StatusEnum.Failed;
                state.recommendationEdit.operation = OperationEnum.None;
            })
            .addCase(getQuestionsAsync.pending, (state) => {
                state.recommendationEdit.questionsList.status = StatusEnum.Loading;
            })
            .addCase(getQuestionsAsync.fulfilled, (state, action) => {
                state.recommendationEdit.questionsList.status = StatusEnum.Idle;
                state.recommendationEdit.questionsList.data = action.payload;
            })
            .addCase(getQuestionsAsync.rejected, (state) => {
                state.recommendationEdit.questionsList.status = StatusEnum.Failed;
            });
    },
});

export const {
    changeMode, changeShowToast, loadRecommendationEdit, changeQuery, loadNextPage, loadPreviousPage,
    editQuestionsListChangeQuery, editQuestionsListLoadNextPage, editQuestionsListLoadPreviousPage,
    createQuestionsListChangeQuery, createQuestionsListLoadNextPage, createQuestionsListLoadPreviousPage
} = recommendationSlice.actions;

export const selectRecommendationMode = (state: RootState) => state.recommendation.mode;
export const selectRecommendationToastShow = (state: RootState) => state.recommendation.toast.show;
export const selectRecommendationToastHeader = (state: RootState) => state.recommendation.toast.header;
export const selectRecommendationToastBody = (state: RootState) => state.recommendation.toast.body;

export const selectRecommendationsList = (state: RootState) => state.recommendation.recommendationsList.data;
export const selectRecommendationsListQuery = (state: RootState) => state.recommendation.recommendationsList.query;
export const selectRecommendationsListStatus = (state: RootState) => state.recommendation.recommendationsList.status;
export const selectRecommendationsListSkip = (state: RootState) => state.recommendation.recommendationsList.skip;
export const selectRecommendationsListTake = (state: RootState) => state.recommendation.recommendationsList.take;

export const selectRecommendationEdit = (state: RootState) => state.recommendation.recommendationEdit.data;
export const selectRecommendationEditStatus = (state: RootState) => state.recommendation.recommendationEdit.status;
export const selectRecommendationEditOperation = (state: RootState) => state.recommendation.recommendationEdit.operation;
export const selectRecommendationEditQuestionsListData = (state: RootState) => state.recommendation.recommendationEdit.questionsList.data;
export const selectRecommendationEditQuestionsListStatus = (state: RootState) => state.recommendation.recommendationEdit.questionsList.status;
export const selectRecommendationEditQuestionsListQuery = (state: RootState) => state.recommendation.recommendationEdit.questionsList.query;
export const selectRecommendationEditQuestionsListSkip = (state: RootState) => state.recommendation.recommendationEdit.questionsList.skip;
export const selectRecommendationEditQuestionsListTake = (state: RootState) => state.recommendation.recommendationEdit.questionsList.take;

export const selectRecommendationCreateStatus = (state: RootState) => state.recommendation.recommendationCreate.status;
export const selectRecommendationCreateQuestionsListData = (state: RootState) => state.recommendation.recommendationCreate.questionsList.data;
export const selectRecommendationCreateQuestionsListStatus = (state: RootState) => state.recommendation.recommendationCreate.questionsList.status;
export const selectRecommendationCreateQuestionsListQuery = (state: RootState) => state.recommendation.recommendationCreate.questionsList.query;
export const selectRecommendationCreateQuestionsListSkip = (state: RootState) => state.recommendation.recommendationCreate.questionsList.skip;
export const selectRecommendationCreateQuestionsListTake = (state: RootState) => state.recommendation.recommendationCreate.questionsList.take;

export default recommendationSlice.reducer;