import { FC } from 'react';

import './TableContentSkeleton.css'

export const TableContentSkeleton: FC<{ numberOfColumns: number, numberOfRows: number }> = ({ numberOfColumns, numberOfRows }) => {
    const rowsData: any[] = [...Array(numberOfRows).map(x => ({}))];
    const colsData: any[] = [...Array(numberOfColumns).map(x => ({}))];

    return (
        <tbody>
            {rowsData.map((_d, i) =>
                <tr key={`row-${i}`}>
                    {colsData.map((_c, k) =>
                        <th key={`colum-${k}`}>
                            {
                                k % 3 !== 0
                                && <div className='skeleton skeleton-text'></div>
                            }
                            <div className='skeleton skeleton-text'></div>
                        </th>
                    )}
                </tr>
            )}
        </tbody>
    );
}