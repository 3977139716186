import http from "../../shared/services/HttpCommon";
import { Filter } from "../../shared/models/Filter";
import { PagedList } from "../../shared/models/PagedList";
import { AuthHeaderService } from "../../shared/services/AuthHeaderService";
import { CreateCompanyData } from "./models/CreateCompanyData";
import { EditCompanyData } from "./models/EditCompanyData";
import { CompanySectorData } from "./models/CompanySectorData";

export async function getCompanySectors(token: string): Promise<PagedList<CompanySectorData>> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const response = await http.get<CompanySectorData[]>(`/companysectors`, headersConfig);
    return {
        data: response.data
    };
}

export async function getCompanies(token: string, filter: Filter): Promise<PagedList<EditCompanyData>> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const excludeIdQuery = filter.excludeIds && filter.excludeIds.length ? `&excludeIds=${filter.excludeIds.join('&excludeIds=')}` : '';
    const response = await http.get<EditCompanyData[]>(`/companies?query=${filter.query}&skip=${filter.skip}&take=${filter.take}${excludeIdQuery}`, headersConfig);
    return {
        data: response.data
    };
}

export async function getCompany(token: string, id: string): Promise<{ data: EditCompanyData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const response = await http.get<EditCompanyData>(`/companies/${id}`, headersConfig);
    return {
        data: response.data
    };
}

export async function createCompany(token: string, createCompanyData: CreateCompanyData): Promise<{ data: EditCompanyData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const result = await http.post<EditCompanyData>(`/companies`, createCompanyData, headersConfig);
    return {
        data: result.data
    };
}

export async function editCompany(token: string, editCompanyData: EditCompanyData): Promise<{ data: EditCompanyData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    await http.put<string>(`/companies/${editCompanyData.id}`, editCompanyData, headersConfig);
    return {
        data: editCompanyData
    };
}

export async function deleteCompany(token: string, id: string): Promise<{ data: string }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    await http.delete<string>(`/companies/${id}`, headersConfig);
    return {
        data: id
    };
}