import { FC } from "react";
import { CSSTransition } from 'react-transition-group'

import './LoadingTransition.css';

import { CenteredSpinner } from "../CenteredSpinner/CenteredSpinner";
import { Alert } from "react-bootstrap";
import { ResourcesService } from "../../services/ResourcesService";

type LoadingTransitionProps = {
    cssClass: string,
    status: 'idle' | 'loading' | 'failed',
    children: JSX.Element
};

export const LoadingTransition: FC<LoadingTransitionProps> = ({ cssClass, status, children }) =>
    <div className={`loading-item-container-wrapper ${cssClass}`}>
        <CSSTransition in={status === 'idle'} timeout={500} classNames="loading-item-container-primary" unmountOnExit>
            <div className='loading-item-container'>
                {children}
            </div>
        </CSSTransition>

        <CSSTransition in={status === 'loading'} timeout={500} classNames="loading-item-container-secondary" unmountOnExit>
            <div className='loading-item-container'>
                <CenteredSpinner />
            </div>
        </CSSTransition>

        <CSSTransition in={status === 'failed'} timeout={500} classNames="loading-item-container-secondary" unmountOnExit>
            <div className='loading-item-container'>
                <Alert variant='warning'>
                    <i className="bi bi-exclamation-triangle"></i> {ResourcesService.translate('We could`t authenticate you. Please try again later.')}
                </Alert>
            </div>
        </CSSTransition>
    </div>