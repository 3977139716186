import { FC } from "react";
import { Button, Spinner } from "react-bootstrap";

import { OperationEnum, StatusEnum } from "../../models/Enums";
import { ResourcesService } from "../../services/ResourcesService";

type DeleteButtonProps = {
    status: StatusEnum,
    operation: OperationEnum,
    onDelete: Function,
    lang: 'BG' | 'EN'
};

export const DeleteButton: FC<DeleteButtonProps> = ({ status, operation, onDelete, lang }) =>
    <Button className='float-end ms-2' variant="outline-danger" size='sm' onClick={() => onDelete()} disabled={status !== StatusEnum.Idle}>
        {
            status === StatusEnum.Loading && operation === OperationEnum.Deleting
            && <Spinner animation="border" variant="primary" as="span" size="sm" role="status" aria-hidden="true">
                <span className="visually-hidden">{ResourcesService.translate('Loading...', lang)}</span>
            </Spinner>
        }
        <i className='bi bi-trash'></i>
        {
            (status !== StatusEnum.Loading || operation !== OperationEnum.Deleting)
            && <span> {ResourcesService.translate('Delete', lang)}</span>
        }
        {
            status === StatusEnum.Loading && operation === OperationEnum.Deleting
            && <span> {ResourcesService.translate('Deleting', lang)}</span>
        }
    </Button>