import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { ModeEnum, OperationEnum, QuestionTypeEnum, StatusEnum } from '../../shared/models/Enums';
import { Filter } from '../../shared/models/Filter';
import { getSections } from '../section/sectionAPI';
import { CreateSurveyData } from './models/CreateSurveyData';
import { EditSurveyData } from './models/EditSurveyData';
import { CreateLiveAnswer, LiveQuestion, LiveSurvey } from './models/LiveSurvey';
import { SurveyState } from './models/SurveyState';
import { LiveSurveyMapper } from './services/LiveSurveyMapper';
import { createSurvey, createSurveyAnswer, createUserSurvey, deleteSurvey, editSurvey, getSurvey, getSurveyAnswers, getSurveys, getUserSurvey, updateUserSurvey } from './surveyAPI';


const initialState: SurveyState = {
    surveysList: {
        data: [],
        status: StatusEnum.Idle,
        query: '',
        skip: 0,
        take: 5,
    },
    surveyEdit: {
        data: {
            id: '',
            titleBg: '',
            titleEn: '',
            employeeCount: 0,
            divisionName: '',
            periodName: '',
            sectorName: '',
            sectionsCount : 0,
            isLive: false,
            sections: []
        },
        status: StatusEnum.Idle,
        operation: OperationEnum.None,
        sectionsList: {
            data: [],
            status: StatusEnum.Idle,
            query: '',
            skip: 0,
            take: 5,
        },
    },
    surveyCreate: {
        status: StatusEnum.Idle,
        sectionsList: {
            data: [],
            status: StatusEnum.Idle,
            query: '',
            skip: 0,
            take: 5,
        },
    },
    surveyLive: {
        data: {
            id: '',
            titleBg: '',
            titleEn: '',
            questions: [],
            isLive: false,
        },
        currentQuestion: {
            id: '',
            titleBg: '',
            titleEn: '',
            subtitleBg: '',
            subtitleEn: '',
            type: QuestionTypeEnum.Checkbox,
            limit : 0,
            sectionId: '',
            isAnswered: false,
            answers: [],
            answered: [],
            questionConditionList: [],
        },
        status: StatusEnum.Idle,
        hasStarted: true,
        isCompleted: false,
    },
    mode: ModeEnum.List,
    toast: {
        show: false,
        header: '',
        body: '',
    }
};

export const getSurveysAsync = createAsyncThunk(
    'survey/getSurveys',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getSurveys(state.login.token, filter);
        return response.data;
    }
);

export const getSurveyAsync = createAsyncThunk(
    'survey/getSurvey',
    async (id: string, { getState }) => {
        const state: any = getState();
        const response = await getSurvey(state.login.token, id);
        return response.data;
    }
);

export const createSurveyAsync = createAsyncThunk(
    'survey/createSurvey',
    async (createData: CreateSurveyData, { getState }) => {
        const state: any = getState();
        const response = await createSurvey(state.login.token, createData);
        return response.data;
    }
);

export const editSurveyAsync = createAsyncThunk(
    'survey/editSurvey',
    async (editData: EditSurveyData, { getState }) => {
        const state: any = getState();
        const response = await editSurvey(state.login.token, editData);
        return response.data;
    }
);

export const deleteSurveyAsync = createAsyncThunk(
    'survey/deleteSurvey',
    async (surveyId: string, { getState }) => {
        const state: any = getState();
        const response = await deleteSurvey(state.login.token, surveyId);
        return response.data;
    }
);

export const getSectionsAsync = createAsyncThunk(
    'survey/getSections',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getSections(state.login.token, filter);
        return response.data;
    }
);

export const getCreateSectionsAsync = createAsyncThunk(
    'survey/getCreateSections',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getSections(state.login.token, filter);
        return response.data;
    }
);

export const loadLiveSurveyAsync = createAsyncThunk(
    'survey/loadLiveSurveyAsync',
    async (id: string, { getState }) => {
        const state: any = getState();
        const [surveyResult, answersResult] = await Promise.all([
            getSurvey(state.login.token, id),
            getSurveyAnswers(state.login.token, id),
        ]);

        const liveQuestions: LiveQuestion[] = LiveSurveyMapper.mapEditData(surveyResult, answersResult);

        const data: LiveSurvey = {
            id: surveyResult.data.id,
            titleBg: surveyResult.data.titleBg,
            titleEn: surveyResult.data.titleEn,
            questions: liveQuestions,
            isLive: surveyResult.data.isLive || false,
        };

        return data;
    }
);

export const createLiveSurveyAnswerAsync = createAsyncThunk(
    'survey/createLiveSurveyAnswerAsync',
    async (createLiveAnswers: CreateLiveAnswer[], { getState }) => {
        const state: any = getState();
        const response = await createSurveyAnswer(state.login.token, createLiveAnswers);
        return response.data;
    }
);

export const getUserSurveyAsync = createAsyncThunk(
    'survey/getUserSurveyAsync',
    async (data: { companyId: string, surveyId: string }, { getState }) => {
        const state: any = getState();
        const response = await getUserSurvey(state.login.token, data.companyId, data.surveyId);
        return response;
    }
);

export const createUserSurveyAsync = createAsyncThunk(
    'survey/createUserSurveyAsync',
    async (data: { companyId: string, surveyId: string }, { getState }) => {
        const state: any = getState();
        const response = await createUserSurvey(state.login.token, data.companyId, data.surveyId);
        return response;
    }
);

export const updateUserSurveyAsync = createAsyncThunk(
    'survey/updateUserSurveyAsync',
    async (data: { companyId: string, surveyId: string }, { getState }) => {
        const state: any = getState();
        const response = await updateUserSurvey(state.login.token, data.companyId, data.surveyId);
        return response;
    }
);

export const createLiveSurveyAnswerEndedAsync = createAsyncThunk(
    'survey/createLiveSurveyAnswerEndedAsync',
    async (createLiveAnswers: CreateLiveAnswer[], { getState }) => {
        const state: any = getState();
        const response = await createSurveyAnswer(state.login.token, createLiveAnswers);
        return response.data;
    }
);

export const surveySlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        // Global
        changeMode: (state, action: PayloadAction<ModeEnum>) => {
            state.mode = action.payload;
            state.surveyCreate.status = StatusEnum.Idle;
            state.surveyEdit.status = StatusEnum.Idle;
        },
        changeShowToast: (state, action: PayloadAction<boolean>) => {
            state.toast.show = action.payload;

            if (action.payload === false) {
                state.toast.header = '';
                state.toast.body = '';
            }
        },
        // Edit
        loadSurveyEdit: (state, action: PayloadAction<EditSurveyData>) => {
            state.surveyEdit.data = action.payload;
            state.surveyEdit.status = StatusEnum.Idle;
            state.mode = ModeEnum.Edit;
        },
        editSectionsListChangeQuery: (state, action: PayloadAction<string>) => {
            state.surveyEdit.sectionsList.query = action.payload;
        },
        editSectionsListLoadNextPage: (state) => {
            if (state.surveyEdit.sectionsList.data.length > 0) {
                state.surveyEdit.sectionsList.skip = state.surveyEdit.sectionsList.skip + state.surveyEdit.sectionsList.take;
            }
        },
        editSectionsListLoadPreviousPage: (state) => {
            if (state.surveyEdit.sectionsList.skip > 0) {
                state.surveyEdit.sectionsList.skip = Math.max(0, state.surveyEdit.sectionsList.skip - state.surveyEdit.sectionsList.take);
            }
        },
        // List
        changeQuery: (state, action: PayloadAction<string>) => {
            state.surveysList.query = action.payload;
        },
        loadNextPage: (state) => {
            if (state.surveysList.data.length > 0) {
                state.surveysList.skip = state.surveysList.skip + state.surveysList.take;
            }
        },
        loadPreviousPage: (state) => {
            if (state.surveysList.skip > 0) {
                state.surveysList.skip = Math.max(0, state.surveysList.skip - state.surveysList.take);
            }
        },
        // Create
        createSectionsListChangeQuery: (state, action: PayloadAction<string>) => {
            state.surveyCreate.sectionsList.query = action.payload;
        },
        createSectionsListLoadNextPage: (state) => {
            if (state.surveyCreate.sectionsList.data.length > 0) {
                state.surveyCreate.sectionsList.skip = state.surveyCreate.sectionsList.skip + state.surveyCreate.sectionsList.take;
            }
        },
        createSectionsListLoadPreviousPage: (state) => {
            if (state.surveyCreate.sectionsList.skip > 0) {
                state.surveyCreate.sectionsList.skip = Math.max(0, state.surveyCreate.sectionsList.skip - state.surveyCreate.sectionsList.take);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            // List
            .addCase(getSurveysAsync.pending, (state) => {
                state.surveysList.status = StatusEnum.Loading;
            })
            .addCase(getSurveysAsync.fulfilled, (state, action) => {
                state.surveysList.status = StatusEnum.Idle;
                state.surveysList.data = action.payload;
            })
            .addCase(getSurveysAsync.rejected, (state) => {
                state.surveysList.status = StatusEnum.Failed;
            })

            //Get
            .addCase(getSurveyAsync.pending, (state) => {
                state.surveyEdit.status = StatusEnum.Loading;
            })
            .addCase(getSurveyAsync.fulfilled, (state, action) => {
                state.surveyEdit.status = StatusEnum.Idle;
                state.surveyEdit.data = action.payload;
                state.mode = ModeEnum.Edit;
            })
            .addCase(getSurveyAsync.rejected, (state) => {
                state.surveyEdit.status = StatusEnum.Failed;
            })

            // Create
            .addCase(createSurveyAsync.pending, (state) => {
                state.surveyCreate.status = StatusEnum.Loading;
            })
            .addCase(createSurveyAsync.fulfilled, (state, action) => {
                state.surveyCreate.status = StatusEnum.Idle;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Survey`;
                state.toast.body = `Created with title "${action.payload.titleEn}"`;
            })
            .addCase(createSurveyAsync.rejected, (state) => {
                state.surveyCreate.status = StatusEnum.Failed;
            })
            .addCase(getCreateSectionsAsync.pending, (state) => {
                state.surveyCreate.sectionsList.status = StatusEnum.Loading;
            })
            .addCase(getCreateSectionsAsync.fulfilled, (state, action) => {
                state.surveyCreate.sectionsList.status = StatusEnum.Idle;
                state.surveyCreate.sectionsList.data = action.payload;
            })
            .addCase(getCreateSectionsAsync.rejected, (state) => {
                state.surveyCreate.sectionsList.status = StatusEnum.Failed;
            })
            // Delete
            .addCase(deleteSurveyAsync.pending, (state) => {
                state.surveyEdit.status = StatusEnum.Loading;
                state.surveyEdit.operation = OperationEnum.Deleting;
            })
            .addCase(deleteSurveyAsync.fulfilled, (state, action) => {
                state.surveyEdit.status = StatusEnum.Idle;
                state.surveyEdit.operation = OperationEnum.None;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Survey`;
                state.toast.body = `Deleted with id "${action.payload}"`;
            })
            .addCase(deleteSurveyAsync.rejected, (state) => {
                state.surveyEdit.status = StatusEnum.Failed;
                state.surveyEdit.operation = OperationEnum.None;
            })
            // Edit
            .addCase(editSurveyAsync.pending, (state) => {
                state.surveyEdit.status = StatusEnum.Loading;
                state.surveyEdit.operation = OperationEnum.Saving;
            })
            .addCase(editSurveyAsync.fulfilled, (state, action) => {
                state.surveyEdit.status = StatusEnum.Idle;
                state.surveyEdit.operation = OperationEnum.None;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Survey`;
                state.toast.body = `Edited with title "${action.payload.titleEn}"`;
            })
            .addCase(editSurveyAsync.rejected, (state) => {
                state.surveyEdit.status = StatusEnum.Failed;
                state.surveyEdit.operation = OperationEnum.None;
            })
            .addCase(getSectionsAsync.pending, (state) => {
                state.surveyEdit.sectionsList.status = StatusEnum.Loading;
            })
            .addCase(getSectionsAsync.fulfilled, (state, action) => {
                state.surveyEdit.sectionsList.status = StatusEnum.Idle;
                state.surveyEdit.sectionsList.data = action.payload;
            })
            .addCase(getSectionsAsync.rejected, (state) => {
                state.surveyEdit.sectionsList.status = StatusEnum.Failed;
            })
            // Live
            .addCase(loadLiveSurveyAsync.pending, (state) => {
                state.surveyLive.status = StatusEnum.Loading;
            })
            .addCase(loadLiveSurveyAsync.fulfilled, (state, action) => {
                state.surveyLive.status = StatusEnum.Idle;
                state.surveyLive.data = action.payload;
                let newQuestions = action.payload.questions;

                let shouldBreak = true;
                while (shouldBreak) {
                    let notAnswered = newQuestions.filter(q => !q.isAnswered);
                    if (!notAnswered || !notAnswered.length) {
                        state.surveyLive.isCompleted = true;
                        shouldBreak = false;
                        return;
                    }

                    let questionToShow = notAnswered[0];

                    if (questionToShow.questionConditionList && questionToShow.questionConditionList.length) {
                        const parentQuestionsIds = questionToShow.questionConditionList.map(qcl => qcl.sourceQuestionId);
                        const parentQuestions = newQuestions.filter(nq => parentQuestionsIds.includes(nq.id));
                        if (parentQuestions && parentQuestions.length) {
                            const notAnsweredParent = parentQuestions.find(pq => !pq.isAnswered);
                            if (notAnsweredParent) {
                                questionToShow = notAnsweredParent;
                            } else {
                                let shouldHideQuestion = false;
                                for (let i = 0; i < parentQuestions.length; i++) {
                                    const parentQuestion = parentQuestions[i];
                                    const metCondition = questionToShow.questionConditionList.find(qc =>
                                        qc.sourceQuestionId === parentQuestion.id
                                        && parentQuestion.answered.some(a => a.id === qc.answerId));
                                    if (metCondition) {
                                        shouldHideQuestion = true;
                                        continue;
                                    }
                                }

                                if (shouldHideQuestion) {
                                    const questionIndex = state.surveyLive.data.questions.findIndex(q => q.id === questionToShow.id);
                                    if (questionIndex > -1) {
                                        state.surveyLive.data.questions[questionIndex].isAnswered = true;
                                    }
                                    newQuestions = newQuestions.filter(q => q.id !== questionToShow.id);
                                    continue;
                                }
                            }
                        }
                    }

                    state.surveyLive.currentQuestion = questionToShow;
                    shouldBreak = false;
                }
            })
            .addCase(loadLiveSurveyAsync.rejected, (state) => {
                state.surveyLive.status = StatusEnum.Failed;
            })
            .addCase(createLiveSurveyAnswerAsync.pending, (state) => {
                state.surveyLive.status = StatusEnum.Loading;
            })
            .addCase(createLiveSurveyAnswerAsync.fulfilled, (state, action) => {
                state.surveyLive.status = StatusEnum.Idle;
                let newQuestions = LiveSurveyMapper.mapLiveData(state.surveyLive.data, action.payload);
                state.surveyLive.data.questions = newQuestions;

                let shouldBreak = true;
                while (shouldBreak) {
                    let notAnswered = newQuestions.filter(q => !q.isAnswered);
                    if (!notAnswered || !notAnswered.length) {
                        state.surveyLive.currentQuestion = {
                            id: '',
                            titleBg: '',
                            titleEn: '',
                            subtitleBg: '',
                            subtitleEn: '',
                            type: QuestionTypeEnum.Checkbox,
                            limit : 0,
                            sectionId: '',
                            isAnswered: false,
                            answers: [],
                            answered: [],
                            questionConditionList: [],
                        };
                        state.surveyLive.isCompleted = true;
                        shouldBreak = false;
                        return;
                    }

                    let questionToShow = notAnswered[0];

                    if (questionToShow.questionConditionList && questionToShow.questionConditionList.length) {
                        const parentQuestionsIds = questionToShow.questionConditionList.map(qcl => qcl.sourceQuestionId);
                        const parentQuestions = newQuestions.filter(nq => parentQuestionsIds.includes(nq.id));
                        if (parentQuestions && parentQuestions.length) {
                            const notAnsweredParent = parentQuestions.find(pq => !pq.isAnswered);
                            if (notAnsweredParent) {
                                questionToShow = notAnsweredParent;
                            } else {
                                let shouldHideQuestion = false;
                                for (let i = 0; i < parentQuestions.length; i++) {
                                    const parentQuestion = parentQuestions[i];

                                    const metCondition = questionToShow.questionConditionList.find(qc =>
                                        qc.sourceQuestionId === parentQuestion.id
                                        && parentQuestion.answered.some(a => a.id === qc.answerId));
                                    if (metCondition) {
                                        shouldHideQuestion = true;
                                        continue;
                                    }
                                }

                                if (shouldHideQuestion) {
                                    const questionIndex = state.surveyLive.data.questions.findIndex(q => q.id === questionToShow.id);
                                    if (questionIndex > -1) {
                                        state.surveyLive.data.questions[questionIndex].isAnswered = true;
                                    }
                                    newQuestions = newQuestions.filter(q => q.id !== questionToShow.id);
                                    continue;
                                }
                            }
                        }
                    }

                    state.surveyLive.currentQuestion = questionToShow;
                    shouldBreak = false;
                }
            })
            .addCase(createLiveSurveyAnswerAsync.rejected, (state) => {
                state.surveyLive.status = StatusEnum.Failed;
            })
            .addCase(getUserSurveyAsync.fulfilled, (state, action) => {
                const userSurvey = action.payload;
                state.surveyLive.isCompleted = !!userSurvey.ended;
            });
    },
});

export const {
    changeMode, changeShowToast, loadSurveyEdit, changeQuery, loadNextPage, loadPreviousPage,
    editSectionsListChangeQuery, editSectionsListLoadNextPage, editSectionsListLoadPreviousPage,
    createSectionsListChangeQuery, createSectionsListLoadNextPage, createSectionsListLoadPreviousPage
} = surveySlice.actions;

export const selectSurveyMode = (state: RootState) => state.survey.mode;
export const selectSurveyToastShow = (state: RootState) => state.survey.toast.show;
export const selectSurveyToastHeader = (state: RootState) => state.survey.toast.header;
export const selectSurveyToastBody = (state: RootState) => state.survey.toast.body;

export const selectSurveysList = (state: RootState) => state.survey.surveysList.data;
export const selectSurveysListQuery = (state: RootState) => state.survey.surveysList.query;
export const selectSurveysListStatus = (state: RootState) => state.survey.surveysList.status;
export const selectSurveysListSkip = (state: RootState) => state.survey.surveysList.skip;
export const selectSurveysListTake = (state: RootState) => state.survey.surveysList.take;

export const selectSurveyEdit = (state: RootState) => state.survey.surveyEdit.data;
export const selectSurveyEditStatus = (state: RootState) => state.survey.surveyEdit.status;
export const selectSurveyEditOperation = (state: RootState) => state.survey.surveyEdit.operation;
export const selectSurveyEditSectionsListData = (state: RootState) => state.survey.surveyEdit.sectionsList.data;
export const selectSurveyEditSectionsListStatus = (state: RootState) => state.survey.surveyEdit.sectionsList.status;
export const selectSurveyEditSectionsListQuery = (state: RootState) => state.survey.surveyEdit.sectionsList.query;
export const selectSurveyEditSectionsListSkip = (state: RootState) => state.survey.surveyEdit.sectionsList.skip;
export const selectSurveyEditSectionsListTake = (state: RootState) => state.survey.surveyEdit.sectionsList.take;

export const selectSurveyCreateStatus = (state: RootState) => state.survey.surveyCreate.status;
export const selectSurveyCreateSectionsListData = (state: RootState) => state.survey.surveyCreate.sectionsList.data;
export const selectSurveyCreateSectionsListStatus = (state: RootState) => state.survey.surveyCreate.sectionsList.status;
export const selectSurveyCreateSectionsListQuery = (state: RootState) => state.survey.surveyCreate.sectionsList.query;
export const selectSurveyCreateSectionsListSkip = (state: RootState) => state.survey.surveyCreate.sectionsList.skip;
export const selectSurveyCreateSectionsListTake = (state: RootState) => state.survey.surveyCreate.sectionsList.take;

export const selectSurveyLive = (state: RootState) => state.survey.surveyLive.data;
export const selectSurveyLiveCurrentQuestion = (state: RootState) => state.survey.surveyLive.currentQuestion;
export const selectSurveyLiveStatus = (state: RootState) => state.survey.surveyLive.status;
export const selectSurveyLiveIsCompleted = (state: RootState) => state.survey.surveyLive.isCompleted;

export default surveySlice.reducer;