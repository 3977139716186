import { FC } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { ResourcesService } from "../../services/ResourcesService";

type NotificationToastProps = {
    show: boolean,
    setShow: Function,
    dispatch: Function,
    header: string,
    body: string,
};

export const NotificationToast: FC<NotificationToastProps> = ({ show, setShow, dispatch, header, body }) =>
    <ToastContainer className="p-3" position={"top-end"}>
        <Toast delay={7000} autohide onClose={() => dispatch(setShow(false))} show={show}>
            <Toast.Header>
                <strong className="me-auto text-success"><i className='bi bi-check-circle-fill'></i> {header}</strong>
                <small className="text-muted">{ResourcesService.translate('just now')}</small>
            </Toast.Header>
            <Toast.Body>{body}</Toast.Body>
        </Toast>
    </ToastContainer>