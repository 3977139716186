import { FC } from 'react';
import { Col, Form } from 'react-bootstrap';
import { QuestionTypeEnum, StatusEnum } from '../../../shared/models/Enums';
import { LiveAnswer } from '../models/LiveSurvey';

type SurveyLiveAnswersQuestionTextareaProps = {
    status: StatusEnum,
    questionType: QuestionTypeEnum,
    questionLimit: number,
    questionAnswers: LiveAnswer[],
    questionAnswersSelected: string[],
    inputAnswer: string,
    setInputAnswer: Function,
};

export const SurveyLiveAnswersQuestionTextarea: FC<SurveyLiveAnswersQuestionTextareaProps> = ({
    status, questionType, questionLimit, questionAnswers, questionAnswersSelected, inputAnswer, setInputAnswer, 
}) =>
    <>
        {
            status === StatusEnum.Idle &&
            questionType === QuestionTypeEnum.Textarea &&
            <Col md>
                <Form.Control as="textarea" maxLength={questionLimit > 0 ? questionLimit : undefined} rows={3} onChange={e => setInputAnswer(e.target.value)}/>                
            </Col>

        }
    </>