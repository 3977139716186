import { FC, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { CancelButton } from '../../../shared/components/CancelButton/CancelButton';
import { DeleteButton } from '../../../shared/components/DeleteButton/DeleteButton';
import { GenericLayout } from '../../../shared/components/GenericLayout/GenericLayout';
import { GenericList } from '../../../shared/components/GenericList/GenericList';
import { SaveButton } from '../../../shared/components/SaveButton/SaveButton';
import { VerticallyCenteredModal } from '../../../shared/components/VerticallyCenteredModal/VerticallyCenteredModal';
import { ModeEnum, OperationEnum, StatusEnum } from '../../../shared/models/Enums';
import { SectionData } from '../../../shared/models/Entities';
import { SectionAccordion } from '../SectionAccordion/SectionAccordion';
import { FormGroupId } from '../../../shared/components/FormGroupId/FormGroupId';
import { FormGroupTextarea } from '../../../shared/components/FormGroupTextarea/FormGroupTextarea';
import { FormWithHandle } from '../../../shared/components/FormWithHandle/FormWithHandle';
import { HelperService } from '../../../shared/services/HelperService';
import { FormGroupCheckboxSwitch } from '../../../shared/components/FormGroupCheckboxSwitch/FormGroupCheckboxSwitch';
import { ResourcesService } from '../../../shared/services/ResourcesService';
import { selectUserLanguage } from '../../login/loginSlice';
import { useAppSelector } from '../../../app/hooks';
import { FormGroupNumber } from '../../../shared/components/FormGroupNumber/FormGroupNumber';
import { FormGroupText } from '../../../shared/components/FormGroupTextarea/FormGroupText';
import { CompanySectorsType } from '../../company/CompanySectorsType/CompanySectorsType';
import { CompanySectorData } from '../../company/models/CompanySectorData';


type SurveyLayoutProps = {
    surveyId?: string,
    surveyTitleBG: string,
    surveyTitleEN: string,
    surveyEmployeeCount: number,
    surveySectorName: string,
    surveyDivisionName: string,
    surveyPeriodName: string,
    surveyIsLive: boolean,

    surveySections: SectionData[],
    surveyStatus: StatusEnum,
    surveyOperation: OperationEnum,

    dispatch: Function,
    changeMode: Function,
    deleteSurvey: Function,
    saveSurvey: Function,

    sectionsListData: SectionData[],
    sectionsListStatus: StatusEnum,
    sectionsListQuery: string,
    sectionsListSkip: number,
    sectionsListTake: number,
    sectionsSetQuery: Function,
    sectionsOnNext: Function,
    sectionsOnPrevious: Function,
    sectionsListLoadData: Function,

    sectors: CompanySectorData[],
};

export const SurveyLayout: FC<SurveyLayoutProps> = ({
    surveyId,
    surveyTitleBG,
    surveyTitleEN,
    surveyIsLive,
    surveyEmployeeCount,
    surveySectorName,
    surveyDivisionName,
    surveyPeriodName,
    surveySections,
    surveyStatus,
    surveyOperation,

    dispatch,
    changeMode,
    deleteSurvey,
    saveSurvey,

    sectionsListData,
    sectionsListStatus,
    sectionsListQuery,
    sectionsListSkip,
    sectionsListTake,
    sectionsSetQuery,
    sectionsOnNext,
    sectionsOnPrevious,
    sectionsListLoadData,

    sectors,

}) => {
    const lang = useAppSelector(selectUserLanguage);
    const [modalShow, setModalShow] = useState(false);
    const [titleBg, setTitleBG] = useState(surveyTitleBG);
    const [titleEn, setTitleEN] = useState(surveyTitleEN);
    const [isLive, setIsLive] = useState(surveyIsLive);
    const [employeeCount, setEmployeeCount] = useState(surveyEmployeeCount);
    const [sectorName, setSectorName] = useState(surveySectorName);
    const [divisionName, setDivisionName] = useState(surveyDivisionName);
    const [periodName, setPeriodName] = useState(surveyPeriodName);
    const [sections, setSections] = useState(surveySections);
    const [sectionsModalShow, setSectionsModalShow] = useState(false);

    useEffect(() => {
        if (sectionsModalShow) {
            dispatch(sectionsListLoadData({
                skip: sectionsListSkip,
                take: sectionsListTake,
                query: sectionsListQuery,
                excludeIds: sections.map(a => a.id)
            }));
        }
    }, [sectionsModalShow, dispatch, sectionsListLoadData, sectionsListQuery, sectionsListSkip, sectionsListTake, sections]);

    const disabled = surveyStatus !== StatusEnum.Idle;

    const onSave = () => {
        if (surveyId) {
            dispatch(saveSurvey({
                id: surveyId,
                titleBg,
                titleEn,
                employeeCount,
                sectorName,
                divisionName,
                periodName,
                isLive,
                sections: sections,
            }));

            return;
        }

        dispatch(saveSurvey({
            titleBg,
            titleEn,
            employeeCount,
            sectorName,
            divisionName,
            periodName,
            isLive,
            sections: sections,
        }));
    };

    const addSection = (section: SectionData) => HelperService.add(section, sections, setSections);
    const removeQuestion = (section: SectionData) => HelperService.remove(section, sections, setSections);

    const tableHeaders = ['#', ResourcesService.translate('Title', lang)];
    const buttons = [
        <DeleteButton lang={lang} status={surveyStatus} operation={surveyOperation} onDelete={() => setModalShow(true)} />,
        <SaveButton lang={lang} status={surveyStatus} operation={surveyOperation} onSave={() => onSave()} />,
        <CancelButton lang={lang} status={surveyStatus} onCancel={() => dispatch(changeMode(ModeEnum.List))} />,
    ];

    return (
        <GenericLayout
            lang={lang}
            onBack={() => dispatch(changeMode(ModeEnum.List))}
            status={surveyStatus}
            h2={`${surveyId ? ResourcesService.translate('Edit', lang) : ResourcesService.translate('Create', lang)} ${ResourcesService.translate('Survey', lang)}`}
            buttons={buttons}
        >
            <FormWithHandle onSubmit={() => onSave()}>
                <>
                    {
                        surveyId &&
                        <FormGroupId lang={lang} controlId='surveyId' defaultValue={surveyId} />
                    }
                </>
                <FormGroupTextarea label={ResourcesService.translate('Title', lang)} controlId='surveyTitle' valueBG={titleBg} setValueBG={setTitleBG} valueEN={titleEn} setValueEN={setTitleEN} disabled={disabled} />
                <FormGroupNumber label={ResourcesService.translate('Division Employee Count', lang)} controlId='employeeCount' value={employeeCount} setValue={setEmployeeCount} disabled={disabled} />
                <FormGroupText label={ResourcesService.translate('Division name', lang)} controlId='divisionName' value={divisionName} setValue={setDivisionName} disabled={disabled} />
                <FormGroupText label={ResourcesService.translate('Period name', lang)} controlId='periodName' value={periodName} setValue={setPeriodName} disabled={disabled} />
                <FormGroupText label={ResourcesService.translate('Division sector', lang)} controlId='sectorName' value={sectorName} setValue={setSectorName} disabled={disabled} />

                <FormGroupCheckboxSwitch label={ResourcesService.translate('Is Live', lang)} value={isLive} setValue={setIsLive} disabled={disabled} />
                <h4>{ResourcesService.translate('Section', lang)}</h4>
                <>
                    {
                        sections.map((s, i) =>
                            <Row className='mb-3' key={`questionId-${s.id}`}>
                                <Col>
                                    <SectionAccordion section={s} />
                                </Col>
                                <Col sm='4'>
                                    <DeleteButton lang={lang} status={StatusEnum.Idle} operation={OperationEnum.None} onDelete={() => removeQuestion(s)} />
                                </Col>
                            </Row>
                        )

                    }
                </>
            </FormWithHandle>

            <Row className='mb-3'>
                <Col>
                    <Button variant="outline-secondary" size='sm' disabled={disabled} onClick={() => setSectionsModalShow(true)}>
                        <i className='bi bi-plus-lg'></i> {ResourcesService.translate('Add section', lang)}
                    </Button>
                </Col>
            </Row>
            <>
                {
                    surveyId &&
                    <VerticallyCenteredModal
                        id='survey-delete-confirmation-dialog'
                        lang={lang}
                        title={ResourcesService.translate('Delete Survey', lang)}
                        show={modalShow}
                        onCancel={() => setModalShow(false)}
                        onOk={() => dispatch(deleteSurvey(surveyId))}
                    >
                        <p>{ResourcesService.translate('Are you sure you want to delete the item?', lang)}</p>
                    </VerticallyCenteredModal>
                }
            </>
            <VerticallyCenteredModal
                id='survey-add-section-dialog'
                lang={lang}
                title={ResourcesService.translate('Select section', lang)}
                show={sectionsModalShow}
                onCancel={() => setSectionsModalShow(false)}
                onOk={() => setSectionsModalShow(false)}
            >
                <GenericList
                    lang={lang}
                    headContent={
                        <tr>
                            {
                                tableHeaders.map(h => <th key={h}>{h}</th>)
                            }
                        </tr>
                    }
                    bodyContent={
                        sectionsListData.map((d, i) =>
                            <tr key={i} onClick={() => addSection(d)}>
                                <td>{d.id}</td>
                                <td>{ResourcesService.translateInput(d.titleBg, d.titleEn, lang)}</td>
                            </tr>
                        )
                    }
                    listStatus={sectionsListStatus}
                    numberOfRows={5}
                    numberOfColumns={tableHeaders.length}
                    searchValue={sectionsListQuery}
                    onSearch={(value: string) => dispatch(sectionsSetQuery(value))}
                    onNext={() => dispatch(sectionsOnNext())}
                    onPrevious={() => dispatch(sectionsOnPrevious())}
                />
            </VerticallyCenteredModal>
        </GenericLayout>
    );
}