import { FC } from 'react';

import { changeMode } from '../answerSlice';

import { GenericList } from '../../../shared/components/GenericList/GenericList';
import { ModeEnum, StatusEnum } from '../../../shared/models/Enums';
import { AnswerData } from '../../../shared/models/Entities';
import { ResourcesService } from '../../../shared/services/ResourcesService';
import { selectUserLanguage } from '../../login/loginSlice';
import { useAppSelector } from '../../../app/hooks';

type AnswersListProps = {
    answers: AnswerData[],
    answerStatus: StatusEnum,
    dispatch: Function
    loadAnswerEdit: Function,
    query: string
    setQuery: Function,
    onNext: Function,
    onPrevious: Function,
};

export const AnswersList: FC<AnswersListProps> = ({
    answers, answerStatus, dispatch, loadAnswerEdit, query, setQuery, onNext, onPrevious
}) => {
    const lang = useAppSelector(selectUserLanguage);
    const tableHeaders = ['#', ResourcesService.translate('Title', lang)];

    return (
        <GenericList
            lang={lang}
            h2={ResourcesService.translate('Answers', lang)}
            headContent={
                <tr>
                    {
                        tableHeaders.map(h => <th key={h}>{h}</th>)
                    }                    
                </tr>
            }
            bodyContent={
                answers.map((d, i) =>
                    <tr key={i} onClick={() => dispatch(loadAnswerEdit(d))}>
                        <td>{d.id.substring(0, 8)}...</td>
                        <td>{ResourcesService.translateInput(d.titleBg, d.titleEn, lang)}</td>
                    </tr>
                )
            }
            listStatus={answerStatus}
            numberOfRows={5}
            numberOfColumns={tableHeaders.length}
            onCreateClick={() => dispatch(changeMode(ModeEnum.Create))}
            searchValue={query}
            onSearch={(value: string) => dispatch(setQuery(value))}
            onNext={() => dispatch(onNext())}
            onPrevious={() => dispatch(onPrevious())}
        />
    );
}