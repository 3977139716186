import { FC } from "react";

import { useAppSelector } from "../../../app/hooks";
import { Login } from "../../../features/login/Login";
import { selectLoginToken } from "../../../features/login/loginSlice";

type RequireAuthProps = {
    children: JSX.Element,
};

export const RequireAuth: FC<RequireAuthProps> = ({ children }) => {   
    const token = useAppSelector(selectLoginToken);    
    return !token ? <Login /> : children;
}