import { FC } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { RecommendationFactorEnum } from "../../shared/models/Enums";
import { ResourcesService } from "../../shared/services/ResourcesService";

type FormGroupSelectFactorTypeProps = {
    controlId: string,
    disabled: boolean,
    setValue: Function,
    defaultValue?: string | number | readonly string[] | undefined,
};

export const FormGroupSelectFactorType: FC<FormGroupSelectFactorTypeProps> = ({ controlId, disabled, setValue, defaultValue }) =>
    <Form.Group as={Row} className="mb-3" controlId={controlId}>
        <Form.Label column sm="2">{ResourcesService.translate('Factor')}</Form.Label>
        <Col sm="10">
            <Form.Select disabled={disabled} defaultValue={defaultValue} onChange={e => setValue(e.currentTarget.value)}>
                <option value={RecommendationFactorEnum.Pay}>{ResourcesService.translate('Pay')}</option>
                <option value={RecommendationFactorEnum.Career}>{ResourcesService.translate('Career')}</option>
                <option value={RecommendationFactorEnum.Empowerment}>{ResourcesService.translate('Empowerment')}</option>
                <option value={RecommendationFactorEnum.Organization}>{ResourcesService.translate('Organization')}</option>
                <option value={RecommendationFactorEnum.Security}>{ResourcesService.translate('Security')}</option>
                <option value={RecommendationFactorEnum.Relationships}>{ResourcesService.translate('Relationships')}</option>
                <option value={RecommendationFactorEnum.Leadership}>{ResourcesService.translate('Leadership')}</option>
                <option value={RecommendationFactorEnum.Alignment}>{ResourcesService.translate('Alignment')}</option>
                {/* <option value={RecommendationFactorEnum.Informative}>Informative</option> */}
            </Form.Select>
        </Col>
    </Form.Group>