import { FC } from "react";
import { Col, Form, Row } from "react-bootstrap";

type FormGroupCheckboxSwitchProps = {
    value: boolean,
    setValue: Function,
    disabled: boolean,
    label: string,
};

export const FormGroupCheckboxSwitch: FC<FormGroupCheckboxSwitchProps> = ({ value, setValue, disabled, label }) =>
    <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2" htmlFor='custom-switch'>{label}</Form.Label>
        <Col sm="10">
            <Form.Check className='pt-2 pb-2' type="switch" id="custom-switch" defaultChecked={value} onChange={(e) => setValue(e.target.checked)} disabled={disabled} />
        </Col>
    </Form.Group>