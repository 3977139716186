import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { ModeEnum, OperationEnum, QuestionTypeEnum, StatusEnum } from '../../shared/models/Enums';
import { Filter } from '../../shared/models/Filter';
import { getAnswers } from '../answer/answerAPI';
import { CreateQuestionData } from './models/CreateQuestionData';
import { EditQuestionData } from './models/EditQuestionData';
import { QuestionState } from './models/QuestionState';
import { createQuestion, getQuestions, editQuestion, deleteQuestion, getQuestion } from './questionAPI';

const initialState: QuestionState = {
    questionsList: {
        data: [],
        status: StatusEnum.Idle,
        query: '',
        skip: 0,
        take: 5,
    },
    questionEdit: {
        data: {
            id: '',
            titleBg: '',
            titleEn: '',
            subtitleBg: '',
            subtitleEn: '',
            hasRevertedAnswers : false,
            type: QuestionTypeEnum.Radio,
            answers: [],
            questionConditionList: [],
            code : '',
            uniqueCode : '',
            limit : 0,
        },
        status: StatusEnum.Idle,
        operation: OperationEnum.None,
        answersList: {
            data: [],
            status: StatusEnum.Idle,
            query: '',
            skip: 0,
            take: 5,
        },
    },
    questionCreate: {
        status: StatusEnum.Idle,
        answersList: {
            data: [],
            status: StatusEnum.Idle,
            query: '',
            skip: 0,
            take: 5,
        },
    },
    conditionQuestions: {
        data: [],
        status: StatusEnum.Idle,
        query: '',
        skip: 0,
        take: 5,
    },
    mode: ModeEnum.List,
    toast: {
        show: false,
        header: '',
        body: '',
    }
};

export const getQuestionsAsync = createAsyncThunk(
    'question/getQuestions',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getQuestions(state.login.token, filter);
        return response.data;
    }
);

export const getQuestionAsync = createAsyncThunk(
    'question/getQuestion',
    async (questionId: string, { getState }) => {
        const state: any = getState();
        const response = await getQuestion(state.login.token, questionId);
        return response.data;
    }
);

export const createQuestionAsync = createAsyncThunk(
    'question/createQuestion',
    async (createData: CreateQuestionData, { getState }) => {
        const state: any = getState();
        const response = await createQuestion(state.login.token, createData);
        return response.data;
    }
);

export const editQuestionAsync = createAsyncThunk(
    'question/editQuestion',
    async (editData: EditQuestionData, { getState }) => {
        const state: any = getState();
        const response = await editQuestion(state.login.token, editData);
        return response.data;
    }
);

export const deleteQuestionAsync = createAsyncThunk(
    'question/deleteQuestion',
    async (questionId: string, { getState }) => {
        const state: any = getState();
        const response = await deleteQuestion(state.login.token, questionId);
        return response.data;
    }
);

export const getEditAnswersAsync = createAsyncThunk(
    'question/getEditAnswers',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getAnswers(state.login.token, filter);
        return response.data;
    }
);

export const getCreateAnswersAsync = createAsyncThunk(
    'question/getCreateAnswers',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getAnswers(state.login.token, filter);
        return response.data;
    }
);

export const getConditionQuestionsAsync = createAsyncThunk(
    'section/getConditionQuestions',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getQuestions(state.login.token, filter);
        return response.data;
    }
);

export const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        // Global
        changeMode: (state, action: PayloadAction<ModeEnum>) => {
            state.mode = action.payload;
        },
        changeShowToast: (state, action: PayloadAction<boolean>) => {
            state.toast.show = action.payload;

            if (action.payload === false) {
                state.toast.header = '';
                state.toast.body = '';
            }
        },
        // Edit
        loadQuestionEdit: (state, action: PayloadAction<EditQuestionData>) => {
            state.questionEdit.data = action.payload;
            state.questionEdit.status = StatusEnum.Idle;
            state.mode = ModeEnum.Edit;
        },
        editAnswersListChangeQuery: (state, action: PayloadAction<string>) => {
            state.questionEdit.answersList.query = action.payload;
        },
        editAnswersListLoadNextPage: (state) => {
            if (state.questionEdit.answersList.data.length > 0) {
                state.questionEdit.answersList.skip = state.questionEdit.answersList.skip + state.questionEdit.answersList.take;
            }
        },
        editAnswersListLoadPreviousPage: (state) => {
            if (state.questionEdit.answersList.skip > 0) {
                state.questionEdit.answersList.skip = Math.max(0, state.questionEdit.answersList.skip - state.questionEdit.answersList.take);
            }
        },
        // List
        changeQuery: (state, action: PayloadAction<string>) => {
            state.questionsList.query = action.payload;
        },
        loadNextPage: (state) => {
            if (state.questionsList.data.length > 0) {
                state.questionsList.skip = state.questionsList.skip + state.questionsList.take;
            }
        },
        loadPreviousPage: (state) => {
            if (state.questionsList.skip > 0) {
                state.questionsList.skip = Math.max(0, state.questionsList.skip - state.questionsList.take);
            }
        },
        // Create
        createAnswersListChangeQuery: (state, action: PayloadAction<string>) => {
            state.questionCreate.answersList.query = action.payload;
        },
        createAnswersListLoadNextPage: (state) => {
            if (state.questionCreate.answersList.data.length > 0) {
                state.questionCreate.answersList.skip = state.questionCreate.answersList.skip + state.questionCreate.answersList.take;
            }
        },
        createAnswersListLoadPreviousPage: (state) => {
            if (state.questionCreate.answersList.skip > 0) {
                state.questionCreate.answersList.skip = Math.max(0, state.questionCreate.answersList.skip - state.questionCreate.answersList.take);
            }
        },
        // Conditions
        changeQueryConditions: (state, action: PayloadAction<string>) => {
            state.conditionQuestions.query = action.payload;
        },
        loadNextPageConditions: (state) => {
            if (state.conditionQuestions.data.length > 0) {
                state.conditionQuestions.skip = state.conditionQuestions.skip + state.conditionQuestions.take;
            }
        },
        loadPreviousPageConditions: (state) => {
            if (state.conditionQuestions.skip > 0) {
                state.conditionQuestions.skip = Math.max(0, state.conditionQuestions.skip - state.conditionQuestions.take);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            // List
            .addCase(getQuestionsAsync.pending, (state) => {
                state.questionsList.status = StatusEnum.Loading;
            })
            .addCase(getQuestionsAsync.fulfilled, (state, action) => {
                state.questionsList.status = StatusEnum.Idle;
                state.questionsList.data = action.payload;
            })
            .addCase(getQuestionsAsync.rejected, (state) => {
                state.questionsList.status = StatusEnum.Failed;
            })

             //Get
             .addCase(getQuestionAsync.pending, (state) => {
                state.questionEdit.status = StatusEnum.Loading;
            })
            .addCase(getQuestionAsync.fulfilled, (state, action) => {
                state.questionEdit.status = StatusEnum.Idle;
                state.questionEdit.data = action.payload;
                state.mode = ModeEnum.Edit;
            })
            .addCase(getQuestionAsync.rejected, (state) => {
                state.questionEdit.status = StatusEnum.Failed;
            })

            // Create
            .addCase(createQuestionAsync.pending, (state) => {
                state.questionCreate.status = StatusEnum.Loading;
            })
            .addCase(createQuestionAsync.fulfilled, (state, action) => {
                state.questionCreate.status = StatusEnum.Idle;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Question`;
                state.toast.body = `Created with title "${action.payload.titleEn}"`;
            })
            .addCase(createQuestionAsync.rejected, (state) => {
                state.questionCreate.status = StatusEnum.Failed;
            })
            .addCase(getCreateAnswersAsync.pending, (state) => {
                state.questionCreate.answersList.status = StatusEnum.Loading;
            })
            .addCase(getCreateAnswersAsync.fulfilled, (state, action) => {
                state.questionCreate.answersList.status = StatusEnum.Idle;
                state.questionCreate.answersList.data = action.payload;
            })
            .addCase(getCreateAnswersAsync.rejected, (state) => {
                state.questionCreate.answersList.status = StatusEnum.Failed;
            })
            // Delete
            .addCase(deleteQuestionAsync.pending, (state) => {
                state.questionEdit.status = StatusEnum.Loading;
                state.questionEdit.operation = OperationEnum.Deleting;
            })
            .addCase(deleteQuestionAsync.fulfilled, (state, action) => {
                state.questionEdit.status = StatusEnum.Idle;
                state.questionEdit.operation = OperationEnum.None;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Question`;
                state.toast.body = `Deleted with id "${action.payload}"`;
            })
            .addCase(deleteQuestionAsync.rejected, (state) => {
                state.questionEdit.status = StatusEnum.Failed;
                state.questionEdit.operation = OperationEnum.None;
            })
            // Edit
            .addCase(editQuestionAsync.pending, (state) => {
                state.questionEdit.status = StatusEnum.Loading;
                state.questionEdit.operation = OperationEnum.Saving;
            })
            .addCase(editQuestionAsync.fulfilled, (state, action) => {
                state.questionEdit.status = StatusEnum.Idle;
                state.questionEdit.operation = OperationEnum.None;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Question`;
                state.toast.body = `Edited with title "${action.payload.titleEn}"`;
            })
            .addCase(editQuestionAsync.rejected, (state) => {
                state.questionEdit.status = StatusEnum.Failed;
                state.questionEdit.operation = OperationEnum.None;
            })
            .addCase(getEditAnswersAsync.pending, (state) => {
                state.questionEdit.answersList.status = StatusEnum.Loading;
            })
            .addCase(getEditAnswersAsync.fulfilled, (state, action) => {
                state.questionEdit.answersList.status = StatusEnum.Idle;
                state.questionEdit.answersList.data = action.payload;
            })
            .addCase(getEditAnswersAsync.rejected, (state) => {
                state.questionEdit.answersList.status = StatusEnum.Failed;
            })
            // Conditions
            .addCase(getConditionQuestionsAsync.pending, (state) => {
                state.conditionQuestions.status = StatusEnum.Loading;
            })
            .addCase(getConditionQuestionsAsync.fulfilled, (state, action) => {
                state.conditionQuestions.status = StatusEnum.Idle;
                state.conditionQuestions.data = action.payload;
            })
            .addCase(getConditionQuestionsAsync.rejected, (state) => {
                state.conditionQuestions.status = StatusEnum.Failed;
            });
    },
});

export const {
    changeMode, changeShowToast, loadQuestionEdit, changeQuery, loadNextPage, loadPreviousPage,
    editAnswersListChangeQuery, editAnswersListLoadNextPage, editAnswersListLoadPreviousPage,
    createAnswersListChangeQuery, createAnswersListLoadNextPage, createAnswersListLoadPreviousPage,
    changeQueryConditions, loadNextPageConditions, loadPreviousPageConditions,
} = questionSlice.actions;

export const selectQuestionMode = (state: RootState) => state.question.mode;
export const selectQuestionToastShow = (state: RootState) => state.question.toast.show;
export const selectQuestionToastHeader = (state: RootState) => state.question.toast.header;
export const selectQuestionToastBody = (state: RootState) => state.question.toast.body;

export const selectQuestionsList = (state: RootState) => state.question.questionsList.data;
export const selectQuestionsListQuery = (state: RootState) => state.question.questionsList.query;
export const selectQuestionsListStatus = (state: RootState) => state.question.questionsList.status;
export const selectQuestionsListSkip = (state: RootState) => state.question.questionsList.skip;
export const selectQuestionsListTake = (state: RootState) => state.question.questionsList.take;

export const selectQuestionEdit = (state: RootState) => state.question.questionEdit.data;
export const selectQuestionEditStatus = (state: RootState) => state.question.questionEdit.status;
export const selectQuestionEditOperation = (state: RootState) => state.question.questionEdit.operation;
export const selectQuestionEditAnswersListData = (state: RootState) => state.question.questionEdit.answersList.data;
export const selectQuestionEditAnswersListStatus = (state: RootState) => state.question.questionEdit.answersList.status;
export const selectQuestionEditAnswersListQuery = (state: RootState) => state.question.questionEdit.answersList.query;
export const selectQuestionEditAnswersListSkip = (state: RootState) => state.question.questionEdit.answersList.skip;
export const selectQuestionEditAnswersListTake = (state: RootState) => state.question.questionEdit.answersList.take;

export const selectQuestionCreateStatus = (state: RootState) => state.question.questionCreate.status;
export const selectQuestionCreateAnswersListData = (state: RootState) => state.question.questionCreate.answersList.data;
export const selectQuestionCreateAnswersListStatus = (state: RootState) => state.question.questionCreate.answersList.status;
export const selectQuestionCreateAnswersListQuery = (state: RootState) => state.question.questionCreate.answersList.query;
export const selectQuestionCreateAnswersListSkip = (state: RootState) => state.question.questionCreate.answersList.skip;
export const selectQuestionCreateAnswersListTake = (state: RootState) => state.question.questionCreate.answersList.take;

export const selectConditionQuestionsList = (state: RootState) => state.question.conditionQuestions.data;
export const selectConditionQuestionsListQuery = (state: RootState) => state.question.conditionQuestions.query;
export const selectConditionQuestionsListStatus = (state: RootState) => state.question.conditionQuestions.status;
export const selectConditionQuestionsListSkip = (state: RootState) => state.question.conditionQuestions.skip;
export const selectConditionQuestionsListTake = (state: RootState) => state.question.conditionQuestions.take;

export default questionSlice.reducer;