import { FC } from 'react';
import { Col, ListGroup } from 'react-bootstrap';
import { QuestionTypeEnum, StatusEnum } from '../../../shared/models/Enums';
import { LiveAnswer } from '../models/LiveSurvey';
import { SurveyLiveAnswersAnswerTitle } from './SurveyLiveAnswersAnswerTitle';

type SurveyLiveAnswersQuestionTextProps = {
    status: StatusEnum,
    questionType: QuestionTypeEnum,
    questionAnswers: LiveAnswer[],
    questionAnswersSelected: string[],
    onSelect: Function,
};

export const SurveyLiveAnswersQuestionText: FC<SurveyLiveAnswersQuestionTextProps> = ({ 
    status, questionType, questionAnswers, questionAnswersSelected, onSelect,
 }) =>
    <>
        {
            status === StatusEnum.Idle &&
            questionType === QuestionTypeEnum.Text &&
            questionAnswers.map(a =>
                <Col md={6} key={a.id} className={'mt-3'}>
                    <ListGroup.Item action onClick={() => onSelect(a)}>
                        <i className={`bi ${questionAnswersSelected && questionAnswersSelected.includes(a.id) ? 'bi-check-square' : 'bi-square'}`}></i>
                        <SurveyLiveAnswersAnswerTitle questionAnswersSelected={questionAnswersSelected} a={a} />
                    </ListGroup.Item>
                </Col>
            )
        }
    </>