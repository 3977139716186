import { FC } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { QuestionTypeEnum } from "../../../shared/models/Enums";
import { ResourcesService } from "../../../shared/services/ResourcesService";

type FormGroupSelectQuestionTypeProps = {
    controlId: string,
    disabled: boolean,
    setValue: Function,
    defaultValue?: string | number | readonly string[] | undefined,
};

export const FormGroupSelectQuestionType: FC<FormGroupSelectQuestionTypeProps> = ({ controlId, disabled, setValue, defaultValue }) =>
    <Form.Group as={Row} className="mb-3" controlId={controlId}>
        <Form.Label column sm="2">{ResourcesService.translate('Type')}</Form.Label>
        <Col sm="10">
            <Form.Select disabled={disabled} defaultValue={defaultValue} onChange={e => setValue(e.currentTarget.value)}>
                <option value={QuestionTypeEnum.Radio}>radio</option>
                <option value={QuestionTypeEnum.Textarea}>textarea</option>
                <option value={QuestionTypeEnum.Checkbox}>checkbox</option>
                <option value={QuestionTypeEnum.Rating}>rating</option>
                <option value={QuestionTypeEnum.Ranking}>ranking</option>
            </Form.Select>
        </Col>
    </Form.Group>