import { FC, useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Col, Dropdown, Form, Row } from 'react-bootstrap';

import { CancelButton } from '../../../shared/components/CancelButton/CancelButton';
import { DeleteButton } from '../../../shared/components/DeleteButton/DeleteButton';
import { GenericLayout } from '../../../shared/components/GenericLayout/GenericLayout';
import { GenericList } from '../../../shared/components/GenericList/GenericList';
import { SaveButton } from '../../../shared/components/SaveButton/SaveButton';
import { VerticallyCenteredModal } from '../../../shared/components/VerticallyCenteredModal/VerticallyCenteredModal';
import { ModeEnum, OperationEnum, StatusEnum } from '../../../shared/models/Enums';
import { HelperService } from '../../../shared/services/HelperService';
import { QuestionData } from '../../../shared/models/Entities';
import { FormGroupId } from '../../../shared/components/FormGroupId/FormGroupId';
import { FormGroupTextarea } from '../../../shared/components/FormGroupTextarea/FormGroupTextarea';
import { FormGroupNumber } from '../../../shared/components/FormGroupNumber/FormGroupNumber';
import { FormWithHandle } from '../../../shared/components/FormWithHandle/FormWithHandle';
import { ResourcesService } from '../../../shared/services/ResourcesService';
import { EditSurveyData } from '../../survey/models/EditSurveyData';
import { useNavigate } from 'react-router-dom';
import { CompanySectorData } from '../models/CompanySectorData';
import { CompanySectorsType } from '../CompanySectorsType/CompanySectorsType';
import { selectUserLanguage } from '../../login/loginSlice';
import { useAppSelector } from '../../../app/hooks';
import { CompanyActivities } from '../CompanyActivities/CompanyActivities';


type CompanyLayoutProps = {
    companyId?: string,
    companyTitleBG: string,
    companyTitleEN: string,
    descriptionBg?: string,
    descriptionEn?: string,
    activities?: string[],
    companyEmployeeCount: number,
    companySectorId: number,
    companySurveys: EditSurveyData[],
    companyStatus: StatusEnum,
    companyOperation: OperationEnum,
    companyLogo: string,

    dispatch: Function,
    changeMode: Function,
    saveCompany: Function,
    deleteCompany: Function,

    surveysListData: EditSurveyData[],
    surveysListStatus: StatusEnum,
    surveysListQuery: string,
    surveysListSkip: number,
    surveysListTake: number,
    surveysSetQuery: Function,
    surveysOnNext: Function,
    surveysOnPrevious: Function,
    surveysListLoadData: Function,

    sectors: CompanySectorData[],
};

export const CompanyLayout: FC<CompanyLayoutProps> = ({
    companyId,
    companyTitleBG,
    companyTitleEN,
    descriptionBg,
    descriptionEn,
    activities,
    companyEmployeeCount,
    companySectorId,
    companySurveys,
    companyStatus,
    companyOperation,
    companyLogo,

    dispatch,
    changeMode,
    saveCompany,
    deleteCompany,

    surveysListData,
    surveysListStatus,
    surveysListQuery,
    surveysListSkip,
    surveysListTake,
    surveysSetQuery,
    surveysOnNext,
    surveysOnPrevious,
    surveysListLoadData,

    sectors,

}) => {
    const [modalShow, setModalShow] = useState(false);
    const [titleBg, setTitleBG] = useState(companyTitleBG);
    const [titleEn, setTitleEN] = useState(companyTitleEN);
    const [descriptionValueBg, setDescriptionValueBg] = useState(descriptionBg || '');
    const [descriptionValueEn, setDescriptionValueEn] = useState(descriptionEn || '');
    const [activitiesValue, setActivitiesValue] = useState<string[]>(activities || []);
    const [employeeCount, setEmployeeCount] = useState(companyEmployeeCount);
    const [sectorId, setSectorId] = useState(companySectorId);
    const [surveys, setSurveys] = useState(companySurveys);
    const [logo, setLogo] = useState(companyLogo || "");
    const [surveysModalShow, setSurveysModalShow] = useState(false);

    useEffect(() => {
        if (surveysModalShow) {
            dispatch(surveysListLoadData({
                skip: surveysListSkip,
                take: surveysListTake,
                query: surveysListQuery,
                excludeIds: surveys.map(a => a.id)
            }));
        }
    }, [surveysModalShow, dispatch, surveysListLoadData, surveysListSkip, surveysListTake, surveysListQuery, surveys]);

    const disabled = companyStatus !== StatusEnum.Idle;

    const onSave = () => {
        if (companyId) {
            dispatch(saveCompany({
                id: companyId,
                titleBg,
                titleEn,
                descriptionBg: descriptionValueBg,
                descriptionEn: descriptionValueEn,
                activities: activitiesValue,
                employeeCount,
                sectorId,
                surveys: surveys,
                logo,
            }));

            return;
        }

        dispatch(saveCompany({
            titleBg,
            titleEn,
            descriptionBg: descriptionValueBg,
            descriptionEn: descriptionValueEn,
            activities: activitiesValue,
            employeeCount,
            sectorId,
            surveys: surveys,
            logo,
        }));
    };

    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const addSurvey = (survey: EditSurveyData) => HelperService.add(survey, surveys, setSurveys);
    const removeSurvey = (survey: EditSurveyData) => HelperService.remove(survey, surveys, setSurveys);

    const lang = useAppSelector(selectUserLanguage);
    const tableHeaders = ['#', ResourcesService.translate('Title', lang)];

    const navigate = useNavigate();
    const routeChange = (path: string) => navigate(path);

    const handleDisplayFileDetails = () => {
        if (inputRef.current?.files) {

            const file = inputRef.current?.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (event) {
                const base64String = event.target?.result;
                setLogo(base64String as string);
            };

            reader.onerror = function (event) {
                console.error('File could not be read! Code ' + event?.target?.error?.code);
            };
        }
    };

    return (
        <GenericLayout
            lang={lang}
            onBack={() => dispatch(changeMode(ModeEnum.List))}
            status={companyStatus}
            h2={`${companyId ? ResourcesService.translate('Edit', lang) : ResourcesService.translate('Create', lang)} ${ResourcesService.translate('Company', lang)}`}
            buttons={[
                <DeleteButton lang={lang} status={companyStatus} operation={companyOperation} onDelete={() => setModalShow(true)} />,
                <SaveButton lang={lang} status={companyStatus} operation={companyOperation} onSave={() => onSave()} />,
                <CancelButton lang={lang} status={companyStatus} onCancel={() => dispatch(changeMode(ModeEnum.List))} />,
            ]}
        >
            <FormWithHandle onSubmit={onSave}>
                <>
                    {
                        companyId &&
                        <FormGroupId lang={lang} controlId='companyId' defaultValue={companyId} />
                    }
                </>
                <FormGroupTextarea label={ResourcesService.translate('Title', lang)} controlId='companyTitle' valueBG={titleBg} setValueBG={setTitleBG} valueEN={titleEn} setValueEN={setTitleEN} disabled={disabled} />
                <FormGroupNumber label={ResourcesService.translate('Employee Count', lang)} controlId='employeeCount' value={employeeCount} setValue={setEmployeeCount} disabled={disabled} />
                <FormGroupTextarea
                    label={ResourcesService.translate('Description', lang)}
                    controlId='companyDescription'
                    valueBG={descriptionValueBg}
                    setValueBG={setDescriptionValueBg}
                    valueEN={descriptionValueEn}
                    setValueEN={setDescriptionValueEn}
                    disabled={disabled}
                />

                <Form.Group as={Row} className="mb-3" controlId={`logo`} key={`logo`}>
                    <Form.Label column sm="2">{ResourcesService.translate('Logo', lang)}</Form.Label>
                    <input id="fileInput" ref={inputRef} accept="image/png, image/jpeg" onChange={handleDisplayFileDetails} hidden type="file" />
                    <Col sm="6">
                        <img src={logo} alt="..." className="img-thumbnail" />
                    </Col>
                    <Col sm='4'>
                        <Button className={`float-end ms-2`} variant="outline-success" size='sm' onClick={handleUpload} disabled={disabled}>

                            <i className='bi bi-save'></i>
                            <span> {ResourcesService.translate('Upload', lang)}</span>
                        </Button>
                    </Col>
                </Form.Group>


                <CompanyActivities controlId='companyActivities' activitiesValue={activitiesValue} setActivitiesValue={setActivitiesValue} lang={lang} />
                <CompanySectorsType lang={lang} controlId='companySector' disabled={disabled} setValue={setSectorId} defaultValue={sectorId} options={sectors} />
                <>
                    {
                        surveys.map((s, i) =>
                            <Form.Group as={Row} className="mb-3" controlId={`surveyId-${s.id}`} key={`surveyId-${s.id}`}>
                                <Form.Label column sm="2">{s.periodName}</Form.Label>
                                <Col sm="6">
                                    <Form.Control disabled defaultValue={ResourcesService.translateInput(s.titleBg, s.titleEn, lang)} as={'textarea'} />
                                </Col>
                                <Col sm='4'>
                                    <DeleteButton lang={lang} status={StatusEnum.Idle} operation={OperationEnum.None} onDelete={() => removeSurvey(s)} />

                                    <Dropdown className='float-end ms-2'>
                                        <Dropdown.Toggle id="dropdown-button-example1" variant="outline-secondary" size='sm'>
                                            <i className='bi bi-three-dots-vertical'></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => routeChange(`${companyId}/surveys/${s.id}`)}>
                                                <i className='bi bi-easel'></i> {ResourcesService.translate('Preview', lang)}
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => { navigator.clipboard.writeText(`${document.URL}/${companyId}/surveys/${s.id}`) }}>
                                                <i className="bi bi-clipboard"></i> {ResourcesService.translate('Copy URL', lang)}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Form.Group>
                        )
                    }
                </>
            </FormWithHandle>
            <Row>
                <Col>
                    <Button variant="outline-secondary" size='sm' disabled={disabled} onClick={() => setSurveysModalShow(true)}>
                        <i className='bi bi-plus-lg'></i> {ResourcesService.translate('Add survey', lang)}
                    </Button>
                </Col>
            </Row>
            <>
                {
                    companyId &&
                    <VerticallyCenteredModal
                        id='company-delete-confirmation-dialog'
                        lang={lang}
                        title={ResourcesService.translate('Delete Company', lang)}
                        show={modalShow}
                        onCancel={() => setModalShow(false)}
                        onOk={() => dispatch(deleteCompany(companyId))}
                    >
                        <p>{ResourcesService.translate('Are you sure you want to delete the item?', lang)}</p>
                    </VerticallyCenteredModal>
                }
            </>
            <VerticallyCenteredModal
                id='company-edit-add-survey-dialog'
                lang={lang}
                title={ResourcesService.translate('Select surveys', lang)}
                show={surveysModalShow}
                onCancel={() => setSurveysModalShow(false)}
                onOk={() => setSurveysModalShow(false)}
            >
                <GenericList
                    lang={lang}
                    headContent={
                        <tr>
                            {
                                tableHeaders.map(h => <th key={h}>{h}</th>)
                            }
                        </tr>
                    }
                    bodyContent={
                        surveysListData.map((d, i) =>
                            <tr key={i} onClick={() => addSurvey(d)}>
                                <td>{d.id}</td>
                                <td>{ResourcesService.translateInput(d.titleBg, d.titleEn, lang)}</td>
                            </tr>
                        )
                    }
                    listStatus={surveysListStatus}
                    numberOfRows={5}
                    numberOfColumns={tableHeaders.length}
                    searchValue={surveysListQuery}
                    onSearch={(value: string) => dispatch(surveysSetQuery(value))}
                    onNext={() => dispatch(surveysOnNext())}
                    onPrevious={() => dispatch(surveysOnPrevious())}
                />
            </VerticallyCenteredModal>
        </GenericLayout>
    );
}