import http from "../../shared/services/HttpCommon";
import { AnswerData } from "../../shared/models/Entities";
import { Filter } from "../../shared/models/Filter";
import { PagedList } from "../../shared/models/PagedList";
import { AuthHeaderService } from "../../shared/services/AuthHeaderService";

export async function getAnswers(token: string, filter: Filter): Promise<PagedList<AnswerData>> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const excludeIdQuery = filter.excludeIds && filter.excludeIds.length ? `&excludeIds=${filter.excludeIds.join('&excludeIds=')}` : '';
    const response = await http.get<AnswerData[]>(`/answers?query=${filter.query}&skip=${filter.skip}&take=${filter.take}${excludeIdQuery}`, headersConfig);
    return {
        data: response.data
    };
}

export async function createAnswer(token: string, titleBg: string, titleEn: string): Promise<{ data: AnswerData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const result = await http.post<AnswerData>(`/answers`, { title: titleBg, titleEn: titleEn }, headersConfig);
    return {
        data: result.data
    };
}

export async function editAnswer(token: string, id: string, titleBg: string, titleEn: string): Promise<{ data: AnswerData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    await http.put<string>(`/answers/${id}`, { title: titleBg, titleEn: titleEn }, headersConfig);
    return {
        data: {
            id,
            titleBg,
            titleEn,
        }
    };
}

export async function deleteAnswer(token: string, id: string): Promise<{ data: string }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    await http.delete<string>(`/answers/${id}`, headersConfig);
    return {
        data: id
    };
}
