import { FC } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { AnswerData } from '../../../shared/models/Entities';
import { ResourcesService } from '../../../shared/services/ResourcesService';
import { selectUserLanguage } from '../../login/loginSlice';

type SurveyLiveAnswersAnswerTitleProps = {
    a: AnswerData,
    questionAnswersSelected: string[],
};

export const SurveyLiveAnswersAnswerTitle: FC<SurveyLiveAnswersAnswerTitleProps> = ({ a, questionAnswersSelected, }) => {
    const lang = useAppSelector(selectUserLanguage);

    return <>
        {
            questionAnswersSelected && questionAnswersSelected.includes(a.id) &&
            <b> {ResourcesService.translateInput(a.titleBg, a.titleEn, lang)}</b>
        }
        {
            !(questionAnswersSelected && questionAnswersSelected.includes(a.id)) &&
            <span> {ResourcesService.translateInput(a.titleBg, a.titleEn, lang)}</span>
        }
    </>;
}