import { FC } from "react";
import { Button } from "react-bootstrap";
import { StatusEnum } from "../../models/Enums";
import { ResourcesService } from "../../services/ResourcesService";

type BackButtonProps = {
    status: StatusEnum,
    onBack: Function,
    lang: 'BG' | 'EN'
};

export const BackButton: FC<BackButtonProps> = ({ status, onBack, lang }) =>
    <Button variant="outline-primary" size='sm' onClick={() => onBack()} disabled={status === StatusEnum.Loading}>
        <i className='bi bi-arrow-left'></i> <span className="d-none d-md-inline-block">{ResourcesService.translate('Back', lang)}</span>
    </Button>