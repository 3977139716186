import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import counterReducer from '../features/counter/counterSlice';
import loginReducer from '../features/login/loginSlice';
import answerReducer from '../features/answer/answerSlice';
import questionReducer from '../features/question/questionSlice';
import sectionReducer from '../features/section/sectionSlice';
import surveyReducer from '../features/survey/surveySlice';
import companyReducer from '../features/company/companySlice';
import recommendationReducer from '../features/recommendation/recommendationSlice';
import reportReducer from '../features/report/reportSlice';
import { Log } from '../shared/services/log';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    login: loginReducer,
    answer: answerReducer,
    question: questionReducer,
    section: sectionReducer,
    recommendation : recommendationReducer,
    reportSlice : reportReducer,
    survey: surveyReducer,
    company: companyReducer,
    report : reportReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

store.subscribe(() => {
  Log.info("Store", store.getState());
});