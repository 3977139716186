import { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { ModeTransition } from '../../shared/components/ModeTransition/ModeTransition';
import { NotificationToast } from '../../shared/components/NotificationToast/NotificationToast';
import { ModeEnum, OperationEnum } from '../../shared/models/Enums';
import { selectLoginRole } from '../login/loginSlice';
import { SurveyLayout } from './SurveyLayout/SurveyLayout';
import {
    changeMode,
    changeQuery,
    changeShowToast,
    createSectionsListChangeQuery,
    createSectionsListLoadNextPage,
    createSectionsListLoadPreviousPage,
    createSurveyAsync,
    deleteSurveyAsync,
    editSectionsListChangeQuery,
    editSectionsListLoadNextPage,
    editSectionsListLoadPreviousPage,
    editSurveyAsync,
    getCreateSectionsAsync,
    getSectionsAsync,
    getSurveysAsync,
    loadNextPage,
    loadPreviousPage,
    loadSurveyEdit,
    selectSurveyCreateSectionsListData,
    selectSurveyCreateSectionsListQuery,
    selectSurveyCreateSectionsListSkip,
    selectSurveyCreateSectionsListStatus,
    selectSurveyCreateSectionsListTake,
    selectSurveyCreateStatus,
    selectSurveyEdit,
    selectSurveyEditOperation,
    selectSurveyEditSectionsListData,
    selectSurveyEditSectionsListQuery,
    selectSurveyEditSectionsListSkip,
    selectSurveyEditSectionsListStatus,
    selectSurveyEditSectionsListTake,
    selectSurveyEditStatus,
    selectSurveyMode, selectSurveysList, selectSurveysListQuery, selectSurveysListSkip,
    selectSurveysListStatus, selectSurveysListTake, selectSurveyToastBody, selectSurveyToastHeader, selectSurveyToastShow
} from './surveySlice';
import { SurveysList } from './SurveysList/SurveysList';
import { getCompanySectorsAsync, selectCompanySectors } from '../company/companySlice';

export const Survey: FC = () => {
    const role = useAppSelector(selectLoginRole);

    const dispatch = useAppDispatch();

    const mode = useAppSelector(selectSurveyMode);
    const toastShow = useAppSelector(selectSurveyToastShow);
    const toastHeader = useAppSelector(selectSurveyToastHeader);
    const toastBody = useAppSelector(selectSurveyToastBody);

    const listStatus = useAppSelector(selectSurveysListStatus);
    const listSkip = useAppSelector(selectSurveysListSkip);
    const listTake = useAppSelector(selectSurveysListTake);
    const listValue = useAppSelector(selectSurveysList);
    const listQuery = useAppSelector(selectSurveysListQuery);

    const editValue = useAppSelector(selectSurveyEdit);
    const editStatus = useAppSelector(selectSurveyEditStatus);
    const editOperation = useAppSelector(selectSurveyEditOperation);
    const editSectionsListData = useAppSelector(selectSurveyEditSectionsListData);
    const editSectionsListStatus = useAppSelector(selectSurveyEditSectionsListStatus);
    const editSectionsListQuery = useAppSelector(selectSurveyEditSectionsListQuery);
    const editSectionsListSkip = useAppSelector(selectSurveyEditSectionsListSkip);
    const editSectionsListTake = useAppSelector(selectSurveyEditSectionsListTake);

    const createStatus = useAppSelector(selectSurveyCreateStatus);
    const createSectionsListData = useAppSelector(selectSurveyCreateSectionsListData);
    const createSectionsListStatus = useAppSelector(selectSurveyCreateSectionsListStatus);
    const createSectionsListQuery = useAppSelector(selectSurveyCreateSectionsListQuery);
    const createSectionsListSkip = useAppSelector(selectSurveyCreateSectionsListSkip);
    const createSectionsListTake = useAppSelector(selectSurveyCreateSectionsListTake);

    const sectors = useAppSelector(selectCompanySectors);

    useEffect(() => {
        if (mode === ModeEnum.List) {
            dispatch(getSurveysAsync({
                skip: listSkip,
                take: listTake,
                query: listQuery
            }));
        }
    }, [mode, dispatch, listSkip, listTake, listQuery]);

    useEffect(() => {
        if (!sectors || !sectors.length) {
            dispatch(getCompanySectorsAsync());
        }
    }, [dispatch, sectors]);

    return (
        (!role || role !== 'admin')
            ? <div></div>
            : <Row>
                <Col md={{ span: 12, offset: 0 }}>
                    <ModeTransition
                        cssClass='mt-4 p-5 rounded shadow bg-white'
                        mode={mode}
                        list={<SurveysList
                            surveys={listValue}
                            surveyStatus={listStatus}
                            dispatch={dispatch}
                            loadSurveyEdit={loadSurveyEdit}
                            query={listQuery}
                            setQuery={changeQuery}
                            onNext={loadNextPage}
                            onPrevious={loadPreviousPage}
                        />}
                        edit={<SurveyLayout
                            surveyId={editValue.id}
                            surveyTitleBG={editValue.titleBg}
                            surveyTitleEN={editValue.titleEn}
                            surveyIsLive={editValue.isLive}
                            surveySections={editValue.sections}
                            surveyEmployeeCount={editValue.employeeCount}
                            surveyDivisionName={editValue.divisionName}
                            surveyPeriodName={editValue.periodName}

                            surveySectorName={editValue.sectorName}
                            surveyStatus={editStatus}
                            surveyOperation={editOperation}

                            dispatch={dispatch}
                            changeMode={changeMode}
                            deleteSurvey={deleteSurveyAsync}
                            saveSurvey={editSurveyAsync}

                            sectionsListData={editSectionsListData}
                            sectionsListStatus={editSectionsListStatus}
                            sectionsListQuery={editSectionsListQuery}
                            sectionsListSkip={editSectionsListSkip}
                            sectionsListTake={editSectionsListTake}
                            sectionsSetQuery={editSectionsListChangeQuery}
                            sectionsOnNext={editSectionsListLoadNextPage}
                            sectionsOnPrevious={editSectionsListLoadPreviousPage}
                            sectionsListLoadData={getSectionsAsync}

                            sectors={sectors}
                        />}
                        create={<SurveyLayout
                            surveyTitleBG={''}
                            surveyTitleEN={''}
                            surveySections={[]}
                            surveyIsLive={false}
                            surveyEmployeeCount={0}
                            surveyDivisionName={''}
                            surveyPeriodName={new Date().getFullYear().toString()}

                            surveySectorName={''}
                            surveyStatus={createStatus}
                            surveyOperation={OperationEnum.Saving}

                            dispatch={dispatch}
                            changeMode={changeMode}
                            deleteSurvey={deleteSurveyAsync}
                            saveSurvey={createSurveyAsync}

                            sectionsListData={createSectionsListData}
                            sectionsListStatus={createSectionsListStatus}
                            sectionsListQuery={createSectionsListQuery}
                            sectionsListSkip={createSectionsListSkip}
                            sectionsListTake={createSectionsListTake}
                            sectionsSetQuery={createSectionsListChangeQuery}
                            sectionsOnNext={createSectionsListLoadNextPage}
                            sectionsOnPrevious={createSectionsListLoadPreviousPage}
                            sectionsListLoadData={getCreateSectionsAsync}

                            sectors={sectors}
                        />}
                    />
                </Col>
                <NotificationToast
                    show={toastShow}
                    setShow={changeShowToast}
                    dispatch={dispatch}
                    header={toastHeader}
                    body={toastBody}
                />
            </Row >
    );
}