import { FC } from 'react';
import { Col, ListGroup } from 'react-bootstrap';
import { QuestionTypeEnum, StatusEnum } from '../../../shared/models/Enums';
import { LiveAnswer } from '../models/LiveSurvey';
import { SurveyLiveAnswersAnswerTitle } from './SurveyLiveAnswersAnswerTitle';

type SurveyLiveAnswersQuestionRatingProps = {
    status: StatusEnum,
    questionType: QuestionTypeEnum,
    questionAnswers: LiveAnswer[],
    questionAnswersSelected: string[],
    onSelect: Function,
};

export const SurveyLiveAnswersQuestionRating: FC<SurveyLiveAnswersQuestionRatingProps> = ({ 
    status, questionType, questionAnswers, questionAnswersSelected, onSelect,
 }) =>
    <>
        {
            status === StatusEnum.Idle &&
            questionType === QuestionTypeEnum.Rating &&
            questionAnswers.map(a =>
                <div key={a.id} className={'bw-ratingOption'}>
                    <ListGroup.Item variant={`${questionAnswersSelected && questionAnswersSelected.includes(a.id) ? 'success' : ''}`} action onClick={() => onSelect(a)}>
                        <SurveyLiveAnswersAnswerTitle questionAnswersSelected={questionAnswersSelected} a={a} />
                    </ListGroup.Item>
                </div>
            )
        }
    </>