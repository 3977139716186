import { FC } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { TextareaFormControl } from "../TextareaFormControl/TextareaFormControl";

type FormGroupTextareaProps = {
    controlId: string,
    valueBG: string,
    setValueBG: Function,
    valueEN: string,
    setValueEN: Function,
    disabled: boolean,
    label: string,
    isWithoutTranslation?: boolean,
};

export const FormGroupTextarea: FC<FormGroupTextareaProps> = ({
    controlId, valueBG, setValueBG, valueEN, setValueEN, disabled, label, isWithoutTranslation
}) =>
    <Form.Group as={Row} className="mb-3" controlId={controlId}>
        <Form.Label column sm="2">{label}</Form.Label>
        {
            isWithoutTranslation &&
            <Col sm="10">
                <TextareaFormControl value={valueBG} setValue={setValueBG} disabled={disabled} />
            </Col>
        }

        {
            !isWithoutTranslation &&
            <Col sm="5">
                <span>EN</span>
                <TextareaFormControl value={valueEN} setValue={setValueEN} disabled={disabled} />
            </Col>
        }
        {
            !isWithoutTranslation &&
            <Col sm="5">
                <span>БГ</span>
                <TextareaFormControl value={valueBG} setValue={setValueBG} disabled={disabled} />
            </Col>
        }
    </Form.Group>