import { FC } from "react";
import { Alert, Col, Row } from "react-bootstrap";

import { StatusEnum } from "../../models/Enums";
import { ResourcesService } from "../../services/ResourcesService";
import { BackButton } from "../BackButton/BackButton";

type GenericLayoutProps = {
    onBack: Function,
    status: StatusEnum,
    h2: string,
    children: JSX.Element | JSX.Element[],
    buttons: JSX.Element[],
    lang: 'BG' | 'EN'
};

export const GenericLayout: FC<GenericLayoutProps> = ({ onBack, status, h2, children, buttons, lang }) =>
    <Row>
        <Col>
            <Row>
                <Col md='4'>
                    <BackButton onBack={() => onBack()} status={status} lang={lang} />
                </Col>
                <Col><h2>{h2}</h2></Col>
            </Row>
            <Row>
                <Col>
                    {
                        status === StatusEnum.Failed &&
                        <Alert variant='warning'>
                            <i className="bi bi-exclamation-triangle"></i> {ResourcesService.translate('The operation failed. Please try again later.', lang)}
                        </Alert>
                    }
                    {children}
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        buttons.map((b, i) => <span key={i}>{b}</span>)
                    }
                </Col>
            </Row>
        </Col>
    </Row>