import { FC, useState } from 'react';

import {
    Button, FormControl, InputGroup,
    Row, Col, Table, ButtonGroup
} from "react-bootstrap";
import { useDebouncedCallback } from 'use-debounce';
import './GenericList.css';

import { TableContentSkeleton } from "../TableContentSkeleton/TableContentSkeleton";
import { StatusEnum } from "../../models/Enums";
import { ResourcesService } from '../../services/ResourcesService';

type GenericListProps = {
    lang: "BG" | "EN",
    h2?: string,
    headContent: JSX.Element,
    bodyContent: JSX.Element[],
    listStatus: StatusEnum,
    numberOfRows: number,
    numberOfColumns: number,
    onCreateClick?: Function,
    searchValue: string,
    onSearch: Function,
    onNext: Function,
    onPrevious: Function,
};

export const GenericList: FC<GenericListProps> = ({
    lang, h2, headContent, bodyContent,
    listStatus, numberOfRows, numberOfColumns, onCreateClick,
    searchValue, onSearch, onNext, onPrevious
}) => {
    const [value, setValue] = useState(searchValue);
    // Debounce callback
    const debounced = useDebouncedCallback(
        // function
        (value) => {
            setValue(value);
            onSearch(value);
        },
        // delay in ms
        500
    );

    return (
        <>
            {
                h2 && <Row>
                    <Col md={{ span: 4, offset: 4 }}>
                        <h2>{h2}</h2>
                    </Col>
                </Row>
            }

            <InputGroup className="mb-3">
                <FormControl
                    defaultValue={value}
                    placeholder={`${ResourcesService.translate('Search in all', lang)} ${h2 ? h2.toLowerCase() : ''}`}
                    onChange={(e) => debounced(e.target.value)}
                />
            </InputGroup>
            <Table hover responsive borderless>
                <thead>
                    {headContent}
                </thead>
                {listStatus === StatusEnum.Loading
                    && <TableContentSkeleton numberOfColumns={numberOfColumns} numberOfRows={numberOfRows} />
                }
                {listStatus === StatusEnum.Idle
                    && <tbody className='generic-list-body'>
                        {bodyContent}
                    </tbody>
                }
                {listStatus === StatusEnum.Failed
                    && <tbody className='generic-list-body'>
                        <p>{ResourcesService.translate('Something went wrong.', lang)}</p>
                    </tbody>
                }
            </Table>
            <Row>
                <Col>
                    <ButtonGroup size='sm'>
                        <Button variant="outline-secondary" onClick={() => onPrevious()} >
                            <i className='bi bi-chevron-left'></i> {ResourcesService.translate('Previous', lang)}
                        </Button>
                        <Button variant="outline-secondary" onClick={() => onNext()} >
                            <i className='bi bi-chevron-right'></i> {ResourcesService.translate('Next', lang)}
                        </Button>
                    </ButtonGroup>
                    {
                        onCreateClick
                        && <div className='float-end'>
                            <Button variant="outline-success" size='sm' onClick={() => onCreateClick()}>
                                <i className='bi bi-plus-lg'></i> {ResourcesService.translate('Create', lang)}
                            </Button>
                        </div>
                    }
                </Col>
            </Row>
        </>
    );
}