import { FC, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';

import { CancelButton } from '../../../shared/components/CancelButton/CancelButton';
import { DeleteButton } from '../../../shared/components/DeleteButton/DeleteButton';
import { FormGroupId } from '../../../shared/components/FormGroupId/FormGroupId';
import { FormGroupTextarea } from '../../../shared/components/FormGroupTextarea/FormGroupTextarea';
import { FormWithHandle } from '../../../shared/components/FormWithHandle/FormWithHandle';
import { GenericLayout } from '../../../shared/components/GenericLayout/GenericLayout';
import { SaveButton } from '../../../shared/components/SaveButton/SaveButton';
import { VerticallyCenteredModal } from '../../../shared/components/VerticallyCenteredModal/VerticallyCenteredModal';
import { ModeEnum, OperationEnum, StatusEnum } from '../../../shared/models/Enums';
import { ResourcesService } from '../../../shared/services/ResourcesService';
import { selectUserLanguage } from '../../login/loginSlice';

type AnswerLayoutProps = {
    answerId?: string,
    answerTitleBG: string,
    answerTitleEN: string,
    answerStatus: StatusEnum,
    dispatch: Function,
    saveAnswer: Function,
    changeMode: Function,
    operation: OperationEnum,
    deleteAnswer?: Function,
};

export const AnswerLayout: FC<AnswerLayoutProps> = ({
    answerTitleBG, answerTitleEN, answerStatus, answerId, dispatch, saveAnswer, changeMode, operation, deleteAnswer
}) => {
    const [modalShow, setModalShow] = useState(false);
    const [titleBg, setTitleBG] = useState(answerTitleBG);
    const [titleEn, setTitleEN] = useState(answerTitleEN);
    const disabled = answerStatus !== StatusEnum.Idle;
    const lang = useAppSelector(selectUserLanguage);

    const onSave = () => {
        if (answerId) {
            dispatch(saveAnswer({ id: answerId, titleBg, titleEn }));
            return;
        }

        dispatch(saveAnswer({ titleBg, titleEn }));
    };

    const buttons = [];
    if (answerId) {
        buttons.push(<DeleteButton lang={lang} status={answerStatus} operation={operation} onDelete={() => setModalShow(true)} />);
    }
    buttons.push(<SaveButton lang={lang} status={answerStatus} operation={operation} onSave={onSave} />);
    buttons.push(<CancelButton lang={lang} status={answerStatus} onCancel={() => dispatch(changeMode(ModeEnum.List))} />);

    return (
        <GenericLayout
            lang={lang}
            onBack={() => dispatch(changeMode(ModeEnum.List))}
            status={answerStatus}
            h2={`${answerId ? ResourcesService.translate('Edit', lang) : ResourcesService.translate('Create', lang)}  ${ResourcesService.translate('Answer', lang)}`}
            buttons={buttons}
        >
            <FormWithHandle onSubmit={onSave}>
                <>
                    {
                        answerId &&
                        <FormGroupId lang={lang} controlId='answerId' defaultValue={answerId} />
                    }
                </>
                <FormGroupTextarea
                    label={ResourcesService.translate('Title', lang)}
                    controlId='answerTitle'
                    valueBG={titleBg}
                    setValueBG={setTitleBG}
                    valueEN={titleEn}
                    setValueEN={setTitleEN}
                    disabled={disabled}
                />
            </FormWithHandle>
            <>
                {
                    answerId && deleteAnswer &&
                    <VerticallyCenteredModal
                        id='answer-delete-confirmation-dialog'
                        lang={lang}
                        title={ResourcesService.translate('Delete Answer', lang)}
                        show={modalShow}
                        onCancel={() => setModalShow(false)}
                        onOk={() => dispatch(deleteAnswer(answerId))}
                    >
                        <p>{ResourcesService.translate('Are you sure you want to delete the item?', lang)}</p>
                    </VerticallyCenteredModal>
                }
            </>

        </GenericLayout>
    );
}