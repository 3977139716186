import { FC, useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, ListGroup, Row } from 'react-bootstrap';
import { useAppSelector } from '../../../app/hooks';
import { CancelButton } from '../../../shared/components/CancelButton/CancelButton';
import { DeleteButton } from '../../../shared/components/DeleteButton/DeleteButton';
import { FormGroupCheckboxSwitch } from '../../../shared/components/FormGroupCheckboxSwitch/FormGroupCheckboxSwitch';
import { FormGroupId } from '../../../shared/components/FormGroupId/FormGroupId';
import { FormGroupTextarea } from '../../../shared/components/FormGroupTextarea/FormGroupTextarea';
import { FormWithHandle } from '../../../shared/components/FormWithHandle/FormWithHandle';
import { GenericLayout } from '../../../shared/components/GenericLayout/GenericLayout';
import { GenericList } from '../../../shared/components/GenericList/GenericList';
import { SaveButton } from '../../../shared/components/SaveButton/SaveButton';
import { VerticallyCenteredModal } from '../../../shared/components/VerticallyCenteredModal/VerticallyCenteredModal';
import { AnswerData, QuestionAnswerData } from '../../../shared/models/Entities';
import { ModeEnum, OperationEnum, QuestionTypeEnum, StatusEnum } from '../../../shared/models/Enums';
import { HelperService } from '../../../shared/services/HelperService';
import { ResourcesService } from '../../../shared/services/ResourcesService';
import { selectUserLanguage } from '../../login/loginSlice';
import { FormGroupSelectQuestionType } from '../FormGroupSelectQuestionType/FormGroupSelectQuestionType';
import { CreateQuestionConditionData } from '../models/CreateQuestionConditionData';
import { EditQuestionConditionData } from '../models/EditQuestionConditionData';
import { EditQuestionData } from '../models/EditQuestionData';
import { QuestionCondition } from '../QuestionCondition/QuestionCondition';
import { FormGroupText } from '../../../shared/components/FormGroupTextarea/FormGroupText';

type QuestionLayoutProps = {
    questionId?: string,
    questionTitleBG: string,
    questionTitleEN: string,
    questionSubtitleBg: string,
    questionSubtitleEn: string,
    questionHasRevertedAnswers : boolean,
    questionType: QuestionTypeEnum,
    questionAnswers: QuestionAnswerData[],
    questionConditions: EditQuestionConditionData[],
    questionOperation: OperationEnum,
    questionCode: string,
    questionUniqueCode: string,
    questionLimit : number,

    status: StatusEnum,
    dispatch: Function,
    changeMode: Function,
    saveQuestion: Function,
    deleteQuestion?: Function,

    answersListLoadData: Function,
    answersListQuery: string,
    answersListSkip: number,
    answersListTake: number,
    answersListData: AnswerData[],
    answersListStatus: StatusEnum,
    answersSetQuery: Function,
    answersOnNext: Function,
    answersOnPrevious: Function,

    conditionsQuery: string,
    conditionsListSkip: number,
    conditionsListTake: number,
    conditionsQuestions: EditQuestionData[],
    conditionsQuestionStatus: StatusEnum,
    conditionsLoadQuestionEdit: Function,
    conditionsSetQuery: Function,
    conditionsOnNext: Function,
    conditionsOnPrevious: Function,
};

export const QuestionLayout: FC<QuestionLayoutProps> = ({
    questionId,
    questionTitleBG,
    questionTitleEN,
    questionSubtitleBg,
    questionSubtitleEn,
    questionHasRevertedAnswers,
    questionType,
    questionAnswers,
    questionOperation,
    questionConditions,
    questionCode,
    questionUniqueCode,
    questionLimit,

    status,
    dispatch,
    changeMode,
    saveQuestion,
    deleteQuestion,

    answersListLoadData,
    answersListQuery,
    answersListSkip,
    answersListTake,
    answersListData,
    answersListStatus,
    answersSetQuery,
    answersOnNext,
    answersOnPrevious,

    conditionsQuery,
    conditionsListSkip,
    conditionsListTake,
    conditionsQuestions,
    conditionsQuestionStatus,
    conditionsLoadQuestionEdit,
    conditionsSetQuery,
    conditionsOnNext,
    conditionsOnPrevious,
}) => {
    const [modalShow, setModalShow] = useState(false);
    const [answersModalShow, setAnswersModalShow] = useState(false);
    const [conditionsModalShow, setConditionsModalShow] = useState(false);

    const [titleBg, setTitleBG] = useState(questionTitleBG);
    const [titleEn, setTitleEN] = useState(questionTitleEN);
    const [subtitleBg, setSubtitleBg] = useState(questionSubtitleBg);
    const [subtitleEn, setSubtitleEn] = useState(questionSubtitleEn);
    const [hasRevertedAnswers, setHasRevertedAnswers] = useState(questionHasRevertedAnswers);
    const [type, setType] = useState(questionType);
    const [answers, setAnswers] = useState(questionAnswers);
    const [code, setCode] = useState(questionCode);
    const [uniqueCode, setUniqueCode] = useState(questionUniqueCode);
    const [limit, setLimit] = useState(questionLimit);
    const [conditions, setConditions] = useState<CreateQuestionConditionData[]>(questionConditions);
    const [conditionQuestionSelected, setConditionQuestionSelected] = useState<EditQuestionData | undefined>();
    const [conditionAnswerSelected, setConditionAnswerSelected] = useState<QuestionAnswerData | undefined>();

    const lang = useAppSelector(selectUserLanguage);

    useEffect(() => {
        if (answersModalShow) {
            dispatch(answersListLoadData({
                skip: answersListSkip,
                take: answersListTake,
                query: answersListQuery,
                excludeIds: answers.map(a => a.id)
            }));
        }
    }, [answersModalShow, dispatch, answersListLoadData, answersListQuery, answers, answersListSkip, answersListTake]);

    useEffect(() => {
        if (conditionsModalShow) {
            dispatch(conditionsLoadQuestionEdit({
                skip: conditionsListSkip,
                take: conditionsListTake,
                query: conditionsQuery,
                excludeIds: answers.map(a => a.id)
            }));
        }
    }, [answers, conditionsListSkip, conditionsListTake, conditionsLoadQuestionEdit, conditionsModalShow, conditionsQuery, dispatch]);

    const disabled = status !== StatusEnum.Idle;

    const onSave = () => {
        if (questionId) {
            dispatch(saveQuestion({
                id: questionId,
                title: titleBg,
                titleEn,
                subtitle: subtitleBg,
                subtitleEn,
                hasRevertedAnswers,
                type,
                code,
                uniqueCode,
                limit,
                answers: type === QuestionTypeEnum.Textarea ? [] : answers,
                questionConditionList: conditions,
            }));

            return;
        }

        dispatch(saveQuestion({
            title: titleBg,
            titleEn,
            subtitle: subtitleBg,
            subtitleEn,
            hasRevertedAnswers,
            type,
            code,
            uniqueCode,
            limit,
            answers: type === QuestionTypeEnum.Textarea ? [] : answers,
            questionConditionList: conditions,
        }));
    };

    const onAnswerExclude = (answer: QuestionAnswerData) => {
        const index = answers.findIndex(a => a.id === answer.id);
        if (index >= 0) {
            const newArray = answers.map(a => {
                if (a.id === answer.id) {
                    return { ...a, isExclude: !a.isExclude };
                }

                return a;
            });

            setAnswers([...newArray]);
        }
    };

    const onConditionDelete = (conditionToDelete: CreateQuestionConditionData) => {
        const index = conditions.findIndex(c => c.title === conditionToDelete.title);
        if (index >= 0) {
            const newArray = conditions.filter(c => c.title !== conditionToDelete.title);

            setConditions([...newArray]);
        }
    };

    const onConditionSave = () => {
        if (conditionQuestionSelected && conditionAnswerSelected) {
            const newCondition: CreateQuestionConditionData = {
                title: `${ResourcesService.translate('Hide when', lang)}...${conditionQuestionSelected.titleEn.substring(0, 30)}...${conditionAnswerSelected.titleEn.substring(0, 30)}`,
                // targetQuestionId: questionId,
                sourceQuestionId: conditionQuestionSelected.id,
                answerId: conditionAnswerSelected.id,
                operation: 'hide',
            };

            setConditions([...conditions, newCondition]);
        }

        setAnswersModalShow(false);
    };

    const onSelectType = (value: string) => {
        setType(HelperService.numberToQuestionTypeEnum(Number.parseInt(value)))
    };

    const addAnswer = (answer: AnswerData) => HelperService.add(answer, answers, setAnswers);
    const removeAnswer = (answer: AnswerData) => HelperService.remove(answer, answers, setAnswers);

    const tableHeaders = ['#', ResourcesService.translate('Title', lang)];
    const buttons = [];
    if (questionId) {
        buttons.push(<DeleteButton lang={lang} status={status} operation={questionOperation} onDelete={() => setModalShow(true)} />);
    }
    buttons.push(<SaveButton lang={lang} status={status} operation={questionOperation} onSave={() => onSave()} />);
    buttons.push(<CancelButton lang={lang} status={status} onCancel={() => dispatch(changeMode(ModeEnum.List))} />);

    return (
        <GenericLayout
            lang={lang}
            onBack={() => dispatch(changeMode(ModeEnum.List))}
            status={status}
            h2={`${questionId ? ResourcesService.translate('Edit', lang) : ResourcesService.translate('Create', lang)} ${ResourcesService.translate('Question', lang)}`}
            buttons={buttons}
        >
            <FormWithHandle onSubmit={onSave}>
                <>
                    {
                        questionId &&
                        <FormGroupId lang={lang} controlId='questionId' defaultValue={questionId} />
                    }
                </>
                <FormGroupTextarea label={ResourcesService.translate('Title', lang)} controlId='questionTitle' valueBG={titleBg} setValueBG={setTitleBG} valueEN={titleEn} setValueEN={setTitleEN} disabled={disabled} />
                <FormGroupTextarea label={ResourcesService.translate('Subtitle', lang)} controlId='questionSubtitle' valueBG={subtitleBg} setValueBG={setSubtitleBg} valueEN={subtitleEn} setValueEN={setSubtitleEn} disabled={disabled} />
                <FormGroupCheckboxSwitch label={ResourcesService.translate('Has Reverted Answers' , lang)} value={hasRevertedAnswers} setValue={setHasRevertedAnswers} disabled={disabled} />
                
                <FormGroupText label={ResourcesService.translate('Unique code', lang)} controlId='questionUniqueCode' value={uniqueCode} setValue={setUniqueCode} disabled={disabled} />
                <FormGroupText label={ResourcesService.translate('Code', lang)} controlId='questionCode' value={code} setValue={setCode} disabled={disabled} />
                
                <FormGroupSelectQuestionType controlId='questionType' setValue={onSelectType} defaultValue={type} disabled={disabled} />

                <>
                    {
                        [QuestionTypeEnum.Checkbox, QuestionTypeEnum.Textarea].includes(type) &&
                <FormGroupText label={ResourcesService.translate('Limit', lang)} controlId='questionLimit' value={limit} setValue={setLimit} disabled={disabled} />
            }
            </>


                <>
                    {
                        answers.map((a, i) =>
                            <Form.Group as={Row} className="mb-3" controlId={`answerId-${a.id}`} key={`answerId-${a.id}`}>
                                <Form.Label column sm="2">A{i + 1}</Form.Label>
                                <Col sm="6">
                                    <Form.Control disabled value={ResourcesService.translateInput(a.titleBg, a.titleEn, lang)} />
                                </Col>
                                <Col sm="4">
                                    <DeleteButton lang={lang} status={StatusEnum.Idle} operation={OperationEnum.None} onDelete={() => removeAnswer(a)} />
                                    <Dropdown className='float-end ms-2'>
                                        <Dropdown.Toggle id="dropdown-button-example1" variant="outline-secondary" size='sm'>
                                            <i className='bi bi-three-dots-vertical'></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item active={a.isExclude} onClick={() => onAnswerExclude(a)}>{ResourcesService.translate('Exclusive', lang)}</Dropdown.Item>
                                            <Dropdown.Item disabled>{ResourcesService.translate('Show if answered', lang)}</Dropdown.Item>
                                            <Dropdown.Item disabled>{ResourcesService.translate('Hide if answered', lang)}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Form.Group>
                        )
                    }
                </>
            </FormWithHandle>
            <Row>
                <Col>
                    <Button variant="outline-secondary" size='sm' disabled={disabled || type === QuestionTypeEnum.Textarea} onClick={() => setAnswersModalShow(true)}>
                        <i className='bi bi-plus-lg'></i> {ResourcesService.translate('Add answer', lang)}
                    </Button>
                </Col>
            </Row>
            <Row>
                <h4>{ResourcesService.translate('Conditions', lang)}</h4>
                <ListGroup>
                    {
                        conditions.map((c, z) =>
                            <ListGroup.Item key={`condition-key-id-${z}`}>
                                {c.title}
                                <DeleteButton lang={lang} status={status} operation={questionOperation} onDelete={() => onConditionDelete(c)} />
                            </ListGroup.Item>
                        )
                    }
                </ListGroup>
            </Row>
            <Row>
                <Col>
                    <Button variant="outline-secondary" size='sm' disabled={disabled || type === QuestionTypeEnum.Textarea} onClick={() => setConditionsModalShow(true)}>
                        <i className='bi bi-plus-lg'></i> {ResourcesService.translate('Add condition', lang)}
                    </Button>
                </Col>
            </Row>
            <>
                {
                    questionId && deleteQuestion &&
                    <VerticallyCenteredModal
                        id='question-delete-confirmation-dialog'
                        lang={lang}
                        title={ResourcesService.translate('Delete Question', lang)}
                        show={modalShow}
                        onCancel={() => setModalShow(false)}
                        onOk={() => dispatch(deleteQuestion(questionId))}
                    >
                        <p>{ResourcesService.translate('Are you sure you want to delete the item?', lang)}</p>
                    </VerticallyCenteredModal>
                }
            </>
            <VerticallyCenteredModal
                id='question-edit-add-answer-dialog'
                lang={lang}
                title={ResourcesService.translate('Select answers', lang)}
                show={answersModalShow}
                onCancel={() => setAnswersModalShow(false)}
                onOk={() => setAnswersModalShow(false)}
            >
                <GenericList
                    lang={lang}
                    headContent={
                        <tr>
                            {
                                tableHeaders.map(h => <th key={h}>{h}</th>)
                            }
                        </tr>
                    }
                    bodyContent={
                        answersListData.map((d, i) =>
                            <tr key={i} onClick={() => addAnswer(d)}>
                                <td>{d.id}</td>
                                <td>{ResourcesService.translateInput(d.titleBg, d.titleEn, lang)}</td>
                            </tr>
                        )
                    }
                    listStatus={answersListStatus}
                    numberOfRows={5}
                    numberOfColumns={tableHeaders.length}
                    searchValue={answersListQuery}
                    onSearch={(value: string) => dispatch(answersSetQuery(value))}
                    onNext={() => dispatch(answersOnNext())}
                    onPrevious={() => dispatch(answersOnPrevious())}
                />
            </VerticallyCenteredModal>
            <VerticallyCenteredModal
                id='question-edit-add-condition-dialog'
                lang={lang}
                title={ResourcesService.translate('Add condition', lang)}
                show={conditionsModalShow}
                onCancel={() => setConditionsModalShow(false)}
                onOk={() => onConditionSave()}
                size='xl'
            >
                <Row>
                    <Col md='1'>
                        <p>{ResourcesService.translate('Action', lang)}</p>
                        <Form.Check
                            type="radio"
                            id="condition-to-hide"
                            name="condition-to-hide"
                            label={ResourcesService.translate('Hide when', lang)}
                            defaultChecked
                        />
                        <Form.Check
                            type="radio"
                            id="condition-to-hide"
                            name="condition-to-hide"
                            label={ResourcesService.translate('Show when', lang)}
                            disabled
                        />
                    </Col>
                    <Col>
                        <p>{ResourcesService.translate('Questions', lang)}</p>
                        <GenericList
                            lang={lang}
                            headContent={
                                <tr>
                                    {
                                        [ResourcesService.translate('Title', lang)].map(h => <th key={h}>{h}</th>)
                                    }
                                </tr>
                            }
                            bodyContent={
                                conditionsQuestions.map((q, i) =>
                                    <tr key={i} onClick={() => setConditionQuestionSelected(q)}>
                                        <td>
                                            {
                                                !(conditionQuestionSelected && conditionQuestionSelected.id === q.id) &&
                                                <span>{ResourcesService.translateInput(q.titleBg, q.titleEn, lang)}</span>
                                            }
                                            {
                                                conditionQuestionSelected && conditionQuestionSelected.id === q.id &&
                                                <b>{ResourcesService.translateInput(q.titleBg, q.titleEn, lang)}</b>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                            listStatus={conditionsQuestionStatus}
                            numberOfRows={5}
                            numberOfColumns={tableHeaders.length}
                            searchValue={conditionsQuery}
                            onSearch={(value: string) => dispatch(conditionsSetQuery(value))}
                            onNext={() => dispatch(conditionsOnNext())}
                            onPrevious={() => dispatch(conditionsOnPrevious())}
                        />
                    </Col>
                    <Col>
                        <p>{ResourcesService.translate('Answers', lang)}</p>
                        <ListGroup>
                            {
                                conditionQuestionSelected &&
                                conditionQuestionSelected.answers.map(a =>
                                    <ListGroup.Item
                                        action
                                        active={conditionAnswerSelected && conditionAnswerSelected.id === a.id}
                                        key={`${a.id}`}
                                        onClick={() => setConditionAnswerSelected(a)}
                                    >
                                        {ResourcesService.translateInput(a.titleBg, a.titleEn, lang)}
                                    </ListGroup.Item>
                                )
                            }
                        </ListGroup>
                    </Col>
                </Row>
            </VerticallyCenteredModal>
        </GenericLayout>
    );
}