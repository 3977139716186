import { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { CancelButton } from '../../../shared/components/CancelButton/CancelButton';
import { DeleteButton } from '../../../shared/components/DeleteButton/DeleteButton';
import { GenericLayout } from '../../../shared/components/GenericLayout/GenericLayout';
import { GenericList } from '../../../shared/components/GenericList/GenericList';
import { SaveButton } from '../../../shared/components/SaveButton/SaveButton';
import { VerticallyCenteredModal } from '../../../shared/components/VerticallyCenteredModal/VerticallyCenteredModal';
import { ModeEnum, OperationEnum, StatusEnum } from '../../../shared/models/Enums';
import { HelperService } from '../../../shared/services/HelperService';
import { QuestionData } from '../../../shared/models/Entities';
import { FormGroupId } from '../../../shared/components/FormGroupId/FormGroupId';
import { FormGroupTextarea } from '../../../shared/components/FormGroupTextarea/FormGroupTextarea';
import { FormWithHandle } from '../../../shared/components/FormWithHandle/FormWithHandle';
import { ResourcesService } from '../../../shared/services/ResourcesService';
import { useAppSelector } from '../../../app/hooks';
import { selectUserLanguage } from '../../login/loginSlice';
import { FormGroupCheckboxSwitch } from '../../../shared/components/FormGroupCheckboxSwitch/FormGroupCheckboxSwitch';


type SectionLayoutProps = {
    sectionId?: string,
    sectionTitleBG: string,
    sectionTitleEN: string,
    sectionHasAdditionalQuestions : boolean,
    sectionHasAdditionalQuestionsExternal : boolean,
    sectionQuestions: QuestionData[],
    sectionStatus: StatusEnum,
    sectionOperation: OperationEnum,

    dispatch: Function,
    changeMode: Function,
    saveSection: Function,
    deleteSection: Function,

    questionsListData: QuestionData[],
    questionsListStatus: StatusEnum,
    questionsListQuery: string,
    questionsListSkip: number,
    questionsListTake: number,
    questionsSetQuery: Function,
    questionsOnNext: Function,
    questionsOnPrevious: Function,
    questionsListLoadData: Function,
};

export const SectionLayout: FC<SectionLayoutProps> = ({
    sectionId,
    sectionTitleBG,
    sectionTitleEN,
    sectionHasAdditionalQuestions,
    sectionHasAdditionalQuestionsExternal,
    sectionQuestions,
    sectionStatus,
    sectionOperation,

    dispatch,
    changeMode,
    saveSection,
    deleteSection,

    questionsListData,
    questionsListStatus,
    questionsListQuery,
    questionsListSkip,
    questionsListTake,
    questionsSetQuery,
    questionsOnNext,
    questionsOnPrevious,
    questionsListLoadData,
}) => {
    const [modalShow, setModalShow] = useState(false);
    const [titleBg, setTitleBG] = useState(sectionTitleBG);
    const [titleEn, setTitleEN] = useState(sectionTitleEN);
    const [hasAdditionalQuestions, setHasAdditionalQuestions] = useState(sectionHasAdditionalQuestions);
    const [hasAdditionalQuestionsExternal, setHasAdditionalQuestionsExternal] = useState(sectionHasAdditionalQuestionsExternal);
    const [questions, setQuestions] = useState(sectionQuestions);
    const [questionsModalShow, setQuestionsModalShow] = useState(false);

    useEffect(() => {
        if (questionsModalShow) {
            dispatch(questionsListLoadData({
                skip: questionsListSkip,
                take: questionsListTake,
                query: questionsListQuery,
                excludeIds: questions.map(a => a.id)
            }));
        }
    }, [questionsModalShow, dispatch, questionsListLoadData, questionsListSkip, questionsListTake, questionsListQuery, questions]);

    const disabled = sectionStatus !== StatusEnum.Idle;

    const onSave = () => {
        if (sectionId) {
            dispatch(saveSection({
                id: sectionId,
                title: titleBg,
                titleEn,
                hasAdditionalQuestions,
                hasAdditionalQuestionsExternal,
                questions,
            }));

            return;
        }

        dispatch(saveSection({
            title: titleBg,
            titleEn,
            hasAdditionalQuestions,
            hasAdditionalQuestionsExternal,
            questions,
        }));
    };

    const addQuestion = (question: QuestionData) => HelperService.add(question, questions, setQuestions);
    const removeQuestion = (question: QuestionData) => HelperService.remove(question, questions, setQuestions);

    const lang = useAppSelector(selectUserLanguage);
    const tableHeaders = ['#', ResourcesService.translate('Title', lang)];

    return (
        <GenericLayout
            lang={lang}
            onBack={() => dispatch(changeMode(ModeEnum.List))}
            status={sectionStatus}
            h2={`${sectionId ? ResourcesService.translate('Edit', lang) : ResourcesService.translate('Create', lang)} ${ResourcesService.translate('Section', lang)}`}
            buttons={[
                <DeleteButton lang={lang} status={sectionStatus} operation={sectionOperation} onDelete={() => setModalShow(true)} />,
                <SaveButton lang={lang} status={sectionStatus} operation={sectionOperation} onSave={() => onSave()} />,
                <CancelButton lang={lang} status={sectionStatus} onCancel={() => dispatch(changeMode(ModeEnum.List))} />,
            ]}
        >
            <FormWithHandle onSubmit={onSave}>
                <>
                    {
                        sectionId &&
                        <FormGroupId lang={lang} controlId='sectionId' defaultValue={sectionId} />
                    }
                </>
                <FormGroupTextarea label={ResourcesService.translate('Title', lang)} controlId='sectionTitle' valueBG={titleBg} setValueBG={setTitleBG} valueEN={titleEn} setValueEN={setTitleEN} disabled={disabled} />
                <FormGroupCheckboxSwitch label={ResourcesService.translate('Additional Questions' , lang)} value={hasAdditionalQuestions} setValue={setHasAdditionalQuestions} disabled={disabled} />
                <FormGroupCheckboxSwitch label={ResourcesService.translate('Additional Questions External' , lang)} value={hasAdditionalQuestionsExternal} setValue={setHasAdditionalQuestionsExternal} disabled={disabled} />
                
                <>
                    {
                        questions.map((q, i) =>
                            <Form.Group as={Row} className="mb-3" controlId={`questionId-${q.id}`} key={`questionId-${q.id}`}>
                                <Form.Label column sm="2">Q{i + 1}</Form.Label>
                                <Col sm="6">
                                    <Form.Control disabled value={ResourcesService.translateInput(q.titleBg, q.titleEn, lang)} as={'textarea'} />
                                </Col>
                                <Col sm="4">
                                    <DeleteButton lang={lang} status={StatusEnum.Idle} operation={OperationEnum.None} onDelete={() => removeQuestion(q)} />
                                </Col>
                            </Form.Group>
                        )
                    }
                </>
            </FormWithHandle>
            <Row>
                <Col>
                    <Button variant="outline-secondary" size='sm' disabled={disabled} onClick={() => setQuestionsModalShow(true)}>
                        <i className='bi bi-plus-lg'></i> {ResourcesService.translate('Add question', lang)} 
                    </Button>
                </Col>
            </Row>
            <>
                {
                    sectionId &&
                    <VerticallyCenteredModal
                        id='section-delete-confirmation-dialog'
                        lang={lang}
                        title={ResourcesService.translate('Delete Section', lang)}
                        show={modalShow}
                        onCancel={() => setModalShow(false)}
                        onOk={() => dispatch(deleteSection(sectionId))}
                    >
                        <p>{ResourcesService.translate('Are you sure you want to delete the item?', lang)}</p>
                    </VerticallyCenteredModal>
                }
            </>
            <VerticallyCenteredModal
                id='section-edit-add-question-dialog'
                lang={lang}
                title={ResourcesService.translate('Select questions', lang)}
                show={questionsModalShow}
                onCancel={() => setQuestionsModalShow(false)}
                onOk={() => setQuestionsModalShow(false)}
            >
                <GenericList
                    lang={lang}
                    headContent={
                        <tr>
                            {
                                tableHeaders.map(h => <th key={h}>{h}</th>)
                            }
                        </tr>
                    }
                    bodyContent={
                        questionsListData.map((d, i) =>
                            <tr key={i} onClick={() => addQuestion(d)}>
                                <td>{d.id}</td>
                                <td>{ResourcesService.translateInput(d.titleBg, d.titleEn, lang)}</td>
                            </tr>
                        )
                    }
                    listStatus={questionsListStatus}
                    numberOfRows={5}
                    numberOfColumns={tableHeaders.length}
                    searchValue={questionsListQuery}
                    onSearch={(value: string) => dispatch(questionsSetQuery(value))}
                    onNext={() => dispatch(questionsOnNext())}
                    onPrevious={() => dispatch(questionsOnPrevious())}
                />
            </VerticallyCenteredModal>
        </GenericLayout>
    );
}