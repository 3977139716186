import { FC } from 'react';

import { Button, Col, Row } from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ResourcesService } from '../../shared/services/ResourcesService';
import { changeLanguage, selectUserLanguage } from './loginSlice';

export const WelcomeScreen: FC<{ onNext: Function }> = ({ onNext }) => {
    const dispatch = useAppDispatch();
    const lang: 'BG' | 'EN' = useAppSelector(selectUserLanguage);
    const rawHTML =
        `
  
  <div class="survey-header">
    <div class="survey-header-content-wrapp">
      <div class="body-text-dark">${ResourcesService.translate('What is', lang)}</div>
      <h1 class="title-big-dark clean">BeyondWork</h1>
    </div>
    <div class="scroll-down-wrapp-dark">
      <div style="width:50px" class="scroll-down-dash"></div>
      <div class="scroll-down-text">scroll</div>
    </div>
  </div>
  <div data-w-id="4c37bf5f-b7c2-d86f-b331-3da819c6cf12" class="survey-section">
    <div class="survey-section-content">
      <div class="content-wrapp-3">
        <div class="title-mid-dark clean">${ResourcesService.translate('BeyondWork investigates the level of satisfaction among employees in your company through a survey created by leading scientists in the field of organizational and social psychology.', lang)}</div>
        <div class="body-text-dark">${ResourcesService.translate('You can find more information at beyondwork.bg', lang)}</div>
      </div>
      <div class="content-wrapp-4">
        <div class="title-mid-dark clean">${ResourcesService.translate('How we guarantee your anonymity', lang)}</div>
        <div class="body-text-dark columns">${ResourcesService.translate('Employees provide feedback on the BeyondWork platform, which your company does not have access to. The survey does not collect personal data about the participants and there are no questions in it that can suggest the type of activity that the employees perform in the company. To eliminate the possibility of abuse, the system requires the employee\'s phone number to prove that he is a real and unique survey participant. No one on the BeyondWork team has access to the phone number, as it is verified by an independent third-party software and converted to an alphanumeric code before it reaches the BeyondWork platform. There is no way back and this phone number can be traced or recovered. Watch the video to see for yourself:', lang)}</div>
      </div>
      <div style="padding-top:56.17021276595745%" class="w-embed-youtubevideo survey-main-video"><iframe src="https://www.youtube.com/embed/SVqhUEP5P_Y" frameborder="0" style="position:absolute;left:0;top:0;width:100%;height:100%;pointer-events:auto" allow="autoplay; encrypted-media" allowfullscreen="" title="BeyondWork Explainers"></iframe></div>
    </div>
    <div class="survey-cercle-1"></div>
    <div class="survey-cercle-2"></div>
  </div>
    `
    return (
        <>
            <div className="nav-survey">
                <div className="nav-logo-wrapp">
                    <span className="nav-logo w-inline-block"></span>
                    <img src="images/BeyondWork-Logotype---Dark-Gray---21-10.svg" alt="Logo" width="121" className="nav-logo" />
                    {/* <div className="logo-dash"></div>
                    <img src="images/ment---in-partnership-with-forbes-10-10.svg" width="Auto" height="30" alt="" /> */}
                </div>
                <div className="language-switch">
                    <span className="bg-selector w-inline-block">
                        <div onClick={() => dispatch(changeLanguage("BG"))}>БГ</div>
                    </span>
                    <div className="language-switch-dash"></div>
                    <span className="en-selector w-inline-block">
                        <div onClick={() => dispatch(changeLanguage("EN"))}>EN</div>
                    </span>
                </div>
            </div>
            {<div dangerouslySetInnerHTML={{ __html: rawHTML }} />}
            <Row className='bw-row bw-row3'>
                <Col md={{ span: 5, offset: 1 }} className='mt-5 mb-5 p-5' >
                </Col>
                <Col md={{ span: 5 }} className='mt-5 mb-5 p-5' >
                    <div className='bw-textM'>{ResourcesService.translate('Every employee is important.', lang)}</div>
                    <div className='bw-textL'>{ResourcesService.translate('The honesty and accuracy of your answers can improve the working environment for everyone in the company.', lang)}</div>
                    <div className='bw-textM'>{ResourcesService.translate('The questionnaire has 76 questions, all questions are obligatory and filling it in takes 10 minutes.', lang)}</div>
                    <Button variant="success" size='sm' onClick={() => onNext()}>
                        <span>{ResourcesService.translate('Start the questionnaire', lang)}</span>
                        <i className='bi bi-chevron-right'></i>
                    </Button>
                </Col>
            </Row>
        </>
    );
}
