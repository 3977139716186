import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { QuestionTypeEnum } from '../../../shared/models/Enums';
import { ResourcesService } from '../../../shared/services/ResourcesService';
import { selectUserLanguage } from '../../login/loginSlice';
import { LiveAnswer } from '../models/LiveSurvey';
import { SurveyLiveLayout } from '../SurveyLiveLayout/SurveyLiveLayout';
import { createLiveSurveyAnswerAsync, createUserSurveyAsync, loadLiveSurveyAsync, selectSurveyLive, selectSurveyLiveCurrentQuestion, /*selectSurveyLiveHasStarted,*/ selectSurveyLiveIsCompleted, selectSurveyLiveStatus, updateUserSurveyAsync } from '../surveySlice';

import './SurveyLive.css';

type SurveyLiveProps = {
};

export const SurveyLive: FC<SurveyLiveProps> = () => {
    const { surveyId } = useParams();
    const { companyId } = useParams();
    const dispatch = useAppDispatch();
    const [alertText, setAlertText] = useState('');
    const [selectedAnswers, setSelectedAnswers] = useState<{
        answer: { id: string, isExclude?: boolean, position?: number }, questionId: string, sectionId: string, surveyId: string, companyId: string,
    }[]>([]);
    const [liveQuestionId, setLiveQuestionId] = useState('');
    const lang: 'BG' | 'EN' = useAppSelector(selectUserLanguage);
    const [inputAnswer, setInputAnswer] = useState('');

    useEffect(() => {
        if (surveyId && companyId) {
            dispatch(loadLiveSurveyAsync(surveyId));
        }
    }, [dispatch, surveyId, companyId]);

    const liveValue = useAppSelector(selectSurveyLive);
    const liveQuestion = useAppSelector(selectSurveyLiveCurrentQuestion);
    const liveStatus = useAppSelector(selectSurveyLiveStatus);
    const liveIsCompleted = useAppSelector(selectSurveyLiveIsCompleted);

    useEffect(() => {
        if (liveQuestion.id !== liveQuestionId) {
            setSelectedAnswers([]);
            setLiveQuestionId(liveQuestion.id);
        }
    }, [liveQuestion, liveQuestionId]);

    useEffect(() => {
        if (surveyId && companyId && liveIsCompleted) {
            dispatch(updateUserSurveyAsync({ companyId, surveyId }));
        }
    }, [dispatch, surveyId, companyId, liveIsCompleted]);

    const now: number = Math.floor((100 * liveValue.questions.filter(q => q.isAnswered).length) / liveValue.questions.length);

    const onSelect = (liveAnswer: LiveAnswer) => {
        if (liveQuestion.type === QuestionTypeEnum.Radio
            || liveQuestion.type === QuestionTypeEnum.Rating
            || liveAnswer.isExclude
        ) {
            setSelectedAnswers([{
                answer: liveAnswer,
                questionId: liveQuestion.id,
                sectionId: liveQuestion.sectionId,
                surveyId: liveValue.id,
                companyId: companyId as string,
            }]);
        } else if (liveQuestion.type === QuestionTypeEnum.Ranking) {
            setSelectedAnswers(prevState => {
                // Undo if selected
                if (prevState.some(rv => rv.answer.id === liveAnswer.id)) {
                    return [...prevState.filter(rv => rv.answer.id !== liveAnswer.id)];
                }
                // Do nothing if full
                if (prevState.length === liveQuestion.answers.length) {
                    return prevState;
                }
                // Add if first
                if (prevState.length === 0) {
                    return [{
                        answer: { position: 0, id: liveAnswer.id },
                        questionId: liveQuestion.id,
                        sectionId: liveQuestion.sectionId,
                        surveyId: liveValue.id,
                        companyId: companyId as string,
                    }];
                }
                // Find next position in sequence
                const answeredSortedState = prevState.sort((a, b) => a.answer.position as number - (b.answer.position as number));
                for (let index = 0; index < liveQuestion.answers.length; index++) {
                    if (!answeredSortedState[index] || ((answeredSortedState[index].answer.position as number) !== index)) {
                        return [
                            {
                                answer: { position: index, id: liveAnswer.id },
                                questionId: liveQuestion.id,
                                sectionId: liveQuestion.sectionId,
                                surveyId: liveValue.id,
                                companyId: companyId as string,
                            },
                            ...prevState
                        ];
                    }
                }

                // Alerts
                if (!setSelectedAnswers || !setSelectedAnswers.length || setSelectedAnswers.length !== liveQuestion.answers.length) {
                    setAlertText(ResourcesService.translate('You need to select rank for every answer.', lang));
                    return prevState;
                } else {
                    setAlertText('');
                    return prevState;
                }
            });            
        } else {
            let newSA: { answer: { id: string, isExclude?: boolean }, questionId: string, sectionId: string, surveyId: string, companyId: string, }[] = selectedAnswers;
            if (newSA.some(sa => sa.answer.isExclude)) {
                const filtered = newSA.filter(sa => !sa.answer.isExclude);
                newSA = [
                    ...filtered,
                    {
                        answer: liveAnswer,
                        questionId: liveQuestion.id,
                        sectionId: liveQuestion.sectionId,
                        surveyId: liveValue.id,
                        companyId: companyId as string,
                    }
                ];
                setSelectedAnswers([...newSA]);
            }
            else if (newSA.some(sa => sa.answer.id === liveAnswer.id)) {
                newSA = newSA.filter(sa => sa.answer.id !== liveAnswer.id);
                setSelectedAnswers([...newSA]);
            } else if (newSA.length <= 0 || newSA.some(sa => sa.answer.id !== liveAnswer.id)) {
                newSA = [
                    ...newSA,
                    {
                        answer: liveAnswer,
                        questionId: liveQuestion.id,
                        sectionId: liveQuestion.sectionId,
                        surveyId: liveValue.id,
                        companyId: companyId as string,
                    }
                ];
                setSelectedAnswers([...newSA]);
            }

            if (!newSA || !newSA.length) { 
                    setAlertText(ResourcesService.translate('No selected response.', lang));
            } else {
                setAlertText('');
            }
        }
    };

    function format(str: string, ...args: any[]): string {
        return str.replace(/{(\d+)}/g, (match, number) => {
            return typeof args[number] !== 'undefined' ? args[number] : match;
        });
    }

    const onSave = () => {
        if (liveQuestion.type === QuestionTypeEnum.Textarea && !inputAnswer.length) {
            setAlertText(ResourcesService.translate('There is no written answer.', lang));
            return;
        }

        if (liveQuestion.type === QuestionTypeEnum.Ranking && selectedAnswers.length !== liveQuestion.answers.length) {
            setAlertText(ResourcesService.translate('Not all responses have been rated. Please select all fields in the desired order.', lang));
            return;
        }

        if(liveQuestion.type === QuestionTypeEnum.Checkbox && liveQuestion.limit && liveQuestion.limit > 0 && selectedAnswers.length != liveQuestion.limit) {
            var vText = ResourcesService.translate('Choose {0} answers.', lang);
            setAlertText(format(vText, liveQuestion.limit));
            return;
        } 

        if (liveQuestion.type !== QuestionTypeEnum.Textarea && (!selectedAnswers || !selectedAnswers.length)) {
            setAlertText(ResourcesService.translate('No selected response.', lang));
            return;
        }


        setAlertText('');

        let data;

        if (liveQuestion.type === QuestionTypeEnum.Textarea) {
            data = [{
                answerId: '',
                answerInput: inputAnswer,
                questionId: liveQuestion.id,
                sectionId: liveQuestion.sectionId,
                surveyId: liveValue.id,
                companyId: companyId as string,
            }];

            setInputAnswer('');
        } else {
            data = selectedAnswers.map(sa => {
                return {
                    answerId: sa.answer.id,
                    questionId: sa.questionId,
                    sectionId: sa.sectionId,
                    surveyId: sa.surveyId,
                    companyId: sa.companyId,
                    rank: sa.answer.position
                }
            });
        }

        dispatch(createLiveSurveyAnswerAsync(data));
    };

    const onStart = () => {
        if (surveyId && companyId) {
            dispatch(createUserSurveyAsync({ companyId, surveyId }));
        }
    };

    return (
        <SurveyLiveLayout
            isCompleted={liveIsCompleted}
            isLive={liveValue.isLive}
            status={liveStatus}
            titleBg={liveValue.titleBg}
            titleEn={liveValue.titleEn}
            now={now}
            questionTitleBG={liveQuestion.titleBg}
            questionTitleEN={liveQuestion.titleEn}
            questionSubtitleBg={liveQuestion.subtitleBg}
            questionSubtitleEn={liveQuestion.subtitleEn}
            questionType={liveQuestion.type}
            questionLimit={liveQuestion.limit}
            questionAnswers={liveQuestion.answers}
            questionAnswersSelected={selectedAnswers.map(sa => sa.answer.id)}
            questionAnswerersRanking={selectedAnswers}
            onSelect={onSelect}
            inputAnswer={inputAnswer}
            setInputAnswer={setInputAnswer}
            onSave={onSave}
            onStart={onStart}
            alertText={alertText}
        />
    );
}