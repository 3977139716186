import { FC } from "react";
import { Col, Form, Row } from "react-bootstrap";


type FormGroupTextProps = {
    controlId: string,
    value: string | number,
    setValue: Function,
    disabled: boolean,
    label: string,
};

export const FormGroupText: FC<FormGroupTextProps> = ({
    controlId, value, setValue, disabled, label,
}) =>
    <Form.Group as={Row} className="mb-3" controlId={controlId}>
        <Form.Label column sm="2">
            {label}
        </Form.Label>
        <Col sm="10">
            <Form.Control type="text" defaultValue={value} onChange={(e) => setValue(e.target.value)} disabled={disabled} />
        </Col>
    </Form.Group>