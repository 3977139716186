import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectUserLanguage, changeLanguage } from '../../login/loginSlice';

export const Language = () => {
    const lang = useAppSelector(selectUserLanguage);
    const dispatch = useAppDispatch();

    return (
        <div className="position-relative p-2 mt-1 bw-languageBar">
            <div className="position-absolute top-0 start-0 bw-imageHeader">
                <span>
                    {/* <img className="bw-headerLogo" src="https://collabo.solutions/customStyles/headerImageNew.png" /> */}
                    <img src="images/BeyondWork-Logotype---Dark-Gray---21-10.svg" alt="Logo" width="121" className="nav-logo" />
                </span>
            </div>
            <ToggleButtonGroup type="radio" name="language" defaultValue={lang} className="position-absolute top-0 end-0">
                <ToggleButton
                    value="BG"
                    size="sm"
                    className={`${lang === 'BG' ? 'languageBtn-bold': 'languageBtn-normal'}`}
                    id='lang-radio-bg'
                    onChange={() => dispatch(changeLanguage("BG"))}
                >
                    бг
                </ToggleButton>
                <ToggleButton
                    value="EN"
                    size="sm"
                    className={`${lang === 'EN' ? 'languageBtn-bold': 'languageBtn-normal'}`}
                    id='lang-radio-en'
                    onChange={() => dispatch(changeLanguage("EN"))}
                >
                    en
                </ToggleButton>
            </ToggleButtonGroup>
        </div >
    );
}