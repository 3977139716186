import { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { ModeTransition } from '../../shared/components/ModeTransition/ModeTransition';
import { NotificationToast } from '../../shared/components/NotificationToast/NotificationToast';
import { ModeEnum, OperationEnum } from '../../shared/models/Enums';
import { selectLoginRole } from '../login/loginSlice';
import { selectSectionCreateStatus } from '../section/sectionSlice';
import { CompaniesList } from './CompaniesList/CompaniesList';
import { CompanyLayout } from './CompanyLayout/CompanyLayout';
import { changeMode, changeQuery, changeShowToast, createCompanyAsync, createSurveysChangeQuery, createSurveysLoadNextPage, createSurveysLoadPreviousPage, deleteCompanyAsync, editCompanyAsync, editSurveysChangeQuery, editSurveysLoadNextPage, editSurveysLoadPreviousPage, getCompaniesAsync, getCompanySectorsAsync, getCreateSurveysAsync, getSurveysAsync, loadCompanyEdit, loadNextPage, loadPreviousPage, selectCompaniesList, selectCompaniesListQuery, selectCompaniesListSkip, selectCompaniesListStatus, selectCompaniesListTake, selectCompanyCreateSurveysListData, selectCompanyCreateSurveysListQuery, selectCompanyCreateSurveysListSkip, selectCompanyCreateSurveysListStatus, selectCompanyCreateSurveysListTake, selectCompanyEdit, selectCompanyEditOperation, selectCompanyEditStatus, selectCompanyEditSurveysListData, selectCompanyEditSurveysListQuery, selectCompanyEditSurveysListSkip, selectCompanyEditSurveysListStatus, selectCompanyEditSurveysListTake, selectCompanyMode, selectCompanySectors, selectCompanyToastBody, selectCompanyToastHeader, selectCompanyToastShow } from './companySlice';

export const Company: FC = () => {
    const role = useAppSelector(selectLoginRole);
    const dispatch = useAppDispatch();

    const mode = useAppSelector(selectCompanyMode);
    const toastShow = useAppSelector(selectCompanyToastShow);
    const toastHeader = useAppSelector(selectCompanyToastHeader);
    const toastBody = useAppSelector(selectCompanyToastBody);

    const listStatus = useAppSelector(selectCompaniesListStatus);
    const listSkip = useAppSelector(selectCompaniesListSkip);
    const listTake = useAppSelector(selectCompaniesListTake);
    const listValue = useAppSelector(selectCompaniesList);
    const listQuery = useAppSelector(selectCompaniesListQuery);

    const editValue = useAppSelector(selectCompanyEdit);
    const editStatus = useAppSelector(selectCompanyEditStatus);
    const editOperation = useAppSelector(selectCompanyEditOperation);
    const editSurveysListData = useAppSelector(selectCompanyEditSurveysListData);
    const editSurveysListStatus = useAppSelector(selectCompanyEditSurveysListStatus);
    const editSurveysListQuery = useAppSelector(selectCompanyEditSurveysListQuery);
    const editSurveysListSkip = useAppSelector(selectCompanyEditSurveysListSkip);
    const editSurveysListTake = useAppSelector(selectCompanyEditSurveysListTake);

    const createStatus = useAppSelector(selectSectionCreateStatus);
    const createSurveysListData = useAppSelector(selectCompanyCreateSurveysListData);
    const createSurveysListStatus = useAppSelector(selectCompanyCreateSurveysListStatus);
    const createSurveysListQuery = useAppSelector(selectCompanyCreateSurveysListQuery);
    const createSurveysListSkip = useAppSelector(selectCompanyCreateSurveysListSkip);
    const createSurveysListTake = useAppSelector(selectCompanyCreateSurveysListTake);

    const sectors = useAppSelector(selectCompanySectors);

    useEffect(() => {
        if (mode === ModeEnum.List) {
            dispatch(getCompaniesAsync({
                skip: listSkip,
                take: listTake,
                query: listQuery
            }));
        }
    }, [mode, dispatch, listSkip, listTake, listQuery]);

    useEffect(() => {
        if (!sectors || !sectors.length) {
            dispatch(getCompanySectorsAsync());
        }
    }, [dispatch, sectors]);

    return (
        (!role || role !== 'admin')
            ? <div></div>
            : <Row>
                <Col md={{ span: 12, offset: 0 }}>
                    <ModeTransition
                        cssClass='mt-4 p-5 rounded shadow bg-white'
                        mode={mode}
                        list={<CompaniesList
                            companies={listValue}
                            companyStatus={listStatus}
                            dispatch={dispatch}
                            loadCompanyEdit={loadCompanyEdit}
                            query={listQuery}
                            setQuery={changeQuery}
                            onNext={loadNextPage}
                            onPrevious={loadPreviousPage}
                        />}
                        edit={<CompanyLayout
                            companyId={editValue.id}
                            companyTitleBG={editValue.titleBg}
                            companyTitleEN={editValue.titleEn}
                            companyEmployeeCount={editValue.employeeCount}
                            companyLogo={editValue.logo}
                            descriptionBg={editValue.descriptionBg}
                            descriptionEn={editValue.descriptionEn}
                            activities={editValue.activities}
                            companySectorId={editValue.sectorId}
                            companySurveys={editValue.surveys}
                            companyStatus={editStatus}
                            companyOperation={editOperation}

                            sectors={sectors}
                            dispatch={dispatch}
                            changeMode={changeMode}
                            saveCompany={editCompanyAsync}
                            deleteCompany={deleteCompanyAsync}

                            surveysListData={editSurveysListData}
                            surveysListStatus={editSurveysListStatus}
                            surveysListQuery={editSurveysListQuery}
                            surveysListSkip={editSurveysListSkip}
                            surveysListTake={editSurveysListTake}
                            surveysSetQuery={editSurveysChangeQuery}
                            surveysOnNext={editSurveysLoadNextPage}
                            surveysOnPrevious={editSurveysLoadPreviousPage}
                            surveysListLoadData={getSurveysAsync}
                        />}
                        create={<CompanyLayout
                            companyTitleBG={''}
                            companyTitleEN={''}
                            companyEmployeeCount={0}
                            companySectorId={1}
                            companySurveys={[]}
                            companyLogo={""}
                            companyStatus={createStatus}
                            companyOperation={OperationEnum.Saving}

                            sectors={sectors}
                            dispatch={dispatch}
                            changeMode={changeMode}
                            saveCompany={createCompanyAsync}
                            deleteCompany={deleteCompanyAsync}

                            surveysListData={createSurveysListData}
                            surveysListStatus={createSurveysListStatus}
                            surveysListQuery={createSurveysListQuery}
                            surveysListSkip={createSurveysListSkip}
                            surveysListTake={createSurveysListTake}
                            surveysSetQuery={createSurveysChangeQuery}
                            surveysOnNext={createSurveysLoadNextPage}
                            surveysOnPrevious={createSurveysLoadPreviousPage}
                            surveysListLoadData={getCreateSurveysAsync}
                        />}
                    />
                </Col>
                <NotificationToast
                    show={toastShow}
                    setShow={changeShowToast}
                    dispatch={dispatch}
                    header={toastHeader}
                    body={toastBody}
                />
            </Row >
    );
}