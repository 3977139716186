import { FC } from "react";
import { CSSTransition } from 'react-transition-group'
import { ModeEnum } from "../../models/Enums";

import './ModeTransition.css';

type ModeTransitionProps = {
    cssClass: string,
    mode: ModeEnum;
    list: JSX.Element;
    edit: JSX.Element;
    create: JSX.Element;
};

export const ModeTransition: FC<ModeTransitionProps> = ({ cssClass, mode, list, edit, create }) =>
    <div className={`mode-item-container-wrapper ${cssClass}`}>
        <CSSTransition in={mode === ModeEnum.List} timeout={500} classNames="mode-item-container-primary" unmountOnExit>
            <div className='mode-item-container'>
                {list}
            </div>
        </CSSTransition>

        <CSSTransition in={mode === ModeEnum.Edit} timeout={500} classNames="mode-item-container-secondary" unmountOnExit>
            <div className='mode-item-container'>
                {edit}
            </div>
        </CSSTransition>

        <CSSTransition in={mode === ModeEnum.Create} timeout={500} classNames="mode-item-container-secondary" unmountOnExit>
            <div className='mode-item-container'>
                {create}
            </div>
        </CSSTransition>
    </div>