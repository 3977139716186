import http from "../../shared/services/HttpCommon";
import { AnswerData, RecommendationData } from "../../shared/models/Entities";
import { Filter } from "../../shared/models/Filter";
import { PagedList } from "../../shared/models/PagedList";
import { AuthHeaderService } from "../../shared/services/AuthHeaderService";
import { ReportTypeEnum } from "../../shared/models/Enums";

export interface ReportApiData {
  reportType : ReportTypeEnum,
  companyId : string,
  surveyId : string,
  leadershipLevelId : string | undefined,
  compareCompanies : string[]
  selectedRecomendations : string[],
  companyToCompareId : string | undefined,
  divisionToCompareId : string | undefined,
}

export async function getReport(token: string, args : ReportApiData) {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const response = await http({
        url: 'report',
        method: 'POST',
        data : args,
        headers : headersConfig.headers,
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
      });
}

export async function getLeadershipLevel(token: string, surveyId: string): Promise<PagedList<AnswerData>> {
  const headersConfig = AuthHeaderService.authHeadersConfig(token);
  const response = await http.get<AnswerData[]>(`/report/leadership-filter?surveyId=${surveyId}`, headersConfig);
  return {
      data: response.data
  };
}