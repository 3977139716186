import { FC } from 'react';
import { QuestionTypeEnum, StatusEnum } from '../../../shared/models/Enums';
import { LiveAnswer } from '../models/LiveSurvey';
import { SurveyLiveAnswersQuestionCheckbox } from './SurveyLiveAnswersQuestionCheckbox';
import { SurveyLiveAnswersQuestionRadio } from './SurveyLiveAnswersQuestionRadio';
import { SurveyLiveAnswersQuestionRanking } from './SurveyLiveAnswersQuestionRanking';
import { SurveyLiveAnswersQuestionRating } from './SurveyLiveAnswersQuestionRating';
import { SurveyLiveAnswersQuestionText } from './SurveyLiveAnswersQuestionText';
import { SurveyLiveAnswersQuestionTextarea } from './SurveyLiveAnswersQuestionTextarea';
import { SurveyLiveAnswersSkeleton } from './SurveyLiveAnswersSkeleton';

type SurveyLiveAnswersProps = {
    status: StatusEnum,
    questionType: QuestionTypeEnum,
    questionLimit : number,
    questionAnswers: LiveAnswer[],
    questionAnswersSelected: string[],
    questionAnswerersRanking: { answer: { position?: number, id: string } }[],
    onSelect: Function,
    inputAnswer: string,
    setInputAnswer: Function
};

export const SurveyLiveAnswers: FC<SurveyLiveAnswersProps> = ({
    status, questionType, questionLimit, questionAnswers, questionAnswersSelected, questionAnswerersRanking, onSelect, inputAnswer,setInputAnswer,
}) =>
    <>
        <SurveyLiveAnswersSkeleton status={status} />        
        <SurveyLiveAnswersQuestionRadio
            status={status}
            questionType={questionType}
            questionAnswers={questionAnswers}
            questionAnswersSelected={questionAnswersSelected}
            onSelect={onSelect}
        />
        <SurveyLiveAnswersQuestionText
            status={status}
            questionType={questionType}
            questionAnswers={questionAnswers}
            questionAnswersSelected={questionAnswersSelected}
            onSelect={onSelect}
        />
        <SurveyLiveAnswersQuestionTextarea
            status={status}
            questionType={questionType}
            questionLimit={questionLimit}
            questionAnswers={questionAnswers}
            questionAnswersSelected={questionAnswersSelected}
            inputAnswer={inputAnswer}
            setInputAnswer={setInputAnswer}
        />
        <SurveyLiveAnswersQuestionCheckbox
            status={status}
            questionLimit={questionLimit}
            questionType={questionType}
            questionAnswers={questionAnswers}
            questionAnswersSelected={questionAnswersSelected}
            onSelect={onSelect}
        />
        <SurveyLiveAnswersQuestionRating
            status={status}
            questionType={questionType}
            questionAnswers={questionAnswers}
            questionAnswersSelected={questionAnswersSelected}
            onSelect={onSelect}
        />
        <SurveyLiveAnswersQuestionRanking
            status={status}
            questionType={questionType}
            questionAnswers={questionAnswers}
            questionAnswerersRanking={questionAnswerersRanking}
            onSelect={onSelect}
        />
    </>