import { FC } from "react";
import { Form } from "react-bootstrap";

type TextareaFormControlProps = {
    value: string,
    setValue: Function,
    disabled: boolean,
};

export const TextareaFormControl: FC<TextareaFormControlProps> = ({ value, setValue, disabled }) =>
    <Form.Control as={'textarea'} defaultValue={value} onChange={(e) => setValue(e.target.value)} disabled={disabled} />