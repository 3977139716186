import { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    getQuestionsAsync, selectQuestionsListStatus, selectQuestionsList,
    loadQuestionEdit, selectQuestionMode, selectQuestionEdit,
    changeMode, selectQuestionsListQuery, changeQuery, selectQuestionsListSkip,
    selectQuestionsListTake, loadNextPage, loadPreviousPage, selectQuestionEditStatus,
    selectQuestionEditOperation, deleteQuestionAsync, editQuestionAsync, createQuestionAsync,
    selectQuestionCreateStatus, selectQuestionToastShow, selectQuestionToastHeader, selectQuestionToastBody,
    changeShowToast,
    selectQuestionEditAnswersListData,
    selectQuestionEditAnswersListStatus,
    selectQuestionEditAnswersListQuery,
    editAnswersListChangeQuery,
    editAnswersListLoadNextPage,
    editAnswersListLoadPreviousPage,
    getEditAnswersAsync,
    getCreateAnswersAsync,
    createAnswersListLoadPreviousPage,
    createAnswersListLoadNextPage,
    createAnswersListChangeQuery,
    selectQuestionCreateAnswersListData,
    selectQuestionCreateAnswersListStatus,
    selectQuestionCreateAnswersListQuery,
    selectQuestionEditAnswersListTake,
    selectQuestionEditAnswersListSkip,
    selectQuestionCreateAnswersListTake,
    selectQuestionCreateAnswersListSkip,
    selectConditionQuestionsListStatus,
    selectConditionQuestionsListSkip,
    selectConditionQuestionsListTake,
    selectConditionQuestionsList,
    selectConditionQuestionsListQuery,
    getConditionQuestionsAsync,
    changeQueryConditions,
    loadNextPageConditions,
    loadPreviousPageConditions,
} from './questionSlice';
import { QuestionsList } from './QuestionsList/QuestionsList';
import { ModeTransition } from '../../shared/components/ModeTransition/ModeTransition';
import { NotificationToast } from '../../shared/components/NotificationToast/NotificationToast';
import { ModeEnum, OperationEnum, QuestionTypeEnum } from '../../shared/models/Enums';
import { QuestionLayout } from './QuestionLayout/QuestionLayout';
import { selectLoginRole } from '../login/loginSlice';

export const Question: FC = () => {
    const role = useAppSelector(selectLoginRole);

    const dispatch = useAppDispatch();

    const mode = useAppSelector(selectQuestionMode);
    const toastShow = useAppSelector(selectQuestionToastShow);
    const toastHeader = useAppSelector(selectQuestionToastHeader);
    const toastBody = useAppSelector(selectQuestionToastBody);

    const listStatus = useAppSelector(selectQuestionsListStatus);
    const listSkip = useAppSelector(selectQuestionsListSkip);
    const listTake = useAppSelector(selectQuestionsListTake);
    const listValue = useAppSelector(selectQuestionsList);
    const listQuery = useAppSelector(selectQuestionsListQuery);

    const editValue = useAppSelector(selectQuestionEdit);
    const editStatus = useAppSelector(selectQuestionEditStatus);
    const editOperation = useAppSelector(selectQuestionEditOperation);
    const editAnswersListData = useAppSelector(selectQuestionEditAnswersListData);
    const editAnswersListStatus = useAppSelector(selectQuestionEditAnswersListStatus);
    const editAnswersListQuery = useAppSelector(selectQuestionEditAnswersListQuery);
    const editAnswersListSkip = useAppSelector(selectQuestionEditAnswersListSkip);
    const editAnswersListTake = useAppSelector(selectQuestionEditAnswersListTake);

    const createStatus = useAppSelector(selectQuestionCreateStatus);
    const createAnswersListData = useAppSelector(selectQuestionCreateAnswersListData);
    const createAnswersListStatus = useAppSelector(selectQuestionCreateAnswersListStatus);
    const createAnswersListQuery = useAppSelector(selectQuestionCreateAnswersListQuery);
    const createAnswersListSkip = useAppSelector(selectQuestionCreateAnswersListSkip);
    const createAnswersListTake = useAppSelector(selectQuestionCreateAnswersListTake);

    const conditionsListStatus = useAppSelector(selectConditionQuestionsListStatus);
    const conditionsListSkip = useAppSelector(selectConditionQuestionsListSkip);
    const conditionsListTake = useAppSelector(selectConditionQuestionsListTake);
    const conditionsListValue = useAppSelector(selectConditionQuestionsList);
    const conditionsListQuery = useAppSelector(selectConditionQuestionsListQuery);

    useEffect(() => {
        if (mode === ModeEnum.List) {
            dispatch(getQuestionsAsync({
                skip: listSkip,
                take: listTake,
                query: listQuery
            }));
        }
    }, [mode, dispatch, listSkip, listTake, listQuery]);

    return (
        (!role || role !== 'admin')
            ? <div></div>
            : <Row>
                <Col md={{ span: 12, offset: 0 }}>
                    <ModeTransition
                        cssClass='mt-4 p-5 rounded shadow bg-white'
                        mode={mode}
                        list={<QuestionsList
                            questions={listValue}
                            questionStatus={listStatus}
                            dispatch={dispatch}
                            loadQuestionEdit={loadQuestionEdit}
                            query={listQuery}
                            setQuery={changeQuery}
                            onNext={loadNextPage}
                            onPrevious={loadPreviousPage}
                        />}
                        edit={<QuestionLayout
                            questionId={editValue.id}
                            questionTitleBG={editValue.titleBg}
                            questionTitleEN={editValue.titleEn}
                            questionSubtitleBg={editValue.subtitleBg}
                            questionSubtitleEn={editValue.subtitleEn}
                            questionHasRevertedAnswers={editValue.hasRevertedAnswers}
                            questionType={editValue.type}
                            questionAnswers={editValue.answers}
                            questionOperation={editOperation}
                            questionConditions={editValue.questionConditionList}
                            questionCode={editValue.code}
                            questionUniqueCode={editValue.uniqueCode}
                            questionLimit={editValue.limit}

                            status={editStatus}
                            dispatch={dispatch}
                            changeMode={changeMode}
                            saveQuestion={editQuestionAsync}
                            deleteQuestion={deleteQuestionAsync}

                            answersListLoadData={getEditAnswersAsync}
                            answersListQuery={editAnswersListQuery}
                            answersListSkip={editAnswersListSkip}
                            answersListTake={editAnswersListTake}
                            answersListData={editAnswersListData}
                            answersListStatus={editAnswersListStatus}
                            answersSetQuery={editAnswersListChangeQuery}
                            answersOnNext={editAnswersListLoadNextPage}
                            answersOnPrevious={editAnswersListLoadPreviousPage}

                            conditionsQuery={conditionsListQuery}
                            conditionsListSkip={conditionsListSkip}
                            conditionsListTake={conditionsListTake}
                            conditionsQuestions={conditionsListValue}
                            conditionsQuestionStatus={conditionsListStatus}
                            conditionsLoadQuestionEdit={getConditionQuestionsAsync}
                            conditionsSetQuery={changeQueryConditions}
                            conditionsOnNext={loadNextPageConditions}
                            conditionsOnPrevious={loadPreviousPageConditions}
                        />}
                        create={<QuestionLayout
                            questionTitleBG={''}
                            questionTitleEN={''}
                            questionSubtitleBg={''}
                            questionSubtitleEn={''}
                            questionHasRevertedAnswers={false}
                            questionType={QuestionTypeEnum.Radio}
                            questionAnswers={[]}
                            questionOperation={OperationEnum.Saving}
                            questionConditions={[]}
                            questionCode={''}
                            questionUniqueCode={''}
                            questionLimit={0}
                            status={createStatus}
                            dispatch={dispatch}
                            changeMode={changeMode}
                            saveQuestion={createQuestionAsync}
                            deleteQuestion={deleteQuestionAsync}

                            answersListLoadData={getCreateAnswersAsync}
                            answersListQuery={createAnswersListQuery}
                            answersListSkip={createAnswersListSkip}
                            answersListTake={createAnswersListTake}
                            answersListData={createAnswersListData}
                            answersListStatus={createAnswersListStatus}
                            answersSetQuery={createAnswersListChangeQuery}
                            answersOnNext={createAnswersListLoadNextPage}
                            answersOnPrevious={createAnswersListLoadPreviousPage}

                            conditionsQuery={conditionsListQuery}
                            conditionsListSkip={conditionsListSkip}
                            conditionsListTake={conditionsListTake}
                            conditionsQuestions={conditionsListValue}
                            conditionsQuestionStatus={conditionsListStatus}
                            conditionsLoadQuestionEdit={getConditionQuestionsAsync}
                            conditionsSetQuery={changeQueryConditions}
                            conditionsOnNext={loadNextPageConditions}
                            conditionsOnPrevious={loadPreviousPageConditions}
                        />}
                    />
                </Col>
                <NotificationToast
                    show={toastShow}
                    setShow={changeShowToast}
                    dispatch={dispatch}
                    header={toastHeader}
                    body={toastBody}
                />
            </Row >
    );
}