import { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { ModeTransition } from '../../shared/components/ModeTransition/ModeTransition';
import { NotificationToast } from '../../shared/components/NotificationToast/NotificationToast';
import { ModeEnum, OperationEnum } from '../../shared/models/Enums';
import { selectLoginRole } from '../login/loginSlice';
import { SectionLayout } from './SectionLayout/SectionLayout';
import {
    changeMode,
    changeQuery,
    changeShowToast,
    createQuestionsListChangeQuery,
    createQuestionsListLoadNextPage,
    createQuestionsListLoadPreviousPage,
    createSectionAsync,
    deleteSectionAsync,
    editQuestionsListChangeQuery,
    editQuestionsListLoadNextPage,
    editQuestionsListLoadPreviousPage,
    editSectionAsync,
    getCreateQuestionsAsync,
    getQuestionsAsync,
    getSectionsAsync,
    loadNextPage,
    loadPreviousPage,
    loadSectionEdit,
    selectSectionCreateQuestionsListData,
    selectSectionCreateQuestionsListQuery,
    selectSectionCreateQuestionsListSkip,
    selectSectionCreateQuestionsListStatus,
    selectSectionCreateQuestionsListTake,
    selectSectionCreateStatus,
    selectSectionEdit,
    selectSectionEditOperation,
    selectSectionEditQuestionsListData,
    selectSectionEditQuestionsListQuery,
    selectSectionEditQuestionsListSkip,
    selectSectionEditQuestionsListStatus,
    selectSectionEditQuestionsListTake,
    selectSectionEditStatus,
    selectSectionMode, selectSectionsList, selectSectionsListQuery, selectSectionsListSkip, selectSectionsListStatus, selectSectionsListTake,
    selectSectionToastBody, selectSectionToastHeader, selectSectionToastShow
} from './sectionSlice';
import { SectionsList } from './SectionsList/SectionsList';

export const Section: FC = () => {
    const role = useAppSelector(selectLoginRole);

    const dispatch = useAppDispatch();

    const mode = useAppSelector(selectSectionMode);
    const toastShow = useAppSelector(selectSectionToastShow);
    const toastHeader = useAppSelector(selectSectionToastHeader);
    const toastBody = useAppSelector(selectSectionToastBody);

    const listStatus = useAppSelector(selectSectionsListStatus);
    const listSkip = useAppSelector(selectSectionsListSkip);
    const listTake = useAppSelector(selectSectionsListTake);
    const listValue = useAppSelector(selectSectionsList);
    const listQuery = useAppSelector(selectSectionsListQuery);

    const editValue = useAppSelector(selectSectionEdit);
    const editStatus = useAppSelector(selectSectionEditStatus);
    const editOperation = useAppSelector(selectSectionEditOperation);
    const editQuestionsListData = useAppSelector(selectSectionEditQuestionsListData);
    const editQuestionsListStatus = useAppSelector(selectSectionEditQuestionsListStatus);
    const editQuestionsListQuery = useAppSelector(selectSectionEditQuestionsListQuery);
    const editQuestionsListSkip = useAppSelector(selectSectionEditQuestionsListSkip);
    const editQuestionsListTake = useAppSelector(selectSectionEditQuestionsListTake);

    const createStatus = useAppSelector(selectSectionCreateStatus);
    const createQuestionsListData = useAppSelector(selectSectionCreateQuestionsListData);
    const createQuestionsListStatus = useAppSelector(selectSectionCreateQuestionsListStatus);
    const createQuestionsListQuery = useAppSelector(selectSectionCreateQuestionsListQuery);
    const createQuestionsListSkip = useAppSelector(selectSectionCreateQuestionsListSkip);
    const createQuestionsListTake = useAppSelector(selectSectionCreateQuestionsListTake);

    useEffect(() => {
        if (mode === ModeEnum.List) {
            dispatch(getSectionsAsync({
                skip: listSkip,
                take: listTake,
                query: listQuery
            }));
        }
    }, [mode, dispatch, listSkip, listTake, listQuery]);

    return (
        (!role || role !== 'admin')
            ? <div></div>
            : <Row>
                <Col md={{ span: 12, offset: 0 }}>
                    <ModeTransition
                        cssClass='mt-4 p-5 rounded shadow bg-white'
                        mode={mode}
                        list={<SectionsList
                            sections={listValue}
                            sectionStatus={listStatus}
                            dispatch={dispatch}
                            loadSectionEdit={loadSectionEdit}
                            query={listQuery}
                            setQuery={changeQuery}
                            onNext={loadNextPage}
                            onPrevious={loadPreviousPage}
                        />}
                        edit={<SectionLayout
                            sectionId={editValue.id}
                            sectionTitleBG={editValue.titleBg}
                            sectionTitleEN={editValue.titleEn}
                            sectionQuestions={editValue.questions}
                            sectionHasAdditionalQuestions={editValue.hasAdditionalQuestions}
                            sectionHasAdditionalQuestionsExternal={editValue.hasAdditionalQuestionsExternal}
                            sectionStatus={editStatus}
                            sectionOperation={editOperation}

                            dispatch={dispatch}
                            changeMode={changeMode}
                            saveSection={editSectionAsync}
                            deleteSection={deleteSectionAsync}

                            questionsListData={editQuestionsListData}
                            questionsListStatus={editQuestionsListStatus}
                            questionsListQuery={editQuestionsListQuery}
                            questionsListSkip={editQuestionsListSkip}
                            questionsListTake={editQuestionsListTake}
                            questionsSetQuery={editQuestionsListChangeQuery}
                            questionsOnNext={editQuestionsListLoadNextPage}
                            questionsOnPrevious={editQuestionsListLoadPreviousPage}
                            questionsListLoadData={getQuestionsAsync}
                        />}
                        create={<SectionLayout
                            sectionTitleBG={''}
                            sectionTitleEN={''}
                            sectionQuestions={[]}
                            sectionHasAdditionalQuestions={false}
                            sectionHasAdditionalQuestionsExternal={false}
                            sectionStatus={createStatus}
                            sectionOperation={OperationEnum.Saving}

                            dispatch={dispatch}
                            changeMode={changeMode}
                            saveSection={createSectionAsync}
                            deleteSection={deleteSectionAsync}

                            questionsListData={createQuestionsListData}
                            questionsListStatus={createQuestionsListStatus}
                            questionsListQuery={createQuestionsListQuery}
                            questionsListSkip={createQuestionsListSkip}
                            questionsListTake={createQuestionsListTake}
                            questionsSetQuery={createQuestionsListChangeQuery}
                            questionsOnNext={createQuestionsListLoadNextPage}
                            questionsOnPrevious={createQuestionsListLoadPreviousPage}
                            questionsListLoadData={getCreateQuestionsAsync}
                        />}
                    />
                </Col>
                <NotificationToast
                    show={toastShow}
                    setShow={changeShowToast}
                    dispatch={dispatch}
                    header={toastHeader}
                    body={toastBody}
                />
            </Row >
    );
}