import { FC } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './App.css';

import { Navigation } from './features/layout/navigation/Navigation';
import { MainContent } from './features/layout/main-content/MainContent';
import { Login } from './features/login/Login';
import { Answer } from './features/answer/Answer';
import { Question } from './features/question/Question';
import { Section } from './features/section/Section';
import { Survey } from './features/survey/Survey';
import { SurveyLive } from './features/survey/SurveyLive/SurveyLive';
import { useAppSelector } from './app/hooks';
import { selectLoginRole, selectLoginToken, selectScreenState, selectUserLanguage } from './features/login/loginSlice';
import { ResourcesService } from './shared/services/ResourcesService';
import { Company } from './features/company/Company';
import { Recommendation } from './features/recommendation/Recommendation';
import { Report } from './features/report/Report';


export const App: FC = () => {
    const token = useAppSelector(selectLoginToken);
    const role = useAppSelector(selectLoginRole);
    const isPrivate = !(token && role && role !== 'user');
    const isAdmin = token && role && role !== 'user';
    const toHide = !!token && role === 'user';
    const lang = useAppSelector(selectUserLanguage);

    const screenState = useAppSelector(selectScreenState);

    const routes: { name: string, path: string, isPrivate?: boolean, toHide?: boolean, component: JSX.Element, children?: { path: string, component: JSX.Element, isPrivate?: boolean, toHide?: boolean }[] }[] = [
        { name: ResourcesService.translate('Login', lang), path: '/', toHide, component: <Login /> },
        {
            name: ResourcesService.translate('Companies', lang),
            path: '/companies',
            isPrivate,
            toHide,
            component: <Company />,
            children: [
                { path: '', isPrivate, toHide, component: <Company />, },
                { path: ':companyId/surveys/:surveyId', isPrivate, toHide, component: <SurveyLive /> },
            ]
        },
        { name: ResourcesService.translate('Surveys', lang), path: '/surveys', isPrivate, toHide, component: <Survey /> },
        { name: ResourcesService.translate('Sections', lang), path: '/sections', isPrivate, toHide, component: <Section /> },
        { name: ResourcesService.translate('Questions', lang), path: '/questions', isPrivate, toHide, component: <Question /> },
        { name: ResourcesService.translate('Answers', lang), path: '/answers', isPrivate, toHide, component: <Answer /> },
        { name: ResourcesService.translate('Recommendations', lang), path: '/recommendations', isPrivate, toHide, component: <Recommendation /> },
        { name: ResourcesService.translate('Reports', lang), path: '/reports', isPrivate, toHide, component: <Report /> },
    ];

    return (
        <div className={`App ${screenState === 'home' ? 'bw-homeScreen' : ''} ${screenState === 'login' ? 'bw-login' : ''} ${screenState === 'survey' ? 'bw-survey' : ''}`}>
            {
                isAdmin &&
                <Navigation routes={routes} />
            }
            <MainContent routes={routes} />
        </div>
    );
}

export default App;
