import { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { CancelButton } from '../../../shared/components/CancelButton/CancelButton';
import { DeleteButton } from '../../../shared/components/DeleteButton/DeleteButton';
import { GenericLayout } from '../../../shared/components/GenericLayout/GenericLayout';
import { GenericList } from '../../../shared/components/GenericList/GenericList';
import { SaveButton } from '../../../shared/components/SaveButton/SaveButton';
import { VerticallyCenteredModal } from '../../../shared/components/VerticallyCenteredModal/VerticallyCenteredModal';
import { ModeEnum, OperationEnum, StatusEnum } from '../../../shared/models/Enums';
import { HelperService } from '../../../shared/services/HelperService';
import { QuestionData } from '../../../shared/models/Entities';
import { FormGroupId } from '../../../shared/components/FormGroupId/FormGroupId';
import { FormGroupTextarea } from '../../../shared/components/FormGroupTextarea/FormGroupTextarea';
import { FormWithHandle } from '../../../shared/components/FormWithHandle/FormWithHandle';
import { ResourcesService } from '../../../shared/services/ResourcesService';
import { useAppSelector } from '../../../app/hooks';
import { selectUserLanguage } from '../../login/loginSlice';
import { FormGroupSelectFactorType } from '../FormGroupSelectFactorType';


type RecommendationLayoutProps = {
    recommendationId?: string,
    recommendationTitleBG: string,
    recommendationTitleEN: string,

    recommendationWeakPointText: string,
    recommendationWeakPointTextEn: string,


    recommendationWeakPointSelfText: string,
    recommendationWeakPointSelfTextEn: string,

    recommendationStrongPointText: string,
    recommendationStrongPointTextEn: string,

    recommendationType: number,


    recommendationQuestions: QuestionData[],
    recommendationStatus: StatusEnum,
    recommendationOperation: OperationEnum,

    dispatch: Function,
    changeMode: Function,
    saveRecommendation: Function,
    deleteRecommendation: Function,

    questionsListData: QuestionData[],
    questionsListStatus: StatusEnum,
    questionsListQuery: string,
    questionsListSkip: number,
    questionsListTake: number,
    questionsSetQuery: Function,
    questionsOnNext: Function,
    questionsOnPrevious: Function,
    questionsListLoadData: Function,
};

export const RecommendationLayout: FC<RecommendationLayoutProps> = ({
    recommendationId,
    recommendationTitleBG,
    recommendationTitleEN,

    recommendationWeakPointText,
    recommendationWeakPointTextEn,
    recommendationWeakPointSelfText,
    recommendationWeakPointSelfTextEn,
    recommendationStrongPointText,
    recommendationStrongPointTextEn,
    recommendationType,

    recommendationQuestions,
    recommendationStatus,
    recommendationOperation,

    dispatch,
    changeMode,
    saveRecommendation,
    deleteRecommendation,

    questionsListData,
    questionsListStatus,
    questionsListQuery,
    questionsListSkip,
    questionsListTake,
    questionsSetQuery,
    questionsOnNext,
    questionsOnPrevious,
    questionsListLoadData,
}) => {
    const [modalShow, setModalShow] = useState(false);
    const [titleBg, setTitleBG] = useState(recommendationTitleBG);
    const [titleEn, setTitleEN] = useState(recommendationTitleEN);

    const [weakPointText, setWeakPointText] = useState(recommendationWeakPointText);
    const [weakPointTextEn, setWeakPointTextEn] = useState(recommendationWeakPointTextEn);

    const [weakPointSelfText, setWeakPointSelfText] = useState(recommendationWeakPointSelfText);
    const [weakPointSelfTextEn, setWeakPointSelfTextEn] = useState(recommendationWeakPointSelfTextEn);

    const [strongPointText, setStrongPointText] = useState(recommendationStrongPointText);
    const [strongPointTextEn, setStrongPointTextEn] = useState(recommendationStrongPointTextEn);

    const [factorType, setFactorType] = useState(recommendationType);

    const [questions, setQuestions] = useState(recommendationQuestions);
    const [questionsModalShow, setQuestionsModalShow] = useState(false);

    useEffect(() => {
        if (questionsModalShow) {
            dispatch(questionsListLoadData({
                skip: questionsListSkip,
                take: questionsListTake,
                query: questionsListQuery,
                excludeIds: questions.map(a => a.id)
            }));
        }
    }, [questionsModalShow, dispatch, questionsListLoadData, questionsListSkip, questionsListTake, questionsListQuery, questions]);

    const disabled = recommendationStatus !== StatusEnum.Idle;

    const onSave = () => {
        if (recommendationId) {
            dispatch(saveRecommendation({
                id: recommendationId,
                title: titleBg,
                titleEn,
                weakPointText,
                weakPointTextEn,
                weakPointSelfText,
                weakPointSelfTextEn,
                strongPointText,
                strongPointTextEn,
                type: factorType,
                questions,
            }));

            return;
        }

        dispatch(saveRecommendation({
            title: titleBg,
            titleEn,
            weakPointText,
            weakPointTextEn,
            weakPointSelfText,
            weakPointSelfTextEn,
            strongPointText,
            strongPointTextEn,
            type: factorType,
            questions,
        }));
    };

    const addQuestion = (question: QuestionData) => HelperService.add(question, questions, setQuestions);
    const removeQuestion = (question: QuestionData) => HelperService.remove(question, questions, setQuestions);

    const lang = useAppSelector(selectUserLanguage);
    const tableHeaders = ['#', ResourcesService.translate('Title', lang)];

    return (
        <GenericLayout
            lang={lang}
            onBack={() => dispatch(changeMode(ModeEnum.List))}
            status={recommendationStatus}
            h2={`${recommendationId ? ResourcesService.translate('Edit', lang) : ResourcesService.translate('Create', lang)} ${ResourcesService.translate('Recommendation', lang)}`}
            buttons={[
                <DeleteButton lang={lang} status={recommendationStatus} operation={recommendationOperation} onDelete={() => setModalShow(true)} />,
                <SaveButton lang={lang} status={recommendationStatus} operation={recommendationOperation} onSave={() => onSave()} />,
                <CancelButton lang={lang} status={recommendationStatus} onCancel={() => dispatch(changeMode(ModeEnum.List))} />,
            ]}
        >
            <FormWithHandle onSubmit={onSave}>
                <>
                    {
                        recommendationId &&
                        <FormGroupId lang={lang} controlId='recommendationId' defaultValue={recommendationId} />
                    }
                </>
                <FormGroupTextarea label={ResourcesService.translate('Title', lang)} controlId='recommendationTitle' valueBG={titleBg} setValueBG={setTitleBG} valueEN={titleEn} setValueEN={setTitleEN} disabled={disabled} />

                <FormGroupTextarea label={ResourcesService.translate('Weak', lang)} controlId='recommendationWeakPoint' valueBG={weakPointText} setValueBG={setWeakPointText} valueEN={weakPointTextEn} setValueEN={setWeakPointTextEn} disabled={disabled} />

                <FormGroupTextarea label={ResourcesService.translate('Weak Self', lang)} controlId='recommendationWeakPointSelf' valueBG={weakPointSelfText} setValueBG={setWeakPointSelfText} valueEN={weakPointSelfTextEn} setValueEN={setWeakPointSelfTextEn} disabled={disabled} />

                <FormGroupTextarea label={ResourcesService.translate('Strong', lang)} controlId='recommendationStrongPoint' valueBG={strongPointText} setValueBG={setStrongPointText} valueEN={strongPointTextEn} setValueEN={setStrongPointTextEn} disabled={disabled} />

                <FormGroupSelectFactorType controlId='questionType' setValue={setFactorType} defaultValue={factorType} disabled={disabled} />

                <>
                    {
                        questions.map((q, i) =>
                            <Form.Group as={Row} className="mb-3" controlId={`questionId-${q.id}`} key={`questionId-${q.id}`}>
                                <Form.Label column sm="2">{q.uniqueCode}</Form.Label>
                                <Col sm="6">
                                    <Form.Control disabled value={ResourcesService.translateInput(q.titleBg, q.titleEn, lang)} as={'textarea'} />
                                </Col>
                                <Col sm="4">
                                    <DeleteButton lang={lang} status={StatusEnum.Idle} operation={OperationEnum.None} onDelete={() => removeQuestion(q)} />
                                </Col>
                            </Form.Group>
                        )
                    }
                </>
            </FormWithHandle>
            <Row>
                <Col>
                    <Button variant="outline-secondary" size='sm' disabled={disabled} onClick={() => setQuestionsModalShow(true)}>
                        <i className='bi bi-plus-lg'></i> {ResourcesService.translate('Add question', lang)}
                    </Button>
                </Col>
            </Row>
            <>
                {
                    recommendationId &&
                    <VerticallyCenteredModal
                        id='recommendation-delete-confirmation-dialog'
                        lang={lang}
                        title={ResourcesService.translate('Delete Recommendation', lang)}
                        show={modalShow}
                        onCancel={() => setModalShow(false)}
                        onOk={() => dispatch(deleteRecommendation(recommendationId))}
                    >
                        <p>{ResourcesService.translate('Are you sure you want to delete the item?', lang)}</p>
                    </VerticallyCenteredModal>
                }
            </>
            <VerticallyCenteredModal
                id='recommendation-edit-add-question-dialog'
                lang={lang}
                title={ResourcesService.translate('Select questions', lang)}
                show={questionsModalShow}
                onCancel={() => setQuestionsModalShow(false)}
                onOk={() => setQuestionsModalShow(false)}
            >
                <GenericList
                    lang={lang}
                    headContent={
                        <tr>
                            {
                                tableHeaders.map(h => <th key={h}>{h}</th>)
                            }
                        </tr>
                    }
                    bodyContent={
                        questionsListData.map((d, i) =>
                            <tr key={i} onClick={() => addQuestion(d)}>
                                <td>{d.id}</td>
                                <td>{ResourcesService.translateInput(d.titleBg, d.titleEn, lang)}</td>
                            </tr>
                        )
                    }
                    listStatus={questionsListStatus}
                    numberOfRows={5}
                    numberOfColumns={tableHeaders.length}
                    searchValue={questionsListQuery}
                    onSearch={(value: string) => dispatch(questionsSetQuery(value))}
                    onNext={() => dispatch(questionsOnNext())}
                    onPrevious={() => dispatch(questionsOnPrevious())}
                />
            </VerticallyCenteredModal>
        </GenericLayout>
    );
}