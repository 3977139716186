import { FC } from 'react';
import { Alert, Col, ListGroup, ProgressBar, Row } from 'react-bootstrap';
import { useAppSelector } from '../../../app/hooks';
import { NextButton } from '../../../shared/components/NextButton/NextButton';
import { OperationEnum, QuestionTypeEnum, StatusEnum } from '../../../shared/models/Enums';
import { selectUserLanguage } from '../../login/loginSlice';
import { LiveAnswer } from '../models/LiveSurvey';
import { SurveyLiveAnswers } from '../SurveyLiveAnswers/SurveyLiveAnswers';

type SurveyLiveLayoutGenericProps = {
    status: StatusEnum,
    titleBg: string,
    titleEn: string,
    now: number,
    questionTitle: string,
    questionSubtitle: string,
    questionType: QuestionTypeEnum,
    questionLimit: number,
    questionAnswers: LiveAnswer[],
    questionAnswersSelected: string[],
    questionAnswerersRanking: { answer: { position?: number, id: string } }[],
    onSelect: Function,
    inputAnswer: string,
    setInputAnswer: Function,
    onSave: Function,
    alertText?: string
};

export const SurveyLiveLayoutGeneric: FC<SurveyLiveLayoutGenericProps> = ({
    status, titleBg, titleEn, now, questionTitle, questionSubtitle, questionType, questionLimit, questionAnswers, questionAnswersSelected, questionAnswerersRanking, onSelect, 
    inputAnswer, setInputAnswer, onSave, alertText
}) => {
    const lang = useAppSelector(selectUserLanguage);

    return <>
        {questionTitle !== '' &&
            <div className='mt-5 mb-1 rounded bw-questionContainer'>
                <Row className='mb-5'>
                    <Col>
                        {status === StatusEnum.Loading &&
                            <ProgressBar now={0} />}
                        {status === StatusEnum.Idle &&
                            <>
                                <div className='bw-progressContainer'>
                                    <span className='progress-value' style={{ left: "calc(" + now + "% - 0.7em)" }}>{now}%</span>
                                    <ProgressBar now={now} />
                                </div>
                            </>}
                    </Col>
                </Row>
                {alertText &&
                    <Row className='mb-5'>
                        <Alert variant='warning'>
                            <i className="bi bi-exclamation-triangle"></i> {alertText}
                        </Alert>
                    </Row>}
                <Row className='mb-4 bw-subtitle'>
                    <Col>
                        <h6>
                            {status === StatusEnum.Loading &&
                                <>
                                    <div className='skeleton skeleton-text'></div>
                                    <div className='skeleton skeleton-text'></div>
                                </>}
                            {status === StatusEnum.Idle && questionSubtitle &&
                                <span className='rounded'>{questionSubtitle}</span>}
                        </h6>
                    </Col>
                </Row>
                <Row className='mb-4 bw-title'>
                    <Col>
                        <h5>
                            {status === StatusEnum.Loading &&
                                <div className='skeleton skeleton-text'></div>}
                            {status === StatusEnum.Idle &&
                                questionTitle}
                        </h5>
                    </Col>
                </Row>
                <ListGroup>
                    <Row>
                        <SurveyLiveAnswers
                            status={status}
                            questionType={questionType}
                            questionLimit={questionLimit}
                            questionAnswers={questionAnswers}
                            questionAnswersSelected={questionAnswersSelected}
                            questionAnswerersRanking={questionAnswerersRanking}
                            onSelect={onSelect}
                            inputAnswer={inputAnswer}
                            setInputAnswer={setInputAnswer}
                        />
                    </Row>
                </ListGroup>
                <Row>
                    <div className='bw-navigation-container'>
                        <img className="bw-footerLogo" src="https://collabo.solutions/customStyles/BeyondWorkHeader.png" alt='BeyondWorkHeader.png' />
                        <NextButton lang={lang} className='mt-3 mb-3' status={status} operation={OperationEnum.Saving} onSave={onSave} />
                    </div>
                </Row>
            </div>}
    </>;
}