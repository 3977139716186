import { FC } from 'react';
import { Col, ListGroup } from 'react-bootstrap';
import { QuestionTypeEnum, StatusEnum } from '../../../shared/models/Enums';
import { LiveAnswer } from '../models/LiveSurvey';
import { SurveyLiveAnswersAnswerTitle } from './SurveyLiveAnswersAnswerTitle';

type SurveyLiveAnswersQuestionRadioProps = {
    status: StatusEnum,
    questionType: QuestionTypeEnum,
    questionAnswers: LiveAnswer[],
    questionAnswersSelected: string[],
    onSelect: Function,
};

export const SurveyLiveAnswersQuestionRadio: FC<SurveyLiveAnswersQuestionRadioProps> = ({ 
    status, questionType, questionAnswers, questionAnswersSelected, onSelect,
 }) =>
    <>
        {
            status === StatusEnum.Idle &&
            questionType === QuestionTypeEnum.Radio &&
            questionAnswers.map(a =>
                <Col md={6} key={a.id} className={'mt-3 bw-radioOption'}>
                    <ListGroup.Item action onClick={() => onSelect(a)} variant={`${questionAnswersSelected && questionAnswersSelected.includes(a.id) ? 'success' : ''}`}>
                        <SurveyLiveAnswersAnswerTitle questionAnswersSelected={questionAnswersSelected} a={a} />
                    </ListGroup.Item>
                </Col>
            )
        }
    </>