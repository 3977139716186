import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { SectionData } from '../../shared/models/Entities';
import { ModeEnum, OperationEnum, StatusEnum } from '../../shared/models/Enums';
import { Filter } from '../../shared/models/Filter';
import { getQuestions } from '../question/questionAPI';
import { CreateSectionData } from './models/CreateSectionData';
import { SectionState } from './models/SectionState';
import { createSection, deleteSection, editSection, getSection, getSections } from './sectionAPI';


const initialState: SectionState = {
    sectionsList: {
        data: [],
        status: StatusEnum.Idle,
        query: '',
        skip: 0,
        take: 5,
    },
    sectionEdit: {
        data: {
            id: '',
            questionsCount : 0,
            titleBg: '',
            titleEn: '',
            hasAdditionalQuestions : false,
            hasAdditionalQuestionsExternal : false,
            questions: [],
        },
        status: StatusEnum.Idle,
        operation: OperationEnum.None,
        questionsList: {
            data: [],
            status: StatusEnum.Idle,
            query: '',
            skip: 0,
            take: 5,
        },
    },
    sectionCreate: {
        status: StatusEnum.Idle,
        questionsList: {
            data: [],
            status: StatusEnum.Idle,
            query: '',
            skip: 0,
            take: 5,
        },
    },
    mode: ModeEnum.List,
    toast: {
        show: false,
        header: '',
        body: '',
    }
};

export const getSectionsAsync = createAsyncThunk(
    'section/getSections',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getSections(state.login.token, filter);
        return response.data;
    }
);

export const getSectionAsync = createAsyncThunk(
    'section/getSection',
    async (id : string, { getState }) => {
        const state: any = getState();
        const response = await getSection(state.login.token, id);
        return response.data;
    }
);

export const createSectionAsync = createAsyncThunk(
    'section/createSection',
    async (createData: CreateSectionData, { getState }) => {
        const state: any = getState();
        const response = await createSection(state.login.token, createData);
        return response.data;
    }
);

export const editSectionAsync = createAsyncThunk(
    'section/editSection',
    async (editData: SectionData, { getState }) => {
        const state: any = getState();
        const response = await editSection(state.login.token, editData);
        return response.data;
    }
);

export const deleteSectionAsync = createAsyncThunk(
    'section/deleteSection',
    async (sectionId: string, { getState }) => {
        const state: any = getState();
        const response = await deleteSection(state.login.token, sectionId);
        return response.data;
    }
);

export const getQuestionsAsync = createAsyncThunk(
    'section/getQuestions',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getQuestions(state.login.token, filter);
        return response.data;
    }
);

export const getCreateQuestionsAsync = createAsyncThunk(
    'section/getCreateQuestions',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getQuestions(state.login.token, filter);
        return response.data;
    }
);

export const sectionSlice = createSlice({
    name: 'section',
    initialState,
    reducers: {
        // Global
        changeMode: (state, action: PayloadAction<ModeEnum>) => {
            state.mode = action.payload;
        },
        changeShowToast: (state, action: PayloadAction<boolean>) => {
            state.toast.show = action.payload;

            if (action.payload === false) {
                state.toast.header = '';
                state.toast.body = '';
            }
        },
        // Edit
        loadSectionEdit: (state, action: PayloadAction<SectionData>) => {
            state.sectionEdit.data = action.payload;
            state.sectionEdit.status = StatusEnum.Idle;
            state.mode = ModeEnum.Edit;
        },
        editQuestionsListChangeQuery: (state, action: PayloadAction<string>) => {
            state.sectionEdit.questionsList.query = action.payload;
        },
        editQuestionsListLoadNextPage: (state) => {
            if (state.sectionEdit.questionsList.data.length > 0) {
                state.sectionEdit.questionsList.skip = state.sectionEdit.questionsList.skip + state.sectionEdit.questionsList.take;
            }
        },
        editQuestionsListLoadPreviousPage: (state) => {
            if (state.sectionEdit.questionsList.skip > 0) {
                state.sectionEdit.questionsList.skip = Math.max(0, state.sectionEdit.questionsList.skip - state.sectionEdit.questionsList.take);
            }
        },
        // List
        changeQuery: (state, action: PayloadAction<string>) => {
            state.sectionsList.query = action.payload;
        },
        loadNextPage: (state) => {
            if (state.sectionsList.data.length > 0) {
                state.sectionsList.skip = state.sectionsList.skip + state.sectionsList.take;
            }
        },
        loadPreviousPage: (state) => {
            if (state.sectionsList.skip > 0) {
                state.sectionsList.skip = Math.max(0, state.sectionsList.skip - state.sectionsList.take);
            }
        },
        // Create
        createQuestionsListChangeQuery: (state, action: PayloadAction<string>) => {
            state.sectionCreate.questionsList.query = action.payload;
        },
        createQuestionsListLoadNextPage: (state) => {
            if (state.sectionCreate.questionsList.data.length > 0) {
                state.sectionCreate.questionsList.skip = state.sectionCreate.questionsList.skip + state.sectionCreate.questionsList.take;
            }
        },
        createQuestionsListLoadPreviousPage: (state) => {
            if (state.sectionCreate.questionsList.skip > 0) {
                state.sectionCreate.questionsList.skip = Math.max(0, state.sectionCreate.questionsList.skip - state.sectionCreate.questionsList.take);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            // List
            .addCase(getSectionsAsync.pending, (state) => {
                state.sectionsList.status = StatusEnum.Loading;
            })
            .addCase(getSectionsAsync.fulfilled, (state, action) => {
                state.sectionsList.status = StatusEnum.Idle;
                state.sectionsList.data = action.payload;
            })
            .addCase(getSectionsAsync.rejected, (state) => {
                state.sectionsList.status = StatusEnum.Failed;
            })

            //Get
            .addCase(getSectionAsync.pending, (state) => {
                state.sectionEdit.status = StatusEnum.Loading;
            })
            .addCase(getSectionAsync.fulfilled, (state, action) => {
                state.sectionEdit.status = StatusEnum.Idle;
                state.sectionEdit.data = action.payload;
                state.mode = ModeEnum.Edit;
            })
            .addCase(getSectionAsync.rejected, (state) => {
                state.sectionEdit.status = StatusEnum.Failed;
            })

            // Create
            .addCase(createSectionAsync.pending, (state) => {
                state.sectionCreate.status = StatusEnum.Loading;
            })
            .addCase(createSectionAsync.fulfilled, (state, action) => {
                state.sectionCreate.status = StatusEnum.Idle;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Section`;
                state.toast.body = `Created with title "${action.payload.titleEn}"`;
            })
            .addCase(createSectionAsync.rejected, (state) => {
                state.sectionCreate.status = StatusEnum.Failed;
            })
            .addCase(getCreateQuestionsAsync.pending, (state) => {
                state.sectionCreate.questionsList.status = StatusEnum.Loading;
            })
            .addCase(getCreateQuestionsAsync.fulfilled, (state, action) => {
                state.sectionCreate.questionsList.status = StatusEnum.Idle;
                state.sectionCreate.questionsList.data = action.payload;
            })
            .addCase(getCreateQuestionsAsync.rejected, (state) => {
                state.sectionCreate.questionsList.status = StatusEnum.Failed;
            })
            // Delete
            .addCase(deleteSectionAsync.pending, (state) => {
                state.sectionEdit.status = StatusEnum.Loading;
                state.sectionEdit.operation = OperationEnum.Deleting;
            })
            .addCase(deleteSectionAsync.fulfilled, (state, action) => {
                state.sectionEdit.status = StatusEnum.Idle;
                state.sectionEdit.operation = OperationEnum.None;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Section`;
                state.toast.body = `Deleted with id "${action.payload}"`;
            })
            .addCase(deleteSectionAsync.rejected, (state) => {
                state.sectionEdit.status = StatusEnum.Failed;
                state.sectionEdit.operation = OperationEnum.None;
            })
            // Edit
            .addCase(editSectionAsync.pending, (state) => {
                state.sectionEdit.status = StatusEnum.Loading;
                state.sectionEdit.operation = OperationEnum.Saving;
            })
            .addCase(editSectionAsync.fulfilled, (state, action) => {
                state.sectionEdit.status = StatusEnum.Idle;
                state.sectionEdit.operation = OperationEnum.None;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Section`;
                state.toast.body = `Edited with title "${action.payload.titleEn}"`;
            })
            .addCase(editSectionAsync.rejected, (state) => {
                state.sectionEdit.status = StatusEnum.Failed;
                state.sectionEdit.operation = OperationEnum.None;
            })
            .addCase(getQuestionsAsync.pending, (state) => {
                state.sectionEdit.questionsList.status = StatusEnum.Loading;
            })
            .addCase(getQuestionsAsync.fulfilled, (state, action) => {
                state.sectionEdit.questionsList.status = StatusEnum.Idle;
                state.sectionEdit.questionsList.data = action.payload;
            })
            .addCase(getQuestionsAsync.rejected, (state) => {
                state.sectionEdit.questionsList.status = StatusEnum.Failed;
            });
    },
});

export const {
    changeMode, changeShowToast, loadSectionEdit, changeQuery, loadNextPage, loadPreviousPage,
    editQuestionsListChangeQuery, editQuestionsListLoadNextPage, editQuestionsListLoadPreviousPage,
    createQuestionsListChangeQuery, createQuestionsListLoadNextPage, createQuestionsListLoadPreviousPage
} = sectionSlice.actions;

export const selectSectionMode = (state: RootState) => state.section.mode;
export const selectSectionToastShow = (state: RootState) => state.section.toast.show;
export const selectSectionToastHeader = (state: RootState) => state.section.toast.header;
export const selectSectionToastBody = (state: RootState) => state.section.toast.body;

export const selectSectionsList = (state: RootState) => state.section.sectionsList.data;
export const selectSectionsListQuery = (state: RootState) => state.section.sectionsList.query;
export const selectSectionsListStatus = (state: RootState) => state.section.sectionsList.status;
export const selectSectionsListSkip = (state: RootState) => state.section.sectionsList.skip;
export const selectSectionsListTake = (state: RootState) => state.section.sectionsList.take;

export const selectSectionEdit = (state: RootState) => state.section.sectionEdit.data;
export const selectSectionEditStatus = (state: RootState) => state.section.sectionEdit.status;
export const selectSectionEditOperation = (state: RootState) => state.section.sectionEdit.operation;
export const selectSectionEditQuestionsListData = (state: RootState) => state.section.sectionEdit.questionsList.data;
export const selectSectionEditQuestionsListStatus = (state: RootState) => state.section.sectionEdit.questionsList.status;
export const selectSectionEditQuestionsListQuery = (state: RootState) => state.section.sectionEdit.questionsList.query;
export const selectSectionEditQuestionsListSkip = (state: RootState) => state.section.sectionEdit.questionsList.skip;
export const selectSectionEditQuestionsListTake = (state: RootState) => state.section.sectionEdit.questionsList.take;

export const selectSectionCreateStatus = (state: RootState) => state.section.sectionCreate.status;
export const selectSectionCreateQuestionsListData = (state: RootState) => state.section.sectionCreate.questionsList.data;
export const selectSectionCreateQuestionsListStatus = (state: RootState) => state.section.sectionCreate.questionsList.status;
export const selectSectionCreateQuestionsListQuery = (state: RootState) => state.section.sectionCreate.questionsList.query;
export const selectSectionCreateQuestionsListSkip = (state: RootState) => state.section.sectionCreate.questionsList.skip;
export const selectSectionCreateQuestionsListTake = (state: RootState) => state.section.sectionCreate.questionsList.take;

export default sectionSlice.reducer;