import { FC } from 'react';
import { Col, ListGroup, } from 'react-bootstrap';
import { StatusEnum } from '../../../shared/models/Enums';

type SurveyLiveAnswersSkeletonProps = {
    status: StatusEnum,
};

export const SurveyLiveAnswersSkeleton: FC<SurveyLiveAnswersSkeletonProps> = ({ status }) =>
    <>
        {
            status === StatusEnum.Loading &&
            [...Array(4).map(x => ({}))].map((a, i) =>
                <Col md={6} key={i}>
                    <ListGroup.Item action><div className='skeleton skeleton-text'></div></ListGroup.Item>
                </Col>)
        }
    </>