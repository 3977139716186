import { BaseEntityData } from "../models/Entities";
import { QuestionTypeEnum } from "../models/Enums";

export class HelperService {

    static add(entity: BaseEntityData, entities: BaseEntityData[], setEntities: Function) {
        const index = entities.findIndex(a => a.id === entity.id);
        if (index < 0) {
            const newArray = entities.filter(a => a.id !== entity.id);
            setEntities([...newArray, entity]);
        }
    }

    static remove(entity: BaseEntityData, entities: BaseEntityData[], setEntities: Function) {
        const index = entities.findIndex(a => a.id === entity.id);
        if (index >= 0) {
            const newArray = entities.filter(a => a.id !== entity.id);
            setEntities([...newArray]);
        }
    }

    static numberToQuestionTypeEnum(value: number) {
        let typeToSet: QuestionTypeEnum;
        switch (value) {
            case 1:
                typeToSet = QuestionTypeEnum.Radio;
                break;
            case 2:
                typeToSet = QuestionTypeEnum.Text;
                break;
            case 3:
                typeToSet = QuestionTypeEnum.Textarea;
                break;
            case 4:
                typeToSet = QuestionTypeEnum.Checkbox;
                break;
            case 5:
                typeToSet = QuestionTypeEnum.Rating;
                break;
            case 6:
                typeToSet = QuestionTypeEnum.Ranking;
                break;

            default:
                typeToSet = QuestionTypeEnum.Radio;
                break;
        }

        return typeToSet;
    }
}