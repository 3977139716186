import { FC } from 'react';
import { Col, ListGroup } from 'react-bootstrap';
import { QuestionTypeEnum, StatusEnum } from '../../../shared/models/Enums';
import { LiveAnswer } from '../models/LiveSurvey';
import { SurveyLiveAnswersAnswerTitle } from './SurveyLiveAnswersAnswerTitle';

type SurveyLiveAnswersQuestionCheckboxProps = {
    status: StatusEnum,
    questionType: QuestionTypeEnum,
    questionLimit: number,
    questionAnswers: LiveAnswer[],
    questionAnswersSelected: string[],
    onSelect: Function,
};

export const SurveyLiveAnswersQuestionCheckbox: FC<SurveyLiveAnswersQuestionCheckboxProps> = ({ 
    status, questionType, questionLimit, questionAnswers, questionAnswersSelected, onSelect,
 }) => {

    function onSelectValidate(a: LiveAnswer) {
        if(questionAnswersSelected.includes(a.id) || questionLimit == 0 || questionAnswersSelected.length < questionLimit) {
            return onSelect(a);
        }
        return ;
    }
    

   return( <>
        {
            status === StatusEnum.Idle &&
            questionType === QuestionTypeEnum.Checkbox &&
            questionAnswers.map(a =>
                <Col md={6} key={a.id} className={'mt-3 bw-checkboxOption'}>
                    <ListGroup.Item action onClick={() => onSelectValidate(a)} variant={`${questionAnswersSelected && questionAnswersSelected.includes(a.id) ? 'success' : ''}`}>
                        <SurveyLiveAnswersAnswerTitle questionAnswersSelected={questionAnswersSelected} a={a} />
                    </ListGroup.Item>
                </Col>
            )
        }
    </>);


 }