import { QuestionTypeEnum } from "../../../shared/models/Enums";
import { PagedList } from "../../../shared/models/PagedList";
import { EditSurveyData } from "../models/EditSurveyData";
import { LiveAnswer, LiveQuestion, LiveSurvey } from "../models/LiveSurvey";
import { SurveyAnswers } from "../models/SurveyAnswers";

export class LiveSurveyMapper {
    static mapEditData(surveyResult: { data: EditSurveyData; }, answersResult: PagedList<SurveyAnswers>) {
        const liveQuestions: LiveQuestion[] = [];
        for (let i = 0; i < surveyResult.data.sections.length; i++) {
            const s = surveyResult.data.sections[i];

            for (let y = 0; y < s.questions.length; y++) {
                const q = s.questions[y];

                const liveQuestion: LiveQuestion = {
                    id: q.id,
                    titleBg: q.titleBg,
                    titleEn: q.titleEn,
                    subtitleBg: q.subtitleBg,
                    subtitleEn: q.subtitleEn,
                    type: q.type,
                    limit: q.limit ?? 0,
                    sectionId: s.id,
                    isAnswered: false,
                    answers: [],
                    answered: [],
                    questionConditionList: q.questionConditionList,
                }

                if (q.type === QuestionTypeEnum.Textarea) {
                    const answerInput = answersResult.data.find(a =>
                        a.questionId === q.id
                        && a.sectionId === s.id
                        && a.surveyId === surveyResult.data.id
                    );

                    if (answerInput) {
                        const liveAnswer: LiveAnswer = {
                            id: answerInput?.answerId as string,
                            titleBg: '',
                            titleEn: '',
                            isAnswered: true,
                            isExclude: false
                        };
                        liveQuestion.answers.push(liveAnswer);
                        liveQuestion.answered.push(liveAnswer);
                        liveQuestion.isAnswered = liveQuestion.answers.some(a => a.isAnswered);
                    }
                }

                for (let z = 0; z < q.answers.length; z++) {
                    const a = q.answers[z];

                    const liveAnswer: LiveAnswer = {
                        id: a.id,
                        titleBg: a.titleBg,
                        titleEn: a.titleEn,
                        isAnswered: answersResult.data.some(ar =>
                            ar.answerId === a.id
                            && ar.questionId === q.id
                            && ar.sectionId === s.id
                            && ar.surveyId === surveyResult.data.id),
                        isExclude: a.isExclude
                    };
                    liveQuestion.answers.push(liveAnswer);
                    if (liveAnswer.isAnswered) {
                        liveQuestion.answered.push(liveAnswer);
                    }

                    liveQuestion.isAnswered = liveQuestion.answers.some(a => a.isAnswered);
                }

                liveQuestions.push(liveQuestion)
            }
        }

        return liveQuestions;
    }

    static mapLiveData(surveyResult: LiveSurvey, answersResult: SurveyAnswers[]) {
        const liveQuestions: LiveQuestion[] = [];

        for (let y = 0; y < surveyResult.questions.length; y++) {
            const q = surveyResult.questions[y];

            const liveQuestion: LiveQuestion = {
                id: q.id,
                titleBg: q.titleBg,
                titleEn: q.titleEn,
                subtitleBg: q.subtitleBg,
                subtitleEn: q.subtitleEn,
                type: q.type,
                limit: q.limit ?? 0,
                sectionId: q.sectionId,
                isAnswered: q.isAnswered,
                answers: [],
                answered: [],
                questionConditionList: q.questionConditionList,
            }

            if (q.type === QuestionTypeEnum.Textarea) {
                const answerInput = answersResult.find(a =>
                    a.questionId === q.id
                    && a.sectionId === q.sectionId
                    && a.surveyId === surveyResult.id
                );

                if (answerInput) {
                    const liveAnswer: LiveAnswer = {
                        id: answerInput?.answerId as string,
                        titleBg: '',
                        titleEn: '',
                        isAnswered: true,
                        isExclude: false
                    };
                    liveQuestion.answers.push(liveAnswer);
                    liveQuestion.answered.push(liveAnswer);
                    liveQuestion.isAnswered = liveQuestion.answers.some(a => a.isAnswered);
                }
            }

            for (let z = 0; z < q.answers.length; z++) {
                const a = q.answers[z];

                const liveAnswer: LiveAnswer = {
                    id: a.id,
                    titleBg: a.titleBg,
                    titleEn: a.titleEn,
                    isAnswered: answersResult.some(ar =>
                        ar.answerId === a.id
                        && ar.questionId === q.id
                        && ar.sectionId === q.sectionId
                        && ar.surveyId === surveyResult.id),
                    isExclude: a.isExclude
                };
                liveQuestion.answers.push(liveAnswer);
                liveQuestion.isAnswered = liveQuestion.isAnswered || liveQuestion.answers.some(a => a.isAnswered);

                if (liveAnswer.isAnswered) {
                    liveQuestion.answered.push(liveAnswer);
                }
            }

            liveQuestions.push(liveQuestion)
        }

        return liveQuestions;
    }
}