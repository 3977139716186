import { FC } from "react";
import { Button, Spinner } from "react-bootstrap";
import { OperationEnum, StatusEnum } from "../../models/Enums";
import { ResourcesService } from "../../services/ResourcesService";

type NextButtonProps = {
    status: StatusEnum,
    operation: OperationEnum,
    onSave: Function,
    className?: string,
    lang: 'BG' | 'EN',
};

export const NextButton: FC<NextButtonProps> = ({ status, operation, onSave, className, lang }) =>
    <Button className={`${className || ''}`} variant="success" size='sm' onClick={() => onSave()} disabled={status !== StatusEnum.Idle}>
        {
            status === StatusEnum.Loading && operation === OperationEnum.Saving
            && <Spinner animation="border" variant="primary" as="span" size="sm" role="status" aria-hidden="true">
                <span className="visually-hidden">{ResourcesService.translate('Loading...', lang)}</span>
            </Spinner>
        }
        {
            (status !== StatusEnum.Loading || operation !== OperationEnum.Saving)
            && <span> {ResourcesService.translate('Next question', lang)}</span>
        }
        {
            status === StatusEnum.Loading && operation === OperationEnum.Saving
            && <span> {ResourcesService.translate('Saving', lang)}</span>
        }
        <i className='bi bi-chevron-right'></i>
    </Button>