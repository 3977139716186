import { FC, useState } from 'react';
import { Container, Nav, Navbar, Offcanvas, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectUserLanguage, changeLanguage } from '../../login/loginSlice';

export const Navigation: FC<{ routes: { name: string, path: string, isPrivate?: boolean, toHide?: boolean }[] }> = ({ routes }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => s);//setShow((s) => !s);

    const lang = useAppSelector(selectUserLanguage);
    const dispatch = useAppDispatch();

    return (
        <Navbar bg="dark" expand='sm' className="mb-3 shadow" variant='dark'>
            <Container fluid>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} onClick={toggleShow} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-md`}
                    show={show || undefined}
                    onHide={handleClose}
                >
                    <Offcanvas.Header closeButton></Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav
                            className='me-auto mx-auto mb-2 mb-lg-0'
                            as='ul'
                        >
                            {routes.filter(r => !r.isPrivate).filter(r => !r.toHide).map(r =>
                                <Nav.Item as='li' key={r.path} onClick={toggleShow}>
                                    <Nav.Link as={NavLink} to={r.path}>{r.name}</Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>                
            </Container>
        </Navbar>
    );
}