import { FC } from 'react';
import { Badge, ListGroup } from 'react-bootstrap';

import { SurveyLiveAnswersAnswerTitle } from './SurveyLiveAnswersAnswerTitle';
import { LiveAnswer } from '../models/LiveSurvey';
import { QuestionTypeEnum, StatusEnum } from '../../../shared/models/Enums';

type SurveyLiveAnswersQuestionRankingProps = {
    status: StatusEnum,
    questionType: QuestionTypeEnum,
    questionAnswers: LiveAnswer[],
    questionAnswerersRanking: { answer: { position?: number, id: string } }[],
    onSelect: Function,
};

export const SurveyLiveAnswersQuestionRanking: FC<SurveyLiveAnswersQuestionRankingProps> = ({
    status, questionType, questionAnswers, questionAnswerersRanking, onSelect,
}) => {
    const selectedIds = questionAnswerersRanking.map(qa => qa.answer.id);

    return <>
        {status === StatusEnum.Idle &&
            questionType === QuestionTypeEnum.Ranking &&
            questionAnswers.map(a =>
                <div key={a.id} className={'bw-rankingOption'}>
                    <ListGroup.Item variant={`${selectedIds && selectedIds.includes(a.id) ? 'success' : ''}`} action onClick={() => onSelect(a)}>
                        <SurveyLiveAnswersAnswerTitle questionAnswersSelected={selectedIds} a={a} />
                        {
                            (questionAnswerersRanking && questionAnswerersRanking.some(qa => qa.answer.id === a.id)) &&
                            <Badge bg="secondary">{(questionAnswerersRanking.find(qa => qa.answer.id === a.id)?.answer.position || 0) + 1}</Badge>
                        }
                    </ListGroup.Item>
                </div>
            )}
    </>;
}