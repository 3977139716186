import { FC } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ResourcesService } from "../../services/ResourcesService";

type FormGroupIdProps = {
    controlId: string,
    defaultValue: string,
    lang: 'BG' | 'EN',
};

export const FormGroupId: FC<FormGroupIdProps> = ({ controlId, defaultValue, lang }) =>
    <Form.Group as={Row} className="mb-3" controlId={controlId}>
        <Form.Label column sm="2">{ResourcesService.translate('Id', lang)}</Form.Label>
        <Col sm="10">
            <Form.Control plaintext readOnly defaultValue={defaultValue} />
        </Col>
    </Form.Group>