import http from "../../shared/services/HttpCommon";
import { RecommendationData } from "../../shared/models/Entities";
import { Filter } from "../../shared/models/Filter";
import { PagedList } from "../../shared/models/PagedList";
import { AuthHeaderService } from "../../shared/services/AuthHeaderService";
import { CreateRecommendationData } from "./models/CreateRecommendationData";

export async function getRecommendations(token: string, filter: Filter): Promise<PagedList<RecommendationData>> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const excludeIdQuery = filter.excludeIds && filter.excludeIds.length ? `&excludeIds=${filter.excludeIds.join('&excludeIds=')}` : '';
    const response = await http.get<RecommendationData[]>(`/recommendations?query=${filter.query}&skip=${filter.skip}&take=${filter.take}${excludeIdQuery}`, headersConfig);
    return {
        data: response.data
    };
}

export async function getRecommendation(token: string, id: string): Promise<{ data: RecommendationData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const response = await http.get<RecommendationData>(`/recommendations/${id}`, headersConfig);
    return {
        data : response.data
    }
}

export async function createRecommendation(token: string, createData: CreateRecommendationData): Promise<{ data: RecommendationData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const result = await http.post<RecommendationData>(`/recommendations`, createData, headersConfig);
    return {
        data: result.data
    };
}

export async function editRecommendation(token: string, editData: RecommendationData): Promise<{ data: RecommendationData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    await http.put<string>(`/recommendations/${editData.id}`, editData, headersConfig);
    return {
        data: editData
    };
}

export async function deleteRecommendation(token: string, id: string): Promise<{ data: string }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    await http.delete<string>(`/recommendations/${id}`, headersConfig);
    return {
        data: id
    };
}