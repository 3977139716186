import { FC, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, InputGroup, Row } from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { changeScreenState, loginAsync, registerAsync, selectLoginIsRegistered, selectLoginStatus, selectScreenState, selectUserLanguage } from './loginSlice';
import { LoadingTransition } from '../../shared/components/LoadingTransition/LoadingTransition';
import { Navigate, useParams } from 'react-router-dom';
import { ResourcesService } from '../../shared/services/ResourcesService';
import { WelcomeScreen } from './WelcomeScreen';

type LoginProps = {
    isRegister?: boolean,
    toShow?: boolean,
};

export const Login: FC<LoginProps> = ({ isRegister, toShow }) => {
    const dispatch = useAppDispatch();
    const loginStatus = useAppSelector(selectLoginStatus);
    const isRegistered = useAppSelector(selectLoginIsRegistered);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [phoneCode, setPhoneCode] = useState('');
    const [phoneCodeError, setPhoneCodeError] = useState('');
    const lang = useAppSelector(selectUserLanguage);
    const { surveyId } = useParams();
    const { companyId } = useParams();
    // const [showWelcome, setShowWelcome] = useState(!!companyId && !!surveyId);
    const screenState = useAppSelector(selectScreenState);
    const [registerTimeout, setRegisterTimeout] = useState(false);

    useEffect(() => {
        dispatch(changeScreenState((!!companyId && !!surveyId) ? 'home' : 'login'));
    }, [companyId, dispatch, surveyId])

    const onLogin = () => {
        if (phoneNumber.startsWith('0')) {
            setPhoneError(ResourcesService.translate('Phone number must not start with 0.', lang));
            return;
        }
        if (phoneNumber.length !== 9) {
            setPhoneError(ResourcesService.translate('Invalid phone number.', lang));
            return;
        }
        setPhoneError('');

        if (phoneCode.length !== 4) {
            setPhoneCodeError(ResourcesService.translate('Invalid phone code.', lang));
            return;
        }
        setPhoneCodeError('');

        dispatch(loginAsync({ phone: phoneNumber, code: phoneCode }));
        dispatch(changeScreenState('survey'));
    };

    const onRegister = () => {
        if (phoneNumber.length !== 9) {
            setPhoneError(ResourcesService.translate('Invalid phone number.', lang));
            return;
        }

        setRegisterTimeout(true);

        setTimeout(() => {
            setRegisterTimeout(false);
        }, 60000); // Timeout between new sms codes

        dispatch(registerAsync({ phone: phoneNumber }));
    };

    return isRegister && isRegistered
        ? <Navigate to={'/'} />
        : screenState === 'home'
            ? <WelcomeScreen onNext={() => dispatch(changeScreenState('login'))} />
            : (
                <Row className='bw-loginForm'>
                    <Col md={{ span: 5 }}>
                        <LoadingTransition cssClass='mt-4 p-5' status={loginStatus}>
                            <Form>
                                <Form.Group className="mb-3" controlId="formPhoneNumber">
                                    <Row>
                                        <Col>
                                            <h1>{ResourcesService.translate('Log in', lang)}</h1>
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col>
                                            <span className='bw-introText'>{ResourcesService.translate('Please enter your phone number in the field and press SEND CODE. You will receive an SMS code on this phone that you will have to enter in the CODE field. Following these steps, click the ENTER button to load the questionnaire.', lang)}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className='bw-inputHeader'>{ResourcesService.translate('Phone number', lang)}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">+359</InputGroup.Text>
                                                <Form.Control disabled={loginStatus === 'loading'} type="tel" placeholder={ResourcesService.translate('Specify here', lang)} value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                                                {
                                                    phoneError &&
                                                    <Alert variant='warning'>{phoneError}</Alert>
                                                }
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formPhoneCode">
                                    <Row>
                                        <Col>
                                            <span className='bw-inputHeader'>{ResourcesService.translate('Phone code', lang)}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Control type="password" disabled={loginStatus === 'loading'} placeholder={ResourcesService.translate('Specify here', lang)} value={phoneCode} onChange={e => setPhoneCode(e.target.value)} />
                                            {
                                                phoneCodeError &&
                                                <Alert variant='warning'>{phoneCodeError}</Alert>
                                            }
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Row className='mb-3'>
                                    <Col>
                                        <span className='bw-introText'>{ResourcesService.translate('In case you do not receive an SMS within 3 minutes, the BeyondWork team is available to assist you at ', lang)}</span><span className='bw-introText'><a href="mailto:hello@beyondwork.bg">hello@beyondwork.bg</a></span><span className='bw-introText'>{ResourcesService.translate(' or at ', lang)}</span><span className='bw-introText'><a href="tel:+359897505478">0897 505 478</a></span>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col sm='6'>
                                        <Button className='btn btn-sendCode' variant="secondary" disabled={loginStatus === 'loading' || registerTimeout} type="button" onClick={() => onRegister()}>
                                            {ResourcesService.translate('Send Code', lang)}
                                            <i className='bi bi-chevron-right'></i>
                                        </Button>
                                    </Col>
                                    <Col sm='6'>
                                        <Button className='btn btn-login' variant="primary" disabled={loginStatus === 'loading'} type="button" onClick={() => onLogin()}>
                                            {ResourcesService.translate('Log in', lang)}
                                            <i className='bi bi-chevron-right'></i>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </LoadingTransition>
                    </Col>
                </Row >
            );
}
