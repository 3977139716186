import { FC, useState } from "react";
import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";

import { ResourcesService } from "../../../shared/services/ResourcesService";

type CompanyActivitiesProps = {
    controlId: string,
    activitiesValue: string[],
    setActivitiesValue: (value: React.SetStateAction<string[]>) => void,
    lang: 'EN' | 'BG',
};

export const CompanyActivities: FC<CompanyActivitiesProps> = (
    { controlId, activitiesValue, setActivitiesValue, lang }
) => {
    const [newValue, setNewValue] = useState('');

    const onDelete = (value: string) => {
        setActivitiesValue(prevState => [
            ...prevState.filter(ps => ps !== value)
        ]);
        console.log('deplete');
    };

    const onCreate = (value: string) => {
        if (value) {
            setActivitiesValue(prevState => [
                value.trim(), ...prevState
            ]);
            setNewValue('');
        }
        console.log('create');
    };

    const onKeyUp = (key: string, value: string) => {
        if (key === 'Enter') {
            onCreate(value);
        }
    };

    return <Form.Group as={Row} className="mb-3" controlId={controlId}>
        <Form.Label column sm="2">{ResourcesService.translate('Activities', lang)}</Form.Label>
        <Col sm="10">
            <ListGroup as="ol">
                <ListGroup.Item as="li">
                    <Form.Control value={newValue} onChange={(e) => setNewValue(e.target.value)} onKeyUp={(e) => onKeyUp(e.key, newValue)} />
                    <Button className='float-end ms-2 mt-2' variant="outline-success" size='sm' onClick={() => onCreate(newValue)}>
                        <i className='bi bi-plus-lg'></i> {ResourcesService.translate('Create', lang)}
                    </Button>
                </ListGroup.Item>
                {
                    activitiesValue.map((av, i) =>
                        <ListGroup.Item as="li" key={`activities-${i}`}>
                            <span>{i + 1}. </span>
                            <span>{av}</span>
                            <Button className='float-end ms-2' variant="outline-danger" size='sm' onClick={() => onDelete(av)}>
                                <i className='bi bi-trash'></i>
                                {/* <span> {ResourcesService.translate('Delete', lang)}</span> */}
                            </Button>
                        </ListGroup.Item>)
                }
            </ListGroup>
        </Col>
    </Form.Group>;
}