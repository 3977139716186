import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ReportState } from './models/ReportState';
import { ReportApiData, getLeadershipLevel, getReport } from './reportAPI';
import { OperationEnum, StatusEnum } from '../../shared/models/Enums';
import { Filter } from '../../shared/models/Filter';
import { getCompanies, getCompany } from '../company/companyAPI';
import { EditCompanyData } from '../company/models/EditCompanyData';
import { RootState } from '../../app/store';
import { getSurveys } from '../survey/surveyAPI';
import { getRecommendations } from '../recommendation/recommendationAPI';


const initialState: ReportState = {
    companiesList: {
        data: [],
        status: StatusEnum.Idle,
        query: '',
        skip: 0,
        take: 2000,
    },
    selectedCompanyId : "",
    selectedReportId : 1,
    selectedSurveyId : "",
    selectedLeadershipLevelId : "",
    companyEdit: {
        data: {
            id: '',
            titleBg: '',
            titleEn: '',
            descriptionBg: '',
            descriptionEn: '',
            activities: [],
            sectorId: 0,
            employeeCount : 0,
            surveys: [],
            surveysCount : 0,
            logo : "",
        },
        status: StatusEnum.Idle,
        operation: OperationEnum.None,
        surveys: {
            data: [],
            status: StatusEnum.Idle,
            query: '',
            skip: 0,
            take: 5,
        },
    },
    companyCompare: {
        data: {
            id: '',
            titleBg: '',
            titleEn: '',
            descriptionBg: '',
            descriptionEn: '',
            activities: [],
            sectorId: 0,
            employeeCount : 0,
            surveys: [],
            surveysCount : 0,
            logo : "",
        },
        status: StatusEnum.Idle,
        operation: OperationEnum.None,
        surveys: {
            data: [],
            status: StatusEnum.Idle,
            query: '',
            skip: 0,
            take: 5,
        },
    },
    leadershipLevelList: {
        data: [],
        status: StatusEnum.Idle,
        query: '',
        skip: 0,
        take: 2000,
    }, 
    recommendationsList: {
        data: [],
        status: StatusEnum.Idle,
        query: '',
        skip: 0,
        take: 2000,
    },
    showLeadershipFilter: false
};

export const getReportCompaniesAsync = createAsyncThunk(
    'companies/getCompanies',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getCompanies(state.login.token, filter);
        return response.data;
    }
);

export const getReportSurveysAsync = createAsyncThunk(
    'companies/getSurveys',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getSurveys(state.login.token, filter);
        return response.data;
    }
);

export const getReportCompanyAsync = createAsyncThunk(
    'companies/getCompany',
    async (id:string, { getState }) => {
        const state: any = getState();
        const response = await getCompany(state.login.token, id);
        return response.data;
    }
);

export const getReportCompareCompanyAsync = createAsyncThunk(
    'companies/getCompareCompany',
    async (id:string, { getState }) => {
        const state: any = getState();
        const response = await getCompany(state.login.token, id);
        return response.data;
    }
);

export const getReportAsync = createAsyncThunk(
    'report/getPDF',
    async (args: ReportApiData, { getState }) => {
        const state: any = getState();
        console.log("args", args);
        const response = await getReport(state.login.token, args);
    }
);

export const getReportLeadershipLevelAsync = createAsyncThunk(
    'report/getLeadershiplevels',
    async (surveyId: string, { getState }) => {
        const state: any = getState();
        const response = await getLeadershipLevel(state.login.token, surveyId);
        return response.data;
    }
);

export const getRecommendationsAsync = createAsyncThunk(
    'recommendations/getRecommendations',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getRecommendations(state.login.token, filter);
        return response.data;
    }
);

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
          // Edit
        loadCompanyEdit: (state, action: PayloadAction<EditCompanyData>) => {
            state.companyEdit.data = action.payload;
            state.companyEdit.status = StatusEnum.Idle;
        },
        editCompanyChangeQuery: (state, action: PayloadAction<string>) => {
            state.companiesList.query = action.payload;
        },
        editCompanyLoadNextPage: (state) => {
            if (state.companiesList.data.length > 0) {
                state.companiesList.skip = state.companiesList.skip + state.companiesList.take;
            }
        },
        editCompanyLoadPreviousPage: (state) => {
            if (state.companiesList.skip > 0) {
                state.companiesList.skip = Math.max(0, state.companiesList.skip - state.companiesList.take);
            }
        },
    },
    extraReducers: (builder) => {
        builder
        // List
         .addCase(getReportCompaniesAsync.pending, (state) => {
            state.companiesList.status = StatusEnum.Loading;
        })
        .addCase(getReportCompaniesAsync.fulfilled, (state, action) => {
            state.companiesList.status = StatusEnum.Idle;
            state.companiesList.data = action.payload;
            if(state.companiesList.data.length > 0) {
                state.selectedCompanyId = state.companiesList.data[0].id;
            }
        })
        
        .addCase(getReportCompaniesAsync.rejected, (state) => {
            state.companiesList.status = StatusEnum.Failed;
        })
        //Get
        .addCase(getReportCompanyAsync.pending, (state) => {
            state.companyEdit.status = StatusEnum.Loading;
        })
        .addCase(getReportCompanyAsync.fulfilled, (state, action) => {
            state.companyEdit.status = StatusEnum.Idle;
            state.companyEdit.data = action.payload;
            if(state.companyEdit.data.surveys.length > 0) {
                state.selectedSurveyId = state.companyEdit.data.surveys[0].id;
            }
        })
        .addCase(getReportCompanyAsync.rejected, (state) => {
            state.companyEdit.status = StatusEnum.Failed;
        })
        // Leadership level filter 
        .addCase(getReportLeadershipLevelAsync.pending, (state) => {
            state.leadershipLevelList.status = StatusEnum.Loading;
        })
        .addCase(getReportLeadershipLevelAsync.fulfilled, (state, action) => {
            state.leadershipLevelList.status = StatusEnum.Idle;
            state.leadershipLevelList.data = action.payload;
            if (action.payload && action.payload.length > 0) {
                state.selectedLeadershipLevelId = action.payload[0].id;
            }
        })
        .addCase(getReportLeadershipLevelAsync.rejected, (state) => {
            state.leadershipLevelList.status = StatusEnum.Failed;
        })
        // Recommendations
        .addCase(getRecommendationsAsync.pending, (state) => {
            state.recommendationsList.status = StatusEnum.Loading;
        })
        .addCase(getRecommendationsAsync.fulfilled, (state, action) => {
            state.recommendationsList.status = StatusEnum.Idle;
            state.recommendationsList.data = action.payload;
        })
        
        .addCase(getRecommendationsAsync.rejected, (state) => {
            state.recommendationsList.status = StatusEnum.Failed;
        })

        //Compare company
        .addCase(getReportCompareCompanyAsync.pending, (state) => {
            state.companyCompare.status = StatusEnum.Loading;
        })
        .addCase(getReportCompareCompanyAsync.fulfilled, (state, action) => {
            state.companyCompare.status = StatusEnum.Idle;
            state.companyCompare.data = action.payload;
            // if(state.companyCompare.data.surveys.length > 0) {
            //     state.selectedSurveyId = state.companyCompare.data.surveys[0].id;
            // }
        })
        .addCase(getReportCompareCompanyAsync.rejected, (state) => {
            state.companyCompare.status = StatusEnum.Failed;
        })
    },
});


export const reportCompaniesList = (state: RootState) => state.report.companiesList.data;
export const reportSelectedCompanyId = (state: RootState) => state.report.selectedCompanyId;
export const reportSelectedSurveyId = (state: RootState) => state.report.selectedSurveyId;
export const reportSelectedReportId = (state: RootState) => state.report.selectedReportId;

// export const selectCompaniesListQuery = (state: RootState) => state.report.companiesList.query;
// export const selectCompaniesListStatus = (state: RootState) => state.report.companiesList.status;
// export const selectCompaniesListSkip = (state: RootState) => state.report.companiesList.skip;
// export const selectCompaniesListTake = (state: RootState) => state.report.companiesList.take;

export const reportCompanyEdit = (state: RootState) => state.report.companyEdit.data;
export const reportCompanyCompare = (state: RootState) => state.report.companyCompare.data;

export const reportCompareCompaniesList = (state: RootState) => state.report.companiesList;
export const reportLeadershipLevelList = (state: RootState) => state.report.leadershipLevelList.data;
export const reportSelectedLeadershipLevel = (state: RootState) => state.report.selectedLeadershipLevelId;
export const reportShowLeadershipFilter = (state: RootState) => state.report.showLeadershipFilter;
export const reportAddedRecommendationsList = (state: RootState) => state.report.recommendationsList;


// export const selectCompanyEditStatus = (state: RootState) => state.report.companyEdit.status;
// export const selectCompanyEditOperation = (state: RootState) => state.report.companyEdit.operation;
// export const selectCompanyEditSurveysListData = (state: RootState) => state.report.companyEdit.surveys.data;
// export const selectCompanyEditSurveysListStatus = (state: RootState) => state.report.companyEdit.surveys.status;
// export const selectCompanyEditSurveysListQuery = (state: RootState) => state.report.companyEdit.surveys.query;
// export const selectCompanyEditSurveysListSkip = (state: RootState) => state.report.companyEdit.surveys.skip;
// export const selectCompanyEditSurveysListTake = (state: RootState) => state.report.companyEdit.surveys.take;

export const {
    loadCompanyEdit,
    editCompanyChangeQuery,
    editCompanyLoadNextPage,
    editCompanyLoadPreviousPage
} = reportSlice.actions;



export default reportSlice.reducer;