
import http from "../../shared/services/HttpCommon";
import { Filter } from "../../shared/models/Filter";
import { PagedList } from "../../shared/models/PagedList";
import { CreateSurveyData } from "./models/CreateSurveyData";
import { EditSurveyData } from "./models/EditSurveyData";
import { AuthHeaderService } from "../../shared/services/AuthHeaderService";
import { SurveyAnswers } from "./models/SurveyAnswers";
import { CreateLiveAnswer } from "./models/LiveSurvey";
import { UserSurvey } from "./models/UserSurvey";
import { AnswerData } from "../../shared/models/Entities";

export async function getSurvey(token: string, id: string): Promise<{ data: EditSurveyData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const response = await http.get<EditSurveyData>(`/surveys/${id}`, headersConfig);
    return {
        data: response.data
    };
}

export async function createSurveyAnswer(token: string, createLiveAnswers: CreateLiveAnswer[]): Promise<PagedList<SurveyAnswers>> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);

    let createLiveAnswersBody = createLiveAnswers;

    // create text answer
    if (createLiveAnswersBody.length === 1 && createLiveAnswersBody[0].answerInput) {
        const answerCreateBody = {
            title: createLiveAnswersBody[0].answerInput,
            titleEn: createLiveAnswersBody[0].answerInput
        };
        
        const answerResponse = await http.post<AnswerData>(`/answers`, answerCreateBody, headersConfig);
        createLiveAnswersBody[0] = {
            ...createLiveAnswersBody[0],
            answerId: answerResponse.data.id,
        }        
    }

    const id = createLiveAnswersBody[0].surveyId;
    const response= await http.post<SurveyAnswers[]>(`/surveys/${id}/answers`, createLiveAnswersBody, headersConfig);
    return {
        data: response.data
    };
}

export async function getUserSurvey(token: string, companyId: string, surveyId: string): Promise<UserSurvey> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const response = await http.get<UserSurvey>(`/companies/${companyId}/surveys/${surveyId}`, headersConfig);
    return response.data;
}

export async function createUserSurvey(token: string, companyId: string, surveyId: string): Promise<boolean> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const response = await http.post<boolean>(`/companies/${companyId}/surveys/${surveyId}`, {}, headersConfig);
    return response.data;
}

export async function updateUserSurvey(token: string, companyId: string, surveyId: string): Promise<boolean> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const response = await http.put<boolean>(`/companies/${companyId}/surveys/${surveyId}`, {}, headersConfig);
    return response.data;
}

export async function getSurveyAnswers(token: string, id: string): Promise<PagedList<SurveyAnswers>> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const response = await http.get<SurveyAnswers[]>(`/surveys/${id}/answers`, headersConfig);
    return {
        data: response.data
    };
}

export async function getSurveys(token: string, filter: Filter): Promise<PagedList<EditSurveyData>> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const excludeIdQuery = filter.excludeIds && filter.excludeIds.length ? `&excludeIds=${filter.excludeIds.join('&excludeIds=')}` : '';
    const response = await http.get<EditSurveyData[]>(`/surveys?query=${filter.query}&skip=${filter.skip}&take=${filter.take}${excludeIdQuery}`, headersConfig);
    return {
        data: response.data
    };
}

export async function createSurvey(token: string, createData: CreateSurveyData): Promise<{ data: EditSurveyData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    const result = await http.post<EditSurveyData>(`/surveys`, createData, headersConfig);
    return {
        data: result.data
    };
}

export async function editSurvey(token: string, editData: EditSurveyData): Promise<{ data: EditSurveyData }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    await http.put<string>(`/surveys/${editData.id}`, editData, headersConfig);
    return {
        data: editData
    };
}

export async function deleteSurvey(token: string, id: string): Promise<{ data: string }> {
    const headersConfig = AuthHeaderService.authHeadersConfig(token);
    await http.delete<string>(`/surveys/${id}`, headersConfig);
    return {
        data: id
    };
}