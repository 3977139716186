import { FC } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { RequireAuth } from '../../../shared/components/RequireAuth/RequireAuth';
import { Language } from '../language/Language';

export const MainContent: FC<{ routes: { name: string, path: string, isPrivate?: Boolean, component: JSX.Element, children?: { path: string, isPrivate?: Boolean, component: JSX.Element }[] }[] }> = ({ routes }) => {
    const location = useLocation();
    return (
        <TransitionGroup className='tg'>
            <CSSTransition key={location.pathname} classNames='fade' timeout={300}>
                <div className='tg-content'>
                    <Container>
                        <Language />
                        <Routes location={location}>
                            {routes.map(r => {
                                return r.isPrivate
                                    ? <Route key={r.path} path={r.path} element={r.children ? undefined : <RequireAuth>{r.component}</RequireAuth>}>
                                        {
                                            r.children &&
                                            r.children.filter(c => c.isPrivate).map(c => <Route key={c.path} path={c.path} element={<RequireAuth>{c.component}</RequireAuth>} />)
                                        }
                                        {
                                            r.children &&
                                            r.children.filter(c => !c.isPrivate).map(c => <Route key={c.path} path={c.path} element={c.component} />)
                                        }
                                    </Route>
                                    : <Route key={r.path} path={r.path} element={r.children ? undefined : r.component}>
                                        {
                                            r.children &&
                                            r.children.map(c => <Route key={c.path} path={c.path} element={c.component} />)
                                        }
                                    </Route>

                            })}
                        </Routes>
                    </Container>
                </div>
            </CSSTransition>
        </TransitionGroup>
    );
}