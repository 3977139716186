import { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectAnswerMode, changeMode, selectAnswersListQuery, changeQuery, selectAnswersListSkip, selectAnswersListTake,
    loadNextPage, loadPreviousPage, selectAnswersListStatus, selectAnswersList, selectAnswerEdit, getAnswersAsync,
    loadAnswerEdit, createAnswerAsync, selectAnswerCreateStatus, selectAnswerToastShow, changeShowToast,
    selectAnswerToastHeader, selectAnswerToastBody, deleteAnswerAsync, selectAnswerEditStatus, editAnswerAsync,
    selectAnswerEditOperation,
} from './answerSlice';
import { AnswersList } from './AnswersList/AnswersList';
import { ModeTransition } from '../../shared/components/ModeTransition/ModeTransition';
import { ModeEnum, OperationEnum } from '../../shared/models/Enums';
import { NotificationToast } from '../../shared/components/NotificationToast/NotificationToast';
import { AnswerLayout } from './AnswerLayout/AnswerLayout';
import { selectLoginRole } from '../login/loginSlice';

export const Answer: FC = () => {
    const role = useAppSelector(selectLoginRole);

    const dispatch = useAppDispatch();

    const mode = useAppSelector(selectAnswerMode);
    const toastShow = useAppSelector(selectAnswerToastShow);
    const toastHeader = useAppSelector(selectAnswerToastHeader);
    const toastBody = useAppSelector(selectAnswerToastBody);

    const listStatus = useAppSelector(selectAnswersListStatus);
    const listSkip = useAppSelector(selectAnswersListSkip);
    const listTake = useAppSelector(selectAnswersListTake);
    const listValue = useAppSelector(selectAnswersList);
    const listQuery = useAppSelector(selectAnswersListQuery);

    const editValue = useAppSelector(selectAnswerEdit);
    const editStatus = useAppSelector(selectAnswerEditStatus);
    const editOperation = useAppSelector(selectAnswerEditOperation);

    const createStatus = useAppSelector(selectAnswerCreateStatus);

    useEffect(() => {
        if (mode === ModeEnum.List) {
            dispatch(getAnswersAsync({
                skip: listSkip,
                take: listTake,
                query: listQuery
            }));
        }
    }, [mode, dispatch, listSkip, listTake, listQuery]);

    return (
        (!role || role !== 'admin')
            ? <div></div>
            : <Row>
                <Col md={{ span: 12, offset: 0 }}>
                    <ModeTransition
                        cssClass='mt-4 p-5 rounded shadow bg-white'
                        mode={mode}
                        list={<AnswersList
                            answers={listValue}
                            answerStatus={listStatus}
                            dispatch={dispatch}
                            loadAnswerEdit={loadAnswerEdit}
                            query={listQuery}
                            setQuery={changeQuery}
                            onNext={loadNextPage}
                            onPrevious={loadPreviousPage}
                        />}
                        edit={<AnswerLayout
                            answerId={editValue.id}
                            answerTitleBG={editValue.titleBg}
                            answerTitleEN={editValue.titleEn}
                            answerStatus={editStatus}
                            dispatch={dispatch}
                            saveAnswer={editAnswerAsync}
                            changeMode={changeMode}
                            operation={editOperation}
                            deleteAnswer={deleteAnswerAsync}
                        />}
                        create={<AnswerLayout
                            answerTitleBG=''
                            answerTitleEN=''
                            answerStatus={createStatus}
                            dispatch={dispatch}
                            saveAnswer={createAnswerAsync}
                            changeMode={changeMode}
                            operation={OperationEnum.Saving}
                            deleteAnswer={deleteAnswerAsync}
                        />}
                    />
                </Col>
                <NotificationToast
                    show={toastShow}
                    setShow={changeShowToast}
                    dispatch={dispatch}
                    header={toastHeader}
                    body={toastBody}
                />
            </Row >
    );
}