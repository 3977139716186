import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { postLogin, register } from './loginAPI';

export interface ILoginState {
    token: string;
    role: string;
    status: 'idle' | 'loading' | 'failed';
    isRegistered: Boolean;
    language: 'BG' | 'EN';
    screenState: 'home' | 'login' | 'survey';
}

const initialState: ILoginState = {
    token: '',
    role: '',
    status: 'idle',
    isRegistered: false,
    language: 'BG',
    screenState: 'login',
};

export const loginAsync = createAsyncThunk(
    'login/postLogin',
    async (form: { phone: string, code: string }) => {
        const response = await postLogin(form.phone, form.code);
        return response.data;
    }
);

export const registerAsync = createAsyncThunk(
    'login/register',
    async (form: { phone: string }) => {
        const response = await register(form.phone);
        return response.data;
    }
);

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        // increment: (state) => {
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value += 1;
        // },
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload;
        // },
        changeLanguage: (state, action: PayloadAction<'BG' | 'EN'>) => {
            state.language = action.payload;
        },
        changeScreenState: (state, action: PayloadAction<'home' | 'login' | 'survey'>) => {
            state.screenState = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.token = action.payload.token;
                state.role = action.payload.role;
            })
            .addCase(loginAsync.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(registerAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(registerAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.isRegistered = action.payload;
            })
            .addCase(registerAsync.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

// export const { increment, decrement, incrementByAmount } = loginSlice.actions;
export const { changeLanguage, changeScreenState } = loginSlice.actions;

export const selectLoginStatus = (state: RootState) => state.login.status;
export const selectLoginToken = (state: RootState) => state.login.token;
export const selectLoginRole = (state: RootState) => state.login.role;
export const selectLoginIsRegistered = (state: RootState) => state.login.isRegistered;
export const selectUserLanguage = (state: RootState) => state.login.language;
export const selectScreenState = (state: RootState) => state.login.screenState;

export default loginSlice.reducer;