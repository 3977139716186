import { FC } from "react";
import { Col, Form, Row } from "react-bootstrap";

import { ResourcesService } from "../../../shared/services/ResourcesService";

type CompanySectorsTypeProps = {
    controlId: string,
    disabled: boolean,
    setValue: Function,
    defaultValue?: string | number | readonly string[] | undefined,
    options: { id: number, title: string }[],
    lang: 'BG' | 'EN'
};

export const CompanySectorsType: FC<CompanySectorsTypeProps> = ({ controlId, disabled, setValue, defaultValue, options, lang }) =>
    <Form.Group as={Row} className="mb-3" controlId={controlId}>
        <Form.Label column sm="2">{ResourcesService.translate('Sectors', lang)}</Form.Label>
        <Col sm="10">
            <Form.Select disabled={disabled} defaultValue={defaultValue} onChange={e => setValue(e.currentTarget.value)}>
                {
                    options.map(o =>
                        <option key={`sector-key-${o.id}`} value={o.id}>{o.title}</option>
                    )
                }
                {/* <option value={QuestionTypeEnum.Radio}>radio</option>
                <option disabled value={QuestionTypeEnum.Text}>text</option>
                <option disabled value={QuestionTypeEnum.Textarea}>textarea</option>
                <option value={QuestionTypeEnum.Checkbox}>checkbox</option>
                <option value={QuestionTypeEnum.Rating}>rating</option> */}
            </Form.Select>
        </Col>
    </Form.Group>