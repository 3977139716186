import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { ModeEnum, OperationEnum, StatusEnum } from '../../shared/models/Enums';
import { Filter } from '../../shared/models/Filter';
import { CompanyState, } from './models/CompanyState';
import { createCompany, deleteCompany, editCompany, getCompanies, getCompany, getCompanySectors } from './companyAPI';
import { CreateCompanyData } from './models/CreateCompanyData';
import { EditCompanyData } from './models/EditCompanyData';
import { getSurveys } from '../survey/surveyAPI';


const initialState: CompanyState = {
    companiesList: {
        data: [],
        status: StatusEnum.Idle,
        query: '',
        skip: 0,
        take: 5,
    },
    companyEdit: {
        data: {
            id: '',
            titleBg: '',
            titleEn: '',
            descriptionBg: '',
            descriptionEn: '',
            activities: [],
            employeeCount: 0,
            sectorId: 0,
            surveys: [],
            surveysCount : 0,
            logo : "",
        },
        status: StatusEnum.Idle,
        operation: OperationEnum.None,
        surveys: {
            data: [],
            status: StatusEnum.Idle,
            query: '',
            skip: 0,
            take: 5,
        },
    },
    companyCreate: {
        status: StatusEnum.Idle,
        surveys: {
            data: [],
            status: StatusEnum.Idle,
            query: '',
            skip: 0,
            take: 5,
        },
    },
    sectors: [],
    mode: ModeEnum.List,
    toast: {
        show: false,
        header: '',
        body: '',
    }
};

export const getCompanySectorsAsync = createAsyncThunk(
    'companies/getCompanySectors',
    async (args: undefined, { getState }) => {
        const state: any = getState();
        const response = await getCompanySectors(state.login.token);
        return response.data;
    }
);

export const getCompaniesAsync = createAsyncThunk(
    'companies/getCompanies',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getCompanies(state.login.token, filter);
        return response.data;
    }
);

export const getCompanyAsync = createAsyncThunk(
    'companies/getCompany',
    async (id:string, { getState }) => {
        const state: any = getState();
        const response = await getCompany(state.login.token, id);
        return response.data;
    }
);

export const createCompanyAsync = createAsyncThunk(
    'companies/createCompany',
    async (createData: CreateCompanyData, { getState }) => {
        const state: any = getState();
        const response = await createCompany(state.login.token, createData);
        return response.data;
    }
);

export const editCompanyAsync = createAsyncThunk(
    'companies/editCompany',
    async (editData: EditCompanyData, { getState }) => {
        const state: any = getState();
        const response = await editCompany(state.login.token, editData);
        return response.data;
    }
);

export const deleteCompanyAsync = createAsyncThunk(
    'companies/deleteCompany',
    async (sectionId: string, { getState }) => {
        const state: any = getState();
        const response = await deleteCompany(state.login.token, sectionId);
        return response.data;
    }
);

export const getSurveysAsync = createAsyncThunk(
    'companies/getSurveys',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getSurveys(state.login.token, filter);
        return response.data;
    }
);

export const getCreateSurveysAsync = createAsyncThunk(
    'companies/getCreateSurveys',
    async (filter: Filter, { getState }) => {
        const state: any = getState();
        const response = await getSurveys(state.login.token, filter);
        return response.data;
    }
);

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        // Global
        changeMode: (state, action: PayloadAction<ModeEnum>) => {
            state.mode = action.payload;
        },
        changeShowToast: (state, action: PayloadAction<boolean>) => {
            state.toast.show = action.payload;

            if (action.payload === false) {
                state.toast.header = '';
                state.toast.body = '';
            }
        },
        // Edit
        loadCompanyEdit: (state, action: PayloadAction<EditCompanyData>) => {
            state.companyEdit.data = action.payload;
            state.companyEdit.status = StatusEnum.Idle;
            state.mode = ModeEnum.Edit;
        },
        editSurveysChangeQuery: (state, action: PayloadAction<string>) => {
            state.companyEdit.surveys.query = action.payload;
        },
        editSurveysLoadNextPage: (state) => {
            if (state.companyEdit.surveys.data.length > 0) {
                state.companyEdit.surveys.skip = state.companyEdit.surveys.skip + state.companyEdit.surveys.take;
            }
        },
        editSurveysLoadPreviousPage: (state) => {
            if (state.companyEdit.surveys.skip > 0) {
                state.companyEdit.surveys.skip = Math.max(0, state.companyEdit.surveys.skip - state.companyEdit.surveys.take);
            }
        },
        // List
        changeQuery: (state, action: PayloadAction<string>) => {
            state.companiesList.query = action.payload;
        },
        loadNextPage: (state) => {
            if (state.companiesList.data.length > 0) {
                state.companiesList.skip = state.companiesList.skip + state.companiesList.take;
            }
        },
        loadPreviousPage: (state) => {
            if (state.companiesList.skip > 0) {
                state.companiesList.skip = Math.max(0, state.companiesList.skip - state.companiesList.take);
            }
        },
        // Create
        createSurveysChangeQuery: (state, action: PayloadAction<string>) => {
            state.companyCreate.surveys.query = action.payload;
        },
        createSurveysLoadNextPage: (state) => {
            if (state.companyCreate.surveys.data.length > 0) {
                state.companyCreate.surveys.skip = state.companyCreate.surveys.skip + state.companyCreate.surveys.take;
            }
        },
        createSurveysLoadPreviousPage: (state) => {
            if (state.companyCreate.surveys.skip > 0) {
                state.companyCreate.surveys.skip = Math.max(0, state.companyCreate.surveys.skip - state.companyCreate.surveys.take);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            // List
            .addCase(getCompaniesAsync.pending, (state) => {
                state.companiesList.status = StatusEnum.Loading;
            })
            .addCase(getCompaniesAsync.fulfilled, (state, action) => {
                state.companiesList.status = StatusEnum.Idle;
                state.companiesList.data = action.payload;
            })
            .addCase(getCompaniesAsync.rejected, (state) => {
                state.companiesList.status = StatusEnum.Failed;
            })

            //Get
            .addCase(getCompanyAsync.pending, (state) => {
                state.companyEdit.status = StatusEnum.Loading;
            })
            .addCase(getCompanyAsync.fulfilled, (state, action) => {
                state.companyEdit.status = StatusEnum.Idle;
                state.companyEdit.data = action.payload;
                state.mode = ModeEnum.Edit;
            })
            .addCase(getCompanyAsync.rejected, (state) => {
                state.companyEdit.status = StatusEnum.Failed;
            })

            // Create
            .addCase(createCompanyAsync.pending, (state) => {
                state.companyCreate.status = StatusEnum.Loading;
            })
            .addCase(createCompanyAsync.fulfilled, (state, action) => {
                state.companyCreate.status = StatusEnum.Idle;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Company`;
                state.toast.body = `Created with title "${action.payload.titleEn}"`;
            })
            .addCase(createCompanyAsync.rejected, (state) => {
                state.companyCreate.status = StatusEnum.Failed;
            })
            .addCase(getCreateSurveysAsync.pending, (state) => {
                state.companyCreate.surveys.status = StatusEnum.Loading;
            })
            .addCase(getCreateSurveysAsync.fulfilled, (state, action) => {
                state.companyCreate.surveys.status = StatusEnum.Idle;
                state.companyCreate.surveys.data = action.payload;
            })
            .addCase(getCreateSurveysAsync.rejected, (state) => {
                state.companyCreate.surveys.status = StatusEnum.Failed;
            })
            // Delete
            .addCase(deleteCompanyAsync.pending, (state) => {
                state.companyEdit.status = StatusEnum.Loading;
                state.companyEdit.operation = OperationEnum.Deleting;
            })
            .addCase(deleteCompanyAsync.fulfilled, (state, action) => {
                state.companyEdit.status = StatusEnum.Idle;
                state.companyEdit.operation = OperationEnum.None;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Company`;
                state.toast.body = `Deleted with id "${action.payload}"`;
            })
            .addCase(deleteCompanyAsync.rejected, (state) => {
                state.companyEdit.status = StatusEnum.Failed;
                state.companyEdit.operation = OperationEnum.None;
            })
            // Edit
            .addCase(editCompanyAsync.pending, (state) => {
                state.companyEdit.status = StatusEnum.Loading;
                state.companyEdit.operation = OperationEnum.Saving;
            })
            .addCase(editCompanyAsync.fulfilled, (state, action) => {
                state.companyEdit.status = StatusEnum.Idle;
                state.companyEdit.operation = OperationEnum.None;
                state.mode = ModeEnum.List;
                state.toast.show = true;
                state.toast.header = `Company`;
                state.toast.body = `Edited with title "${action.payload.titleEn}"`;
            })
            .addCase(editCompanyAsync.rejected, (state) => {
                state.companyEdit.status = StatusEnum.Failed;
                state.companyEdit.operation = OperationEnum.None;
            })
            .addCase(getSurveysAsync.pending, (state) => {
                state.companyEdit.surveys.status = StatusEnum.Loading;
            })
            .addCase(getSurveysAsync.fulfilled, (state, action) => {
                state.companyEdit.surveys.status = StatusEnum.Idle;
                state.companyEdit.surveys.data = action.payload;
            })
            .addCase(getSurveysAsync.rejected, (state) => {
                state.companyEdit.surveys.status = StatusEnum.Failed;
            })
            .addCase(getCompanySectorsAsync.fulfilled, (state, action) => {
                state.sectors = action.payload;
            });
    },
});

export const {
    changeMode, changeShowToast, loadCompanyEdit, changeQuery, loadNextPage, loadPreviousPage,
    editSurveysChangeQuery, editSurveysLoadNextPage, editSurveysLoadPreviousPage,
    createSurveysChangeQuery, createSurveysLoadNextPage, createSurveysLoadPreviousPage
} = companySlice.actions;

export const selectCompanyMode = (state: RootState) => state.company.mode;
export const selectCompanyToastShow = (state: RootState) => state.company.toast.show;
export const selectCompanyToastHeader = (state: RootState) => state.company.toast.header;
export const selectCompanyToastBody = (state: RootState) => state.company.toast.body;

export const selectCompaniesList = (state: RootState) => state.company.companiesList.data;
export const selectCompaniesListQuery = (state: RootState) => state.company.companiesList.query;
export const selectCompaniesListStatus = (state: RootState) => state.company.companiesList.status;
export const selectCompaniesListSkip = (state: RootState) => state.company.companiesList.skip;
export const selectCompaniesListTake = (state: RootState) => state.company.companiesList.take;

export const selectCompanyEdit = (state: RootState) => state.company.companyEdit.data;
export const selectCompanyEditStatus = (state: RootState) => state.company.companyEdit.status;
export const selectCompanyEditOperation = (state: RootState) => state.company.companyEdit.operation;
export const selectCompanyEditSurveysListData = (state: RootState) => state.company.companyEdit.surveys.data;
export const selectCompanyEditSurveysListStatus = (state: RootState) => state.company.companyEdit.surveys.status;
export const selectCompanyEditSurveysListQuery = (state: RootState) => state.company.companyEdit.surveys.query;
export const selectCompanyEditSurveysListSkip = (state: RootState) => state.company.companyEdit.surveys.skip;
export const selectCompanyEditSurveysListTake = (state: RootState) => state.company.companyEdit.surveys.take;

export const selectCompanyCreateStatus = (state: RootState) => state.company.companyCreate.status;
export const selectCompanyCreateSurveysListData = (state: RootState) => state.company.companyCreate.surveys.data;
export const selectCompanyCreateSurveysListStatus = (state: RootState) => state.company.companyCreate.surveys.status;
export const selectCompanyCreateSurveysListQuery = (state: RootState) => state.company.companyCreate.surveys.query;
export const selectCompanyCreateSurveysListSkip = (state: RootState) => state.company.companyCreate.surveys.skip;
export const selectCompanyCreateSurveysListTake = (state: RootState) => state.company.companyCreate.surveys.take;

export const selectCompanySectors = (state: RootState) => state.company.sectors;

export default companySlice.reducer;