import { FC } from "react";
import { Button } from "react-bootstrap";
import { StatusEnum } from "../../models/Enums";
import { ResourcesService } from "../../services/ResourcesService";

type CancelButtonProps = {
    status: StatusEnum,
    onCancel: Function,
    lang: 'BG' | 'EN'
};

export const CancelButton: FC<CancelButtonProps> = ({ status, onCancel, lang }) =>
    <Button className='float-end ms-2' variant="outline-primary" size='sm' onClick={() => onCancel()} disabled={status === StatusEnum.Loading}>
        <i className='bi bi-x-lg'></i> {ResourcesService.translate('Cancel', lang)}
    </Button>