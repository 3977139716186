import { FC } from "react";
import { Form, } from "react-bootstrap";

type FormWithHandleProps = {
    children: JSX.Element | JSX.Element[],
    onSubmit: Function,
};

export const FormWithHandle: FC<FormWithHandleProps> = ({ children, onSubmit }) => {
    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        onSubmit();
    };

    return (
        <Form onSubmit={handleSubmit}>
            {children}
        </Form>
    );
}