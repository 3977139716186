import { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { ModeTransition } from '../../shared/components/ModeTransition/ModeTransition';
import { NotificationToast } from '../../shared/components/NotificationToast/NotificationToast';
import { ModeEnum, OperationEnum } from '../../shared/models/Enums';
import { selectLoginRole } from '../login/loginSlice';
import { RecommendationLayout } from './RecommendationLayout/RecommendationLayout';
import {
    changeMode,
    changeQuery,
    changeShowToast,
    createQuestionsListChangeQuery,
    createQuestionsListLoadNextPage,
    createQuestionsListLoadPreviousPage,
    createRecommendationAsync,
    deleteRecommendationAsync,
    editQuestionsListChangeQuery,
    editQuestionsListLoadNextPage,
    editQuestionsListLoadPreviousPage,
    editRecommendationAsync,
    getCreateQuestionsAsync,
    getQuestionsAsync,
    getRecommendationsAsync,
    loadNextPage,
    loadPreviousPage,
    loadRecommendationEdit,
    selectRecommendationCreateQuestionsListData,
    selectRecommendationCreateQuestionsListQuery,
    selectRecommendationCreateQuestionsListSkip,
    selectRecommendationCreateQuestionsListStatus,
    selectRecommendationCreateQuestionsListTake,
    selectRecommendationCreateStatus,
    selectRecommendationEdit,
    selectRecommendationEditOperation,
    selectRecommendationEditQuestionsListData,
    selectRecommendationEditQuestionsListQuery,
    selectRecommendationEditQuestionsListSkip,
    selectRecommendationEditQuestionsListStatus,
    selectRecommendationEditQuestionsListTake,
    selectRecommendationEditStatus,
    selectRecommendationMode, selectRecommendationsList, selectRecommendationsListQuery, selectRecommendationsListSkip, selectRecommendationsListStatus, selectRecommendationsListTake,
    selectRecommendationToastBody, selectRecommendationToastHeader, selectRecommendationToastShow
} from './recommendationSlice';
import { RecommendationsList } from './RecommendationList/RecommendationList';

export const Recommendation: FC = () => {
    const role = useAppSelector(selectLoginRole);

    const dispatch = useAppDispatch();

    const mode = useAppSelector(selectRecommendationMode);
    const toastShow = useAppSelector(selectRecommendationToastShow);
    const toastHeader = useAppSelector(selectRecommendationToastHeader);
    const toastBody = useAppSelector(selectRecommendationToastBody);

    const listStatus = useAppSelector(selectRecommendationsListStatus);
    const listSkip = useAppSelector(selectRecommendationsListSkip);
    const listTake = useAppSelector(selectRecommendationsListTake);
    const listValue = useAppSelector(selectRecommendationsList);
    const listQuery = useAppSelector(selectRecommendationsListQuery);

    const editValue = useAppSelector(selectRecommendationEdit);
    const editStatus = useAppSelector(selectRecommendationEditStatus);
    const editOperation = useAppSelector(selectRecommendationEditOperation);
    const editQuestionsListData = useAppSelector(selectRecommendationEditQuestionsListData);
    const editQuestionsListStatus = useAppSelector(selectRecommendationEditQuestionsListStatus);
    const editQuestionsListQuery = useAppSelector(selectRecommendationEditQuestionsListQuery);
    const editQuestionsListSkip = useAppSelector(selectRecommendationEditQuestionsListSkip);
    const editQuestionsListTake = useAppSelector(selectRecommendationEditQuestionsListTake);

    const createStatus = useAppSelector(selectRecommendationCreateStatus);
    const createQuestionsListData = useAppSelector(selectRecommendationCreateQuestionsListData);
    const createQuestionsListStatus = useAppSelector(selectRecommendationCreateQuestionsListStatus);
    const createQuestionsListQuery = useAppSelector(selectRecommendationCreateQuestionsListQuery);
    const createQuestionsListSkip = useAppSelector(selectRecommendationCreateQuestionsListSkip);
    const createQuestionsListTake = useAppSelector(selectRecommendationCreateQuestionsListTake);

    useEffect(() => {
        if (mode === ModeEnum.List) {
            dispatch(getRecommendationsAsync({
                skip: listSkip,
                take: listTake,
                query: listQuery
            }));
        }
    }, [mode, dispatch, listSkip, listTake, listQuery]);

    return (
        (!role || role !== 'admin')
            ? <div></div>
            : <Row>
                <Col md={{ span: 12, offset: 0 }}>
                    <ModeTransition
                        cssClass='mt-4 p-5 rounded shadow bg-white'
                        mode={mode}
                        list={<RecommendationsList
                            recommendations={listValue}
                            recommendationStatus={listStatus}
                            dispatch={dispatch}
                            loadRecommendationEdit={loadRecommendationEdit}
                            query={listQuery}
                            setQuery={changeQuery}
                            onNext={loadNextPage}
                            onPrevious={loadPreviousPage}
                        />}
                        edit={<RecommendationLayout
                            recommendationId={editValue.id}
                            recommendationTitleBG={editValue.titleBg}
                            recommendationTitleEN={editValue.titleEn}
                            recommendationQuestions={editValue.questions}


                            recommendationStrongPointText={editValue.strongPointText}
                            recommendationStrongPointTextEn={editValue.strongPointTextEn}
                            recommendationWeakPointText={editValue.weakPointText}
                            recommendationWeakPointTextEn={editValue.weakPointTextEn}
                            recommendationWeakPointSelfText={editValue.weakPointSelfText}
                            recommendationWeakPointSelfTextEn={editValue.weakPointSelfTextEn}
                            recommendationType={editValue.type}

                            recommendationStatus={editStatus}
                            recommendationOperation={editOperation}

                            dispatch={dispatch}
                            changeMode={changeMode}
                            saveRecommendation={editRecommendationAsync}
                            deleteRecommendation={deleteRecommendationAsync}

                            questionsListData={editQuestionsListData}
                            questionsListStatus={editQuestionsListStatus}
                            questionsListQuery={editQuestionsListQuery}
                            questionsListSkip={editQuestionsListSkip}
                            questionsListTake={editQuestionsListTake}
                            questionsSetQuery={editQuestionsListChangeQuery}
                            questionsOnNext={editQuestionsListLoadNextPage}
                            questionsOnPrevious={editQuestionsListLoadPreviousPage}
                            questionsListLoadData={getQuestionsAsync}
                        />}
                        create={<RecommendationLayout
                            recommendationTitleBG={''}
                            recommendationTitleEN={''}
                            recommendationStrongPointText={''}
                            recommendationStrongPointTextEn={''}
                            recommendationType={1}
                            recommendationWeakPointText={''}
                            recommendationWeakPointTextEn={''}
                            recommendationWeakPointSelfText={''}
                            recommendationWeakPointSelfTextEn={''}
                            recommendationQuestions={[]}
                            recommendationStatus={createStatus}
                            recommendationOperation={OperationEnum.Saving}

                            dispatch={dispatch}
                            changeMode={changeMode}
                            saveRecommendation={createRecommendationAsync}
                            deleteRecommendation={deleteRecommendationAsync}

                            questionsListData={createQuestionsListData}
                            questionsListStatus={createQuestionsListStatus}
                            questionsListQuery={createQuestionsListQuery}
                            questionsListSkip={createQuestionsListSkip}
                            questionsListTake={createQuestionsListTake}
                            questionsSetQuery={createQuestionsListChangeQuery}
                            questionsOnNext={createQuestionsListLoadNextPage}
                            questionsOnPrevious={createQuestionsListLoadPreviousPage}
                            questionsListLoadData={getCreateQuestionsAsync}
                        />}
                    />
                </Col>
                <NotificationToast
                    show={toastShow}
                    setShow={changeShowToast}
                    dispatch={dispatch}
                    header={toastHeader}
                    body={toastBody}
                />
            </Row >
    );
}