import { FC } from "react";
import { Accordion, ListGroup } from "react-bootstrap";
import { useAppSelector } from "../../../app/hooks";
import { SectionData } from "../../../shared/models/Entities";
import { ResourcesService } from "../../../shared/services/ResourcesService";
import { selectUserLanguage } from "../../login/loginSlice";

type SectionAccordionProps = {
    section: SectionData,
};

export const SectionAccordion: FC<SectionAccordionProps> = ({ section }) => {
    const lang = useAppSelector(selectUserLanguage);

    return <Accordion className='mb-3'>
        <Accordion.Item eventKey="0">
            <Accordion.Header>{ResourcesService.translateInput(section.titleBg, section.titleEn, lang)}</Accordion.Header>
            <Accordion.Body>
                <h5>{ResourcesService.translate('Questions', lang)}</h5>
                <Accordion className='mb-3'>
                    {section.questions.map((q, i) => <Accordion.Item eventKey={`questions-${i}`} key={`questions-key-${i}`}>
                        <Accordion.Header>{ResourcesService.translateInput(q.titleBg, q.titleEn, lang)}</Accordion.Header>
                        <Accordion.Body>
                            <h6>{ResourcesService.translate('Answers', lang)}</h6>
                            <ListGroup>
                                {q.answers.map((a, k) => <ListGroup.Item key={`questions-answers-key-${k}`}>{ResourcesService.translateInput(a.titleBg, a.titleEn, lang)}</ListGroup.Item>
                                )}
                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                    )}
                </Accordion>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>;
}